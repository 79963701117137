import * as React from "react";
import { Controller } from "./Infraestructure/Controller";
import { ViewMain } from "./Components/View";
import { ViewModalDetalle } from "./Components/modal/ViewModalDetalle";
import ElementModalPDF from "../../shared/Components/ElementModalPDF";
import { LanguageTranslate } from "../../shared/Infraestructure/LanguageTranslate";

const ManagerEntity = () => {
  const controller = Controller();

  React.useEffect(() => {
    controller.init();
    // eslint-disable-next-line
  }, [controller.countProcess]);

  return (
    <>
      <ViewMain
        reloadData={controller.reloadData}
        data={controller.data}
        onClickItem={controller.onClickItem}
        onPreviousFile={controller.onPreviousFile}
        onChangeSearch={controller.onChangeSearch}
        onSubmitSearch={controller.onSubmitSearch}
      />
      <ViewModalDetalle
        closeModal={controller.closeModalGeneric}
        data={controller.configModal.data}
        show={controller.configModal.show}
        openModalPDF={controller.openModalPDF}
        openModalDetalle={controller.onClickItem}
        onChangeTabsDocument={controller.onChangeTabsDocument}
        countCurrentFilesSameFilters={controller.configModal.countCurrentFilesSameFilters}
      />
      <ElementModalPDF
        onCloseModal={controller.closeModalPDF}
        show={controller.configModalPDF.show}
        src={controller.configModalPDF.src}
        title={LanguageTranslate().textoVisualizarPDF}
        downloadFile={controller.downloadFile}
        textMarkOnDocument={LanguageTranslate().textoDocumentoNoValido}
      />
    </>
  );
};

export default ManagerEntity;
