import { InputTextArea } from "../../../../../shared/Components/ElementInputsCostume"
import ElementModalCostume from "../../../../../shared/Components/ElementModalCostume"
import { LanguageTranslate } from "../../../../../shared/Infraestructure/LanguageTranslate";
import { EntityFormModalNotes } from "../../Domain/EntityUtilts";

interface ContainerProps {
    closeModalNotas: () => void;
    configModalNotas: EntityFormModalNotes;
    onChangeMN: (value: any, index: number) => void;
    onSubmitMN: (evt: Event) => void;
}

const languageTranslate = LanguageTranslate();

export const ModalNotas = (props: ContainerProps) => {

    return (
        <ElementModalCostume
            closeModal={props.closeModalNotas}
            show={props.configModalNotas.show}
            title={languageTranslate.moduloGenericDetail.itemNotas.textTitle}
            height="340px"
        >
            <div className="ModalAgregarItem">

                <InputTextArea
                    label={languageTranslate.moduloGenericDetail.itemDetail.textComentario}
                    name="note"
                    onChange={props.onChangeMN}
                    values={props.configModalNotas.note}
                    isRequired
                />

                <div className="w-100 d-flex my-3" style={{ gap: 10 }}>
                    <button className='btn btn-dark w-50' onClick={props.closeModalNotas}>{languageTranslate.btnCancelar}</button>
                    <button className='btn btn-primary w-50' onClick={(evt: any) => props.onSubmitMN(evt)}>{languageTranslate.btnGuardar}</button>
                </div>
            </div>
        </ElementModalCostume>
    )
}