//Dependency External
import { useState } from 'react';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import { useDispatch } from 'react-redux';
import { Dispatch } from 'redux';

//Adapters
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { addLoading, removeLoading } from '../../../shared/Infraestructure/SliceGenerico';
import { AdapterConfigure } from './AdapterConfigure';
import { AdapterValidator } from '../../../shared/Infraestructure/AdapterValidator';

//Repository
import { RepositoryImplMain } from './RepositoryImplMain';

//UseCase
import { UseCaseLogin } from '../Application/UseCaseLogin';

//Entities
import { DtoRequestLogin } from '../Domain/DtoRequestLogin';
import { DtoResponseLogin } from '../Domain/DtoResponseLogin';


import { changePermisoVariable, changePreference, signIn, signInInvite } from '../../../shared/Infraestructure/SliceAuthentication';
import { useSelector } from 'react-redux';
import { RootState } from '../../../shared/Infraestructure/AdapterStore';
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';
import { NavigateFunction, useNavigate } from 'react-router-dom';
import { AdapterStorage } from '../../../shared/Infraestructure/AdapterStorage';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { AdapterLoadMaster } from '../../../shared/Infraestructure/AdapterLoadMaster';


export const Controller = () => {
    const { websocket, dbLocal } = useSelector((state: RootState) => state.generico);
    const dispatch: Dispatch = useDispatch();
    const navigate: NavigateFunction = useNavigate();
    const { language } = AdapterStorage.get('language');
    let { recordar } = AdapterStorage.get('recordar');

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const repositoryLoadMaster = new AdapterLoadMaster(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);
    const languageTranslate = LanguageTranslate();

    const [showPassword, setShowPassword] = useState(false);

    const formLogin = useFormik({
        // initialValues: { username: 'lhuaman', password: 'G3n3r4l*/*@#', recordar: false, },
        initialValues: { username: !!recordar ? recordar.username : '', password: !!recordar ? recordar.password : '', recordar: !!recordar, },
        validationSchema: Yup.object({
            username: Yup.string().required('Ingresar Usuario').min(3, 'Mímimo 3 caracteres'),
            password: Yup.string().required('Ingresar Contraseña').min(3, 'Mímimo 3 caracteres'),
            recordar: Yup.boolean().notRequired(),
        }),

        onSubmit: (values, formikHelpers) => { },
    });

    const onChange = (name: string, value: any) => {
        if (value === null) { return; }
        formLogin.setFieldValue(name, value);
    };

    const onSubmit = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await formLogin.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formLogin.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));

            let params: DtoRequestLogin = {
                User: formLogin.values.username,
                Password: formLogin.values.password,
            };

            let response: DtoResponseLogin | null = await (new UseCaseLogin(repository)).exec(params);
            if (response === null) { return; }

            let user: EntityDataUsuario = {
                IdUser: response.IdUser,
                Codigo: response.Codigo,
                User: response.User,
                username: response.username,
                LastNameFather: response.LastNameFather,
                LastNameMother: response.LastNameMother,
                Name: response.Name,
                Empresa: response.Empresa,
                DatosTrabajo: response.DatosTrabajo,
                TipoDocumento: response.TipoDocumento,
                FullName: response.FullName || response.fullname,
                Identificacion: response.Identificacion,
                Profile: response.Profile,
                Email: response.Email,
                Tasa: response.Tasa,
                Foto: response.Foto,
                Pais: response.Pais,
                TypeLogin: response.TypeLogin,
                ChangePasswordObligate: response.ChangePasswordObligate,
                MessageObligate: response.MessageObligate,
                Confidencialidad: response.Confidencialidad,
                Rol: response.Rol
            };

            if (formLogin.values.recordar) { AdapterStorage.set('recordar', JSON.stringify({ username: formLogin.values.username, password: formLogin.values.password })); }
            else { AdapterStorage.remove('recordar'); }

            var { [`preference${user.IdUser}`]: preference } = AdapterStorage.get(`preference${user.IdUser}`);

            // Guardar token para el uso de los servicios después del login
            AdapterStorage.set('token', response.token);

            const responseUserPermiss = await updateUserInformation(user);
            if (responseUserPermiss) {
                await repositoryLoadMaster.initialService(responseUserPermiss, user);
            } else {
                response.permisos.menu = response.permisos.menu.filter(row => row.id !== 30 && row.padre !== 30)
            }

            dispatch(removeLoading());

            dispatch(signIn({
                token: response.token,
                tokenRefresh: response.tokenRefresh,
                user,
                menu: response.permisos.menu,
                rolesSisacce: response.permisos.rolesSisacce,
                paisesSisacce: response.permisos.paisesSisacce,
                filialesSisacce: response.permisos.filialesSisacce,
                opcionesSisacce: response.permisos.opcionesSisacce,
            }));

            dispatch(changePreference(preference));
            formLogin.resetForm();
        } catch (error) {
            console.error(error);

            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        };
    };

    const updateUserInformation = async (user: EntityDataUsuario) => {
        const defaultValue = { Pais: [], Grupo: [], Delegacion: [], OT: [] };
        if (!navigator.onLine) return defaultValue;
        try {
            let response = await repositoryLoadMaster.UpdateUserInformation(user.IdUser);
            const { access: { Delegacion, Grupo, OT, Pais }, menu, exitoso } = response;
            if (!exitoso) {
                dispatch(signIn({ menu: menu }));
                return null;
            }

            dispatch(changePermisoVariable({ arrIdPaises: Pais, arrIdGrupos: Grupo, arrIdDelegaciones: Delegacion, arrIdOT: OT }));
            return { Pais, Grupo, Delegacion, OT };
        } catch (error) {
            return defaultValue;
        }
    }

    const onClickRecuperarPassword = () => {
        navigate(`/${process.env.REACT_APP_ROUTE_PATH_RECOVERPASSWORD}`, { replace: true });
    };

    const onClickRegisterRDI = () => {
        try {
            dispatch(signInInvite());
            navigate(`/${process.env.REACT_APP_ROUTE_PATH_MAIN}/${process.env.REACT_APP_ROUTE_PATH_MAIN_RDI}/${process.env.REACT_APP_ROUTE_PATH_MAIN_RDI_FORM}`, { replace: true });
        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        }
    }

    const onChangeLanguage = async (code: string) => {
        AdapterStorage.set('language', code);
        window.location.reload();
    };

    return {
        showPassword,
        formLogin,
        onChange,
        onSubmit,
        setShowPassword,
        onClickRecuperarPassword,
        onClickRegisterRDI,
        onChangeLanguage,
        language,
    };
}