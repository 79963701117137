// Components
import { ElementBreadCrumb } from '../../../../shared/Components/ElementBreadCrumb';
import { ElementCollapseCostume } from '../../../../shared/Components/ElementCollapseCostume';
import ElementStepperContent from '../../../../shared/Components/ElementStepperContent';
import ElementStepperHeader from '../../../../shared/Components/ElementStepperHeader';
import { ElementTableCostume } from '../../../../shared/Components/ElementTableCostume';
import { EntityGenericDetailForm } from '../../../../shared/Domain/EntityGenericDetailForm';
import { EntityAusencia } from '../../../../shared/Domain/Tareo/EntityAusencia';
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import { EntityDias, EntityRequestGenericForm } from '../Domain/EntityRequest';

// Domain
import { EntityConfigForm } from '../Domain/EntityUtilts';
import { AdapterConfigure } from '../Infraestructure/AdapterConfigure';

// Styles
import './View.scss';

interface ContainerProps {
    openModalAutocompletar: () => void;
    openModalDetalle: (data: any) => void;
    openModalNote: () => void;
    configForm: EntityConfigForm;
    formikForm: EntityGenericDetailForm<EntityRequestGenericForm>;
    onSubmit: (evt: Event) => Promise<null | undefined>;
    onSubmitApproval: (evt: Event) => Promise<null | undefined>;
    openModalAusencia: (mode: "create" | "edit" | "preview", data?: EntityAusencia) => void;
    onClearTareo(): void;
    cancelWorkFlow: (evt: Event) => Promise<null | undefined>
    downloadPDF: () => Promise<void>
}

const languageTranslate = LanguageTranslate();

const codeStatus: any = {
    "-1": 'Pendiente',
    1: languageTranslate.textoEstadoAprobado,
    2: languageTranslate.textoEstadoAbierto,
    3: languageTranslate.textoEstadoEnAprobacion,
    5: languageTranslate.textoEstadoEnAprobacion,
}

export const ViewMain = (props: ContainerProps) => {
    const isFormReject = AdapterConfigure.ACTION_ITEM_REJECT.includes(props.formikForm.values.Bitacora[props.formikForm.values.Bitacora.length - 1]?.accion) || AdapterConfigure.ACTION_ITEM_REJECT.includes(props.formikForm.values.Bitacora[0]?.accion);

    return (
        <div className="ViewMain">
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={props.configForm.navBar} />
            </div>
            <div className="container-form">
                {/** MENSAJE REVISAR NOTAS */}
                {
                    isFormReject ?
                        <div className='alert alert-warning'>
                            <span style={{ fontSize: '0.8rem' }}>
                                Este registro ah sido devuelto, por favor revise las notas para las correcciones.
                            </span>
                        </div>
                    : null
                }

                {/** FLUJO DE APROBADORES */}
                {
                    (props.formikForm.values.Flujo?.Aprobacion.length || 0) > 0 && props.configForm.mode === 'preview' ?
                        <ElementStepperHeader
                            data={props.formikForm.values.Flujo?.Aprobacion.map(row => ({ label: row.Aprobador.FullName, value: row.Aprobador.Identificacion })) || []}
                            onChange={() => {}}
                            stepPosition={props.formikForm.values.Estado.IdStatus === 1 ? 999 : (props.formikForm.values.Aprobacion?.Nivel || 0) + 1}
                            className='CustomHeaderStepper'
                        />
                    : null
                }

                {/** DETALLE GENERAL */}
                <ElementCollapseCostume
                    textTitle={languageTranslate.moduloTareo.detail.textTitleGeneral}
                    className='my-3'
                    persistsOpen
                    isOpenDefault
                >
                    <div className='ContentItemCollapse'>
                        <div className='d-flex'>
                            <span>{languageTranslate.moduloTareo.detail.textPeriodo}:</span>
                            <span className='text-muted'>&nbsp;{props.formikForm.values.Periodo?.Criterio} </span>
                        </div>
                        <div className='d-flex'>
                            <span>{languageTranslate.moduloTareo.detail.textPeriodoInicial}:</span>
                            <span className='text-muted'>&nbsp;{AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(props.formikForm.values.Periodo?.FechaInicio), 3)} </span>
                        </div>
                        <div className='d-flex'>
                            <span>{languageTranslate.moduloTareo.detail.textPeriodoFinal}:</span>
                            <span className='text-muted'>&nbsp;{AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(props.formikForm.values.Periodo?.FechaFinal), 3)} </span>
                        </div>
                        <div className='d-flex'>
                            <span>{languageTranslate.moduloTareo.detail.textEstado}:</span>
                            <span className='text-muted'>&nbsp;{codeStatus[props.formikForm.values.Estado.IdStatus]}</span>
                        </div>
                        <div className='d-flex'>
                            <span>{languageTranslate.moduloTareo.detail.textTotalHoras}:</span>
                            <span className='text-muted'>&nbsp;
                            {
                                AdapterGenerico.sumarHoras(props.formikForm.values.Dias.map(row => (row.HorasTrabajador || 0)))
                            }
                            </span>
                        </div>
                    </div>
                </ElementCollapseCostume>

                {/** DETALLE AUSENCIA */}
                <ElementStepperContent
                    step={1}
                    currentStep={1}
                    title={languageTranslate.moduloTareo.detail.textDetalleAusencia}
                    isLastStep
                    removeButtonSubmit
                    onSubmit={() => {}}
                    buttonRightCustom={() =>
                        (
                            [0, 2].includes(props.formikForm.values.Estado.IdStatus) || true ?
                                <div className='GroupButton'>
                                    <button className='btn btn-primary' onClick={() => props.openModalAusencia('create')}>{languageTranslate.btnAgregar}</button>
                                </div>
                            : <></>
                        )
                    }
                >
                    <div className='ContentDetalleTareo'>
                        <ElementTableCostume
                            listData={props.configForm.dataOptions.ausencia.map(row => ({
                                Estado: row.Status.Status,
                                TipoAusencia: row.TipoAusencia.TipoAusencia,
                                FechaInicio: row.FechaInicio.DateString,
                                FechaFinal: row.FechaFin.DateString,
                                dataComplete: row
                            }))}
                            listHeader={
                                languageTranslate.moduloTareo.detail.tableAusencia?.map(row => {
                                    /*if (row.key === 'Delete')
                                        row.render = (text: string, { dataComplete }: { dataComplete: EntityAusencia }, index: number) => <i className='fa-solid fa-trash' onClick={() => console.log(dataComplete)} />*/
                                    if (row.key === 'Preview')
                                        row.render = (text: string, { dataComplete }: { dataComplete: EntityAusencia }, index: number) =>
                                            // props.configForm.mode === 'edit' ?
                                                // <i className='fa-solid fa-pen' onClick={() => props.openModalAusencia('edit', dataComplete)} />
                                                // :
                                                <i className='fa-solid fa-eye' onClick={() => props.openModalAusencia('preview', dataComplete)} />

                                    return row;
                                })
                            }
                            className='mb-4 TableNoWrap'
                        />

                    </div>
                </ElementStepperContent>

                {/** DETALLE TAREO */}
                <ElementStepperContent
                    step={1}
                    currentStep={1}
                    title={languageTranslate.moduloTareo.detail.textTitle}
                    isLastStep
                    removeButtonSubmit
                    onSubmit={() => {}}
                >
                    <div className='ContentDetalleTareo'>

                        <div className='GroupButton'>
                            {
                                [0, 2].includes(props.formikForm.values.Estado.IdStatus) ?
                                    <>
                                        <button className='btn btn-primary' onClick={props.onClearTareo}>{languageTranslate.btnLimpiar}</button>
                                        <button className='btn btn-primary' onClick={props.openModalAutocompletar}>{languageTranslate.btnAutocompletar}</button>
                                    </>
                                : null
                            }
                            <button className='btn btn-primary' onClick={props.downloadPDF}><i className="fa-solid fa-file-pdf" style={{ fontSize: 20 }}></i></button>
                        </div>

                        <ElementTableCostume
                            listData={props.formikForm.values.Dias.map(row => {
                                return ({
                                    ...row,
                                    OT: props.formikForm.values.Tareas.reduce((prev, current) => {
                                        if (prev.length <= 2)
                                            prev.push(`${current.Proyecto.OT.Codigo} - ${current.Proyecto.OT.OT}`);
                                        if (prev.length === 3)
                                            prev.push(`otros...`);

                                        return prev;
                                    }, [] as Array<string>).join(', ')
                                })
                            })}
                            listHeader={
                                languageTranslate.moduloTareo.detail.table?.map(row => {
                                    if (row.key === 'Nombre')
                                        row.render = (text: string, data: EntityDias) =>
                                            <span
                                                className={`${data.Tipo === AdapterConfigure.IDENTIFIER_TIPO_FESTIVO || data.Ausencia ? 'text-danger' : ''}`}
                                            >
                                                {languageTranslate.moduloTareo.days[text]}{data.Ausencia ? ' - Ausencia' : ''}{data.Bloqueado ? ' - Bloqueado' : ''}
                                            </span>
                                    if (row.key === 'Preview') row.render = (text: string, data: EntityDias, index: number) => <i className={`fa-solid fa-eye`} style={(data.Ausencia || data.Bloqueado) ? { color: '#b3b3b3' } : {}} onClick={() => (data.Ausencia || data.Bloqueado) ? null : props.openModalDetalle(data)} />
                                    if (row.key === 'HorasTrabajador') row.render = (text: string, data: EntityDias, index: number) => data.HorasTrabajador === 0 ? '-' : `${AdapterGenerico.formatNumberToHoras(data.HorasTrabajador)}`; 

                                    return row;
                                })
                            }
                            className='my-4'
                        />

                    </div>
                </ElementStepperContent>

                {/** DETALLE NOTAS Y BITACORA */}
                <div>
                    <ElementCollapseCostume
                        textTitle={languageTranslate.moduloGenericDetail.itemNotas.textTitle}
                        className='my-3'
                    >
                        <div className='ContentItemCollapse'>
                            {
                                [0, 2, 3].includes(props.formikForm.values.Estado.IdStatus) ?
                                    <div className="w-100 mb-2" style={{ textAlign: 'end' }}>
                                        <button className="btn btn-primary" onClick={props.openModalNote}>{languageTranslate.btnAgregar}</button>
                                    </div>
                                    : null
                            }
                            <ElementTableCostume
                                listHeader={languageTranslate.moduloGenericDetail.itemNotas.headerTable}
                                listData={props.formikForm.values.Notas}
                                className="CustomTableNota"
                            />
                        </div>
                    </ElementCollapseCostume>

                    <ElementCollapseCostume
                        textTitle={languageTranslate.moduloGenericDetail.itemBitacora.textTitle}
                        className='mt-3 mb-5'
                    >
                        <div className='ContentItemCollapse'>
                            <ElementTableCostume
                                listHeader={languageTranslate.moduloGenericDetail.itemBitacora.headerTable}
                                listData={props.formikForm.values.Bitacora}
                            />
                        </div>
                    </ElementCollapseCostume>
                </div>

                {/** BUTTONS GUARDAR */}
                {
                    [0, 2].includes(props.formikForm.values.Estado.IdStatus) ?
                        <div className='GroupButtonSave'>
                            <button className='btn btn-primary' onClick={(evt: any) => props.onSubmit(evt)}>{languageTranslate.btnGuardar}</button>
                            <button className='btn btn-primary' onClick={(evt: any) => props.onSubmitApproval(evt)}>{languageTranslate.btnEnviarAprobacion}</button>
                        </div>
                    : null
                }

                {/** BUTTONS CANCELAR WORKFLOW */}
                {
                    [3, 5].includes(props.formikForm.values.Estado.IdStatus) ?
                        <div className='GroupButtonSave'>
                            <button className='btn btn-danger' onClick={(evt: any) => props.cancelWorkFlow(evt)}>Cancelar Flujo</button>
                        </div>
                    : null
                }
            </div>
        </div>
    )
}