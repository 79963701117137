// Infraestructure
import { AdapterData } from "../../../../shared/Infraestructure/AdapterData";
import { RepositoryImplGenerico } from "../../../../shared/Infraestructure/RepositoryImplGenerico";

// Domain
import { EntityDataUsuario } from "../../../../shared/Domain/EntityDataUsuario";
import { EntityResponseInitialData } from "../Domain/EntityResponse";
import { EntityDisciplina } from "../../../../shared/Domain/Tareo/EntityDisciplina";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntityDias, EntityRequestAddAusencia, EntityRequestAddNote, EntityRequestCancelReport, EntityRequestGenericForm, EntityRequestUpdateAusencia } from "../Domain/EntityRequest";
import { EntityTareoPersonal } from "../../../../shared/Domain/Tareo/EntityTareoPersonal";
import { addCountProcess } from "../../../../shared/Infraestructure/SliceGenerico";
import { AdapterGenerico } from "../../../../shared/Infraestructure/AdapterGenerico";
import { EntityRequestFormServiceSendApproval, EntityRequestFromServiceUpdate } from "../Domain/EntityUtilts";
import { EntityTipoAusencia } from "../../../../shared/Domain/Tareo/EntityTipoAusencia";
import { EntityAusencia } from "../../../../shared/Domain/Tareo/EntityAusencia";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async save(params: EntityRequestFromServiceUpdate, type: 'create' | 'edit' | 'preview'  | ''): Promise<EntityTareoPersonal | null> {
        try {
            let response = await this._save(params, type);
            let rowLocal = await this._saveLocal(params, type);

            await this.dbLocal.deleteByIndexStore({ nameStore: 'TareoPersonal', value: rowLocal.Codigo });
            await this.dbLocal.insertDataStore({ nameStore: 'TareoPersonal', data: response ? response : rowLocal });

            return !!response ? response : rowLocal;
        } catch (error) {
            throw error;
        }
    }

    private async _saveLocal(params: EntityRequestFromServiceUpdate, type: 'create' | 'edit' | 'preview'  | ''): Promise<any> {
        const StatusOffline = { Estado: "No Enviado", IdEstado: -1 }
        const result = await this.dbLocal.selectByIndexStore({ nameStore: 'TareoPersonal', value: params.codigo });

        let response = { ...result, ...params, modeSave: type, Status: StatusOffline, dataSend: params, isPendingSend: true };
        if (!!params.codigo) return response;

        await this.dbLocal.insertDataStore({ nameStore: 'TareoPersonal', data: response });
        return response;
    }

    private async _save(params: EntityRequestFromServiceUpdate, modeSave: 'create' | 'edit' | 'preview'  | ''): Promise<EntityTareoPersonal | null> {
        const url = `${this.urlBaseGastos}${modeSave === 'create' ? '/Tareo/Informe/saveManyWithFiles' : '/Tareo/Informe/ingresarTareas'}`
        if (!navigator.onLine) { this.dispatch(addCountProcess()); return null; }
        
        const response = await this.service.call<any>("POST", url, JSON.stringify(params), "bearer", "json", 'json',  { "request-decrypt-response": true }, 0);
        return Array.isArray(response) ? response[0] : response;
    }

    public async loadInitialData(user: EntityDataUsuario): Promise<EntityResponseInitialData> {
        const { ot } = AdapterData;
        const [disciplina, tipoAusencia, ausencia]: [EntityDisciplina[], EntityTipoAusencia[], EntityAusencia[]] = await this.dbLocal.selectAllStore(['CTDisciplina', 'CTTipoAusencia', 'CTAusencia']);

        return ({
            disciplina: (disciplina || []).map(row => ({ label: row.Name, value: row.Code, dataComplete: row })),
            ot: ot.map(row => ({ label: `${row.Codigo} - ${row.Name.trim()}`, value: row.Id, dataComplete: row })),
            tipoAusencia: (tipoAusencia || []).map(row => ({ label: row.Name, value: row.IdRegister, dataComplete: row })),
            ausencia: ausencia || []
        })
    }

    public async formatDataToSave(form: EntityRequestGenericForm): Promise<EntityRequestFromServiceUpdate> {

        return ({
            codigo: form.Codigo,
            tareas: form.Tareas.map(row => ({
                Codigo: form.Codigo,
                Proyecto: row.Proyecto,
                Disciplina: {
                    Codigo: row.Disciplina.Codigo,
                    Key: row.Disciplina.Codigo,
                    Name: row.Disciplina.Name
                },
                Horas: row.Horas.map(({ Ausencia, ..._row}) => {
                    const { HorasTrabajador } =  _row;
                    return ({
                        Horas: HorasTrabajador,
                        Id: _row.Id,
                        IdDia: _row.IdDia,
                        Anio: _row?.Anio || new Date().getFullYear(),
                        Mes: _row.Mes,
                        DiaMes: _row.DiaMes,
                        Semana: _row.Semana,
                        Nombre: _row.Nombre,
                        Tipo: _row.Tipo,
                        Descripcion: _row.Descripcion,
                    })
                })
            })),
            user: form.User,
        })
    }

    public async findForm(code: string, options: EntityResponseInitialData): Promise<{ data: EntityRequestGenericForm, dataComplete: EntityTareoPersonal } | null> {   
        const response: EntityTareoPersonal | null = await this.dbLocal.selectByIndexStore({ nameStore: 'TareoPersonal', value: code });
        if (!response) return null;

        // Configurar Dias
        let ListDia: EntityDias[] = response.Dias.map(row => ({
            ...row,
            HorasTrabajador: 0
        }));

        // Acumular las Horas de las tareas
        for (const row of response.Tareas) {
            row.Horas.forEach((item, index) => {
                ListDia[index].HorasTrabajador = AdapterGenerico.sumarHoras([ListDia[index].HorasTrabajador, item.Horas]);
            })
        }

        return ({
            data: {
                Codigo: response.Codigo,
                OTBase: response.DatosTrabajo.OT,
                DelegacionBase: {
                    Code: response.DatosTrabajo.Delegacion.Codigo,
                    Codigo: response.DatosTrabajo.Delegacion.Codigo,
                    Name: response.DatosTrabajo.Delegacion.Delegacion
                },
                Empresa: {
                    CDEmpresa: response.DatosEmpresa.CDEmpresa,
                    Empresa: response.DatosEmpresa.Empresa,
                    Grupo: response.Grupo.Codigo
                },
                Pais: {
                    CDPais: response.DatosPais.CDPais,
                    Code: response.DatosPais.Code,
                    Name: response.DatosPais.Name
                },
                User: {
                    IdUser: 0,
                    LastFather: '',
                    LastMother: '',
                    Name: '',
                    Perfil: '',
                    User: ''
                },
                Estado: {
                    IdStatus: response.Status.IdStatus, 
                    Status: response.Status.Status
                },
                Aprobacion: {
                    Nivel: response.Aprobacion?.Nivel || 0
                },
                Flujo: {
                    Aprobacion: response.Flujo?.Aprobacion || [],
                    Code: response.Flujo?.Code || "",
                    Codigo: response.Flujo?.Codigo || "",
                    Descripcion: response.Flujo?.Descripcion || "",
                    Id: response.Flujo?.Id || 0,
                    Name: response.Flujo?.Name || ""
                },
                Notas: (response?.Notas || []).map(row => ({
                    observacion: row.Description || row.Descripcion || '',
                    fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date?.Date || row.Dates?.Fecha), 3),
                    usuario: `${row.User?.Name || row.Users?.Name || ''} ${row.User?.LastName || `${row.Users?.LastFather} ${row.Users?.LastMother}`}`,
                    action: row.Action
                })).reverse(),
                Bitacora: (response?.Bitacora || []).map(row => ({
                    accion: row.Action,
                    fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3),
                    descripcion: row.Description,
                    usuario: `${row.User.Name} ${row.User.LastName}`
                })).reverse(),
                Dias: ListDia,
                Firma: [],
                Grupo: response.Grupo,
                Observacion: response.Observacion,
                Periodo: {
                    Codigo: response.Periodo.Codigo,
                    Criterio: response.Periodo.Criterio,
                    FechaFinal: response.Periodo.FechaFinal,
                    FechaInicio: response.Periodo.FechaInicio
                },
                Semanas: response.Semanas,
                Tareas: response.Tareas.map(row => ({
                    ...row,
                    Horas: row.Horas.map(_row => ({
                        ..._row,
                        HorasTrabajador: _row.Horas
                    })) 
                }))
            },
            dataComplete: response
        });
    }

    public async sendApproval(params: EntityRequestFormServiceSendApproval): Promise<boolean> {
        const url = `${this.urlBase}/navision/workflow/send-aprobacion-tareo`;
        const response = (await this.service.call<any>("POST", url, JSON.stringify(params.paramsSend), "bearer", "json", 'json', { "request-decrypt-response": true }, 0));
        if (!response) return false;
        
        // Eliminar registro
        await this.dbLocal.deleteByIndexStore({ nameStore: 'SolicitudAnticipo', value: params.extraData.Codigo });

        return true;
    }

    public async addNote(payload: EntityRequestAddNote): Promise<any[] | null> {
        let url: string = `${this.urlBaseGastos}/Tareo/Informe/addNoteReport`;
        const result: EntityTareoPersonal[] | null = await this.service.call<any>("POST", url, JSON.stringify(payload), 'bearer', "json", 'json', { "request-decrypt-response": true }, 0);
        if (!result || result.length === 0) return null;

        return (result[0].Notas || []).map(row => ({
            observacion: row.Description || row.Descripcion || '',
            fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date?.Date || row.Dates?.Fecha), 3),
            usuario: `${row.User?.Name || row.Users?.Name || ''} ${row.User?.LastName || `${row.Users?.LastFather} ${row.Users?.LastMother}`}`,
            action: row.Action
        })).reverse()
    }

    public async addAusencia(payload: EntityRequestAddAusencia): Promise<EntityAusencia | null> {
        let url: string = `${this.urlBaseGastos}/Tareo/Ausencia/saveUpload`;
        const formData = new FormData();

        const { Documento, ...rest } = payload;
        const listNameFiles = Documento.map(row => row.value.name);
        formData.append('data', JSON.stringify({ ...rest, Documento: [], Files: listNameFiles }));

        for (const item of Documento) {
            formData.append('file', item.value, item.value.name);
        };
        
        const result: EntityAusencia[] | EntityAusencia | null = await this.service.call<any>("POST", url, formData, 'bearer', "form", 'json', { "request-decrypt-response": true }, 0);
        if (!result) return null;

        const urlSendApproval = `${this.urlBase}/navision/workflow/ausencia/send-aprobacion`;
        const item = Array.isArray(result) ? result[0] : result;

        const payloadSendApproval = {
            identificacion: payload.User.Identificacion,
            codigo: item.Codigo,
            "CodeAucencia": item.TipoAusencia.Codigo
        };

        try {
            const resultApproval: EntityAusencia[] = await this.service.call<any>("POST", urlSendApproval, JSON.stringify(payloadSendApproval), 'bearer', "json", 'json', { "request-decrypt-response": true }, 0);
            if (resultApproval.length > 0) item.Status = resultApproval[0].Status;
        } catch { }

        if (Array.isArray(result) && result.length === 0) return null;
        await this.dbLocal.insertDataStore({ nameStore: 'CTAusencia', data: Array.isArray(result) ? result : [result] });

        return item;
    }

    public async updateAusencia(payload: EntityRequestUpdateAusencia): Promise<EntityAusencia | null> {
        let url: string = `${this.urlBaseGastos}/Tareo/Ausencia/updateOne`;
        const result: EntityAusencia[] | EntityAusencia | null = await this.service.call<any>("PATCH", url, JSON.stringify(payload), 'bearer', "json", 'json', { "request-decrypt-response": true }, 0);
        if (!result) return null;
        if (Array.isArray(result) && result.length === 0) return null;
        await this.dbLocal.updateByIndexStore({ nameStore: 'CTAusencia', value: Array.isArray(result) ? result[0] : result });

        return Array.isArray(result) ? result[0] : result;
    }

    public async updateDetailTareo(Codigo: string): Promise<EntityTareoPersonal | null> {
        let url: string = `${this.urlBaseGastos}/Tareo/Informe/find`;
        const payload = {
            "filter": { Codigo },
            "fields": { "Codigo": 1, "Dias": 1, "Status": 1, "Tareas": 1 }
        }

        const result: EntityTareoPersonal[] | null = await this.service.call<any>("POST", url, JSON.stringify(payload), 'bearer', "json", 'json', { "request-decrypt-response": true }, 0);
        if (!result) return null;
        if (Array.isArray(result) && result.length === 0) return null;

        const item = result[0];
        item.Tareas = item.Tareas.map(row => ({
            ...row,
            Horas: row.Horas.map(_row => ({
                ..._row,
                HorasTrabajador: _row.Horas
            })) 
        }))

        // Configurar Dias
        let ListDia: EntityDias[] = item.Dias.map(row => ({
            ...row,
            HorasTrabajador: 0
        }));

        // Acumular las Horas de las tareas
        for (const row of item.Tareas) {
            row.Horas.forEach((item, index) => {
                if (!ListDia[index].Ausencia)
                    ListDia[index].HorasTrabajador += item.Horas;
            })
        }

        item.Dias = ListDia;

        return item;
    }

    public async cancelWorkFlow(payload: EntityRequestCancelReport): Promise<EntityAusencia | null> {
        let url: string = `${this.urlBaseGastos}/Tareo/Informe/cancelFlow`;
        const result: EntityAusencia[] | EntityAusencia | null = await this.service.call<any>("POST", url, JSON.stringify(payload), 'bearer', "json", 'json', { "request-decrypt-response": true }, 0);
        if (!result) return null;
        if (Array.isArray(result) && result.length === 0) return null;

        return Array.isArray(result) ? result[0] : result;
    }

    public async downloadPDF(params: { idTareo: number }): Promise<File | null> {
        let url: string = `${this.urlBase}/reporte/pdf_tareo`;
        const result: File = await this.service.call<any>("POST", url, JSON.stringify(params), 'bearer', "json", 'blob', { "request-decrypt-response": true }, 0);
        return result;
    }
}