import { EntityLanguage } from "../context/shared/Domain/EntityLanguage";

/* NOTA: modificar solo el valor, no la llave */
export const idioma_de: EntityLanguage = {
    code: 'de',
    nombreProyecto: 'Ganzheitliches Verwaltungsmanagement',
    textoLogin: 'Anmelden',
    textoConfiguracion: 'Einstellungen',
    textoCerrarSesion: 'Abmelden',
    textoModoCrear: 'ERSTELLEN',
    textoModoEditar: 'BEARBEITEN',
    textoModoVisualizar: 'ANZEIGEN',
    textoNavbarInicio: 'Startseite',
    textoConfigurandoAreaTrabajo: 'Richten Sie Ihre Arbeitsumgebung ein...',
    textoNuevaVersion: 'Neue Version, klicken Sie hier zum Aktualisieren',
    textoGuardando: 'Speichern...',
    textoCopiar: 'In die Zwischenablage kopiert',
    textoCargando: 'Laden...',
    textoEnviando: 'Senden...',
    textoDescargando: 'Herunterladen...',
    textoDescargar: 'Herunterladen',
    textoPagina: 'Seite',
    textoPaginaDe: 'von',
    textoAlerta: 'Warnung',
    textoError: 'Fehler',
    textoAceptar: 'Annehmen',
    textoArchivo: 'Datei',
    textoDocumentoNoValido: 'Ungültiges Dokument',
    textoVisualizarPDF: 'PDF anzeigen',
    textoSinDatos: 'Keine Daten',
    textoFiltros: 'Filter',
    textoBuscar: 'Finden',
    textoEstado: 'Zustand',
    textoAproados: 'Genehmigt',
    textoEnAprobacion: 'In Genehmigung',
    textoEstadoAprobado: 'Genehmigt',
    textoEstadoAbierto: 'Offen',
    textoEstadoEnAprobacion: 'In Genehmigung',
    textoEstadoPagado: 'Bezahlt',
    textoEstadoAnulado: 'Annulliert',
    textoEstadoDevuelto: 'Zurückgekehrt',
    textoIncompleto: 'Unvollständig',
    textoSinConexion: 'Keine Verbindung',
    textoConexionEstablecida: 'Verbindung hergestellt',
    textoDescripcionSinConexion: 'Keine Internetverbindung vorhanden',
    textSinResultados: 'Keine Ergebnisse',
    textoExitoso: 'Erfolgreich',
    textoExitosoCambioPassword: 'Passwortänderung erfolgreich durchgeführt',
    textoExitosoRecuperacionPassword: 'Passwortwiederherstellung erfolgreich, Sie erhalten eine Nachricht an die registrierte E-Mail-Adresse',
    textoEliminar: 'Sind Sie sicher, dass Sie die folgenden Elemente löschen möchten?',
    btnCancelar: 'Abbrechen',
    btnGuardar: 'Speichern',
    btnSiguiente: 'Weiter',
    btnVerImagen: 'Bilder ansehen',
    btnRegresar: 'Zurück',
    btnSeleccionar: 'Auswählen',
    btnDescartar: 'Verwerfen',
    btnEliminar: 'Löschen',
    btnAgregar: 'Hinzufügen',
    btnRechazar: 'Ablehnen',
    btnAprobar: 'Genehmigen',
    btnSubirArchivo: 'Datei hochladen',
    btnTodo: 'Alles',
    btnDownloadTemplate: 'Vorlage herunterladen',
    btnEnviar: 'Senden',
    btnCancelarFlujo: 'Fluss abbrechen',
    btnEnviarAprobacion: 'Genehmigung senden',
    btnAutocompletar: 'Autovervollständigen',
    btnLimpiar: 'Leeren',
    moduloConfiguracion: {
        textoIdioma: 'Sprache',
        textoCambioPassword: 'Passwort ändern',
        textoNuevaPassword: 'Neues Passwort',
        textoRepetirNuevaPassword: 'Neues Passwort wiederholen',
        textoBotonSaveCambioPassword: 'Speichern',
        nombre: 'Deutsch'
    },
    moduloLogin: {
        textoTitulo: 'Anmeldung',
        txtUsuario: 'Benutzer',
        txtPassword: 'Passwort',
        btnLogin: 'ANMELDEN',
        chkRecordarUsuario: 'Mich erinnern',
        textoOlvidoContrasenia: 'Haben Sie Ihr Passwort vergessen?'
    },
    moduloRecuperarPassword: {
        textoTitulo: 'Passwort Wiederherstellen',
        textoUsuario: 'Benutzer',
        textoIdentificacion: 'Identifikationsnummer',
        textoCorreoElectronico: 'E-Mail-Adresse',
        textoAccion: 'Wiederherstellen',
        textoRegresarLogin: 'Zurück zur Anmeldung'
    },
    modulo404: {
        textoDescripcion: 'Die angeforderte Ressource ist nicht verfügbar',
        textoHome: 'Zurück zur Startseite'
    },
    moduloNotAvailable: {
        textoCabecera: 'Ressource Nicht Verfügbar',
        textoDescripcion: 'Die angeforderte Ressource ist für Ihr Gerät nicht verfügbar'
    },
    moduloMain: {
        textoTitulo: 'System für Betriebssicherheitsmanagement',
        textoBienvenida: 'Willkommen!',
        textoInicio: 'Startseite',
        textoAyuda: 'Hilfe',
        textoTituloPreguntaAyuda: 'Weiterleitung',
        textoDescripcionPreguntaAyuda: 'Sie werden zur Systemdokumentation weitergeleitet. Sind Sie sicher?',
        textoReporte: 'Melden',
        textoRefrescarDatos: 'Daten aktualisieren',
        textoTituloPreguntaReporte: 'Weiterleitung',
        textoDescripcionPreguntaReporte: 'Sie werden zum Service Management System weitergeleitet. Sind Sie sicher?'
    },
    moduloCatalogo: {
        textoTitulo: 'DATEN AKTUALISIEREN',
        textoRefrescarTodo: 'Alles aktualisieren',
        textoRefrescar: 'Aktualisieren',
        textoSincronizado: 'Synchronisiert',
        textoNoSincronizado: 'Nicht synchronisiert'
    },
    moduloApprovals: {
        navBar: [{ text: 'GENEHMIGUNGEN', navigate: false, path: '' }],
        question: {
            textoDesea: 'Möchten Sie',
            textoAprobar: 'genehmigen',
            textoDevolver: 'zurückgeben',
            textoComparativo: 'den folgenden Vergleich?',
            textoFactura: 'die folgende Rechnung?',
            textoRequerimientoSistema: 'die folgende Systemanforderung?',
            textoRequerimientoCompra: 'die folgende Einkaufsanforderung?',
            textoSolicitudCajaChica: 'den folgenden Antrag auf Petty Cash?',
            textoRendicionGastoCajaChica: 'den folgenden Bericht über Petty Cash-Ausgaben?',
            textoHojaPeticion: 'das folgende Antragsformular?',
            textoCondicionLaboral: 'die folgende Arbeitsbedingung?'
        }
    },
    moduloGenericDetail: {
        navBarComparativo: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'VERGLEICH', navigate: true, path: '/main/approvals/comparative' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarFactura: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'RECHNUNG', navigate: true, path: '/main/approvals/invoice' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarRequerimientoSistemas: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'SYSTEMANFORDERUNG', navigate: true, path: '/main/approvals/system-requirement' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarRequerimientoCompra: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'EINKAUFSANFORDERUNG', navigate: true, path: '/main/approvals/purchase-requirement' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarSolicitudCajaChica: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'PETTY CASH-ANTRAG', navigate: true, path: '/main/approvals/petty-cash-request' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarRendicionGastosCajaChica: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'BERICHT ÜBER PETTY CASH-AUSGABEN', navigate: true, path: '/main/approvals/surrender-petty-cash-expenses' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarHojaPeticion: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'ANTRAGSFORMULAR', navigate: true, path: '/main/approvals/request-form' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarWorkingConditions: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'ARBEITSBEDINGUNGEN', navigate: true, path: '/main/approvals/working-conditions' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarAusencia: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'ABWESENHEIT', navigate: true, path: '/main/approvals/ausencia' }, { text: 'DETAIL', navigate: false, path: '' }],
        navBarDocucobra: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'DOCUCOBRA', navigate: true, path: '/main/approvals/docucobra' }, { text: 'DETAIL', navigate: false, path: '' }],
        btnTextDevolver: 'Zurückgeben',
        btnTextAprobar: 'Genehmigen',
        itemDetail: {
            textCodigo: 'Code',
            textTitle: 'DETAIL',
            textPais: 'Land',
            textGrupo: 'Gruppe',
            textEmpresa: 'Unternehmen',
            textProveedor: 'Lieferant',
            textOT: 'OT',
            textFechaRecepcion: 'Empfangsdatum',
            textFormaPago: 'Zahlungsart',
            textTipoPago: 'Zahlungstyp',
            textTerminoPago: 'Zahlungsbedingungen',
            textMoneda: 'Währung',
            textImporte: 'Betrag',
            textComentario: 'Kommentar',
            textPedidoCompra: 'Kaufauftrag',
            textImporteFacturaAsociado: 'Zugehöriger Rechnungsbetrag',
            textSolicitante: 'Antragsteller',
            textMultiProveedor: 'Mehrfachlieferant',
            textContratoMarco: 'Rahmenvertrag',
            textFechaRegistro: 'Registrierungsdatum',
            textTrabajador: 'Arbeiter',
            textAreaSolicitante: 'Bereich',
            textPuestoSolicitante: 'Position',
            textCargoSolicitante: 'Aufgabe',
            textObservaciones: 'Bemerkungen',
            textAprobadores: 'Genehmiger',
            textCodigoSolicitud: 'Anfragecode',
            textEstado: 'Status',
        },
        itemPersonal: {
            textTitle: 'PERSONAL',
            textModalidadTrabajo: 'Arbeitsmodalität',
            textMotivoSolicitud: 'Antragsgrund',
            textTipoContrato: 'Vertragstyp',
            textPuesto: 'Position',
            textTipoDocumento: 'Dokumenttyp',
            textIdentificacion: 'Identifikation',
            textNombreCompleto: 'Vollständiger Name',
            textFechaAlta: 'Einstellungsdatum',
            textFechaBaja: 'Austrittsdatum',
            textVehiculoPropio: 'Eigenes Fahrzeug',
            textVehiculoEmpresa: 'Firmenfahrzeug',
            textHoraExtra: 'Überstunden',
            textPrimaProduccion: 'Produktionsprämie',
            textBonoTurnoNoche: 'Nachtzuschlag',
            textBonoTurnos: 'Schichtzulage',
            textRetribucionMensual: 'Monatsvergütung',
            textRetribucionAnual: 'Jahresvergütung',
            textFunciones: 'Funktionen'
        },
        itemEmpleado: {
            textTitle: 'MITARBEITER',
            textTitleActuales: 'Aktuelle Daten',
            textTitlePropuestas: 'Vorgeschlagene Daten',
            textEmpleado: 'Mitarbeiter',
            textDelegacion: 'Delegation',
            textOT: 'OT',
            textPuesto: 'Position',
            textRemuneracionMensual: 'Monatsvergütung',
            textRemuneracionAnual: 'Jahresvergütung',
            textRemuneracionAnualEuros: 'Jahresvergütung in Euro',
            textTipoCoteo: 'Kostenart',
            textFechaEfectiva: 'Wirksamkeitsdatum',
            textVarianza: 'Abweichung',
            textMotivoAumentoSalarial: 'Grund für Gehaltserhöhung',
            textJustificacionSolicitud: 'Begründung des Antrags'
        },
        itemDistribuccion: {
            textTitle: 'VERTEILUNG',
            textTitleEmpty: 'KEINE VERTEILUNG',
            textProveedor: 'Lieferant',
            textMoneda: 'Währung',
            headerTable: [
                { label: 'Konto', key: 'cuenta' },
                { label: 'OT', key: 'ot' },
                { label: 'Betrag', key: 'importe' }
            ],
            textImporteTotal: 'Gesamtbetrag',
            textImporteDistribuido: 'Verteilter Betrag',
            textImportePendiente: 'Ausstehender Betrag'
        },
        itemProducto: {
            textTitle: 'PRODUKT',
            headerTable: [
                { label: 'Beschreibung', key: 'descripcion' },
                { label: 'Menge', key: 'cantidad' },
                { label: 'Einheit', key: 'unidad' },
                { label: 'Einzelpreis', key: 'precioUnitario' },
                { label: 'Zwischensumme', key: 'subTotal' }
            ],
            headerTableWithoutPrice: [
                { label: 'Beschreibung', key: 'descripcion' },
                { label: 'Menge', key: 'cantidad' },
                { label: 'Einheit', key: 'unidad' }
            ],
            headerTableRQSistema: [
                { label: 'Personal', key: 'personal' },
                { label: 'Beschreibung', key: 'descripcion' },
                { label: 'Menge', key: 'cantidad' },
                { label: 'Einheit', key: 'unidad' }
            ],
            textMontoTotal: 'Gesamtbetrag'
        },
        itemValorizaciones: {
            textTitle: 'WERTBESTIMMUNG',
            headerTablePedidoCompra: [
                { label: 'Serie', key: 'serie' },
                { label: 'Dokumentnummer', key: 'numeroDocumento' },
                { label: 'Beschreibung', key: 'descripcion' },
                { label: 'Menge', key: 'cantidad' },
                { label: 'Einzelpreis', key: 'precioUnitario' },
                { label: 'Zwischensumme', key: 'subTotal' }
            ],
            headerTableContrato: [
                { label: 'Wertbestimmungsnummer', key: 'numeroValorizacion' },
                { label: 'Vertrag', key: 'contrato' },
                { label: 'Dienstleistung', key: 'servicio' },
                { label: 'Betrag', key: 'monto' }
            ],
            textMontoTotal: 'Gesamtbetrag'
        },
        itemRendicion: {
            textTitle: 'AUSGABEN',
            headerTable: [
                { label: 'Dienstleistungsname', key: 'nombreServicio' },
                { label: 'Betrag', key: 'importe' }
            ],
            headerTableNuevo: [
                { label: '', key: 'checkbox' },
                { label: '', key: 'preview' },
                { label: 'Dienstleistungsname', key: 'DatosServicio.Servicio' },
                { label: 'Betrag', key: 'DatosImporte.Importe', special: 'amountFormat' }
            ],
            textImporteTotal: 'Gesamtbetrag'
        },
        itemNotas: {
            textTitle: 'NOTIZEN',
            headerTable: [
                { label: 'Beobachtung', key: 'observacion' },
                { label: 'Datum', key: 'fecha' },
                { label: 'Benutzer', key: 'usuario' }
            ]
        },
        itemBitacora: {
            textTitle: 'LOGBUCH',
            headerTable: [
                { label: 'Aktion', key: 'accion' },
                { label: 'Datum', key: 'fecha' },
                { label: 'Beschreibung', key: 'descripcion' },
                { label: 'Benutzer', key: 'usuario' }
            ]
        },
        itemDocumentos: {
            textTitle: 'DOKUMENTE'
        },
        errors: {
            comparativoErrorDetalle: 'Vergleichsdetail nicht gefunden',
            facturaErrorDetalle: 'Rechnungsdetail nicht gefunden',
            requerimientoSistemaErrorDetalle: 'Detail der Systemanforderung nicht gefunden',
            requerimientoCompraErrorDetalle: 'Detail der Einkaufsanforderung nicht gefunden',
            solicitudCajaChicaErrorDetalle: 'Detail des Petty Cash-Antrags nicht gefunden',
            rendicionGastoCajaChicaErrorDetalle: 'Detail des Petty Cash-Ausgabenberichts nicht gefunden',
            hojaPeticionErrorDetalle: 'Detail des Antragsformulars nicht gefunden',
            condicionLaboralErrorDetalle: 'Detail der Arbeitsbedingung nicht gefunden',
            docucobraErrorDetalle: 'Detail des Docucobra nicht gefunden',
            facturaNoAsociada: 'Rechnung kann nicht genehmigt werden ohne Zuordnung',
            aproveBatch: 'Beim Genehmigen der Datensätze ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut',
            returnBatch: 'Beim Zurückgeben der Datensätze ist ein Fehler aufgetreten. Bitte versuchen Sie es erneut'
        },
        successBatch: 'Datensätze gelöscht'
    },
    moduloGenericList: {
        navBarComparativo: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'VERGLEICH', navigate: false, path: '' }],
        navBarFactura: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'RECHNUNG', navigate: false, path: '' }],
        navBarRequerimientoCompra: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'EINKAUFSANFORDERUNG', navigate: false, path: '' }],
        navBarRequerimientoSistema: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'SYSTEMANFORDERUNG', navigate: false, path: '' }],
        navBarSolicitudCajaChica: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'PETTY CASH-ANTRAG', navigate: false, path: '' }],
        navBarRendicionGastosCajaChica: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'BERICHT ÜBER PETTY CASH-AUSGABEN', navigate: false, path: '' }],
        navBarHojaPeticion: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'ANTRAGSFORMULAR', navigate: false, path: '' }],
        navBarWorkingConditions: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'ARBEITSBEDINGUNGEN', navigate: false, path: '' }],
        navBarGastoAprobacion: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'BERICHT ÜBER PETTY CASH-AUSGABEN', navigate: false, path: '' }],
        navBarTareo: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'STUNDENABRECHNUNG', navigate: false, path: '' }],
        navBarAusencia: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'ABWESENHEIT', navigate: false, path: '' }],
        navBarDocucobra: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'DOCUCOBRA', navigate: false, path: '' }],
    
        navBarHistoricoGastos: [{ text: 'BERICHTE', navigate: true, path: '/main/caja-chica#aprobador' }, { text: 'HISTORIE DER AUSGABEN', navigate: false, path: '' }],
        navBarRendicionGastos: [{ text: 'BERICHTE', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'BERICHT ÜBER PETTY CASH-AUSGABEN', navigate: false, path: '' }],
        navBarIngresoGastos: [{ text: 'BERICHTE', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'EINGABEN DER AUSGABEN', navigate: false, path: '' }],
        navBarIngresoGastosAsociados: [{ text: 'BERICHTE', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'EINGABEN DER ZUGEHÖRIGEN AUSGABEN', navigate: false, path: '' }],
        navBarSolicitudAnticipo: [{ text: 'BERICHTE', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'PETTY CASH-ANTRAG', navigate: false, path: '' }],
    
        navBarTareoPersonal: [{ text: 'STUNDENABRECHNUNG', navigate: true, path: '/main/tareo' }, { text: 'MEINE STUNDENABRECHNUNG', navigate: false, path: '' }],
        navBarTareoGrupal: [{ text: 'STUNDENABRECHNUNG', navigate: true, path: '/main/tareo' }, { text: 'GRUPPENSTUNDENABRECHNUNG', navigate: false, path: '' }],        
        
        item: {
            textoListaVacia: 'Keine Einträge',
            itemTextProveedor: 'Lieferant',
            itemTextOT: 'OT',
            itemTextMoneda: 'Währung',
            itemTextImporte: 'Betrag',
            itemTextFechaRegistro: 'Registrierungsdatum',
            itemTextFaltaAsociacion: 'Fehlende Zuordnung',
            itemTextSociedad: 'Gesellschaft',
            itemTextTrabajador: 'Arbeiter',
            itemTextSolicitante: 'Antragsteller',
            itemTextEmpresa: 'Unternehmen',
            itemTextPersonal: 'Personal',
            itemTextImporteAprobado: 'Genehmigter Betrag',
            itemTextRendidor: 'Rechenschaftspflichtiger',
            itemTextProceso: 'In Bearbeitung',
            itemTextPeriodo: 'Zeitraum',
            itemTextPeriodoInicial: 'Anfangszeitraum',
            itemTextPeriodoFinal: 'Endzeitraum',
            itemTextHoras: 'Stunden',
            itemTextCantidadLinea: 'Anzahl der Zeilen',
        }
    },
    moduloCajaChica: {
        navBar: [{ text: 'ABRECHNUNGEN', navigate: false, path: '' }],
        textoImporteTotalSolicitud: 'Gesamtbetrag Angefordert',
        textoSinGastosAsociados: 'Keine Zugehörigen Ausgaben',
    },
    moduloGasto: {
        navBarGastosPendientes: [{ text: 'ABRECHNUNGEN', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'AUFSTELLUNG DER AUSGABEN', navigate: true, path: '/main/caja-chica/ingreso-gastos' }, { text: '', navigate: false, path: '' }],
        navBarGastosAsociados: [{ text: 'ABRECHNUNGEN', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'AUFSTELLUNG DER ZUGEORDNETEN AUSGABEN', navigate: true, path: '/main/caja-chica/ingreso-gastos-asociados' }, { text: '', navigate: false, path: '' }],
        detail: {
            textTitle: 'Allgemeine Informationen',
            textOT: 'OT',
            textProveedor: 'Lieferant',
            textOtroProveedor: 'Anderer Lieferant',
            textDesactivarOtroProveedor: 'Anderen Lieferanten deaktivieren',
            textTipoDocumentoProveedor: 'Dokumenttyp des Lieferanten',
            textNumeroDocumentoProveedor: 'Dokumentnummer des Lieferanten',
            textRazonSocialProveedor: 'Firmierung des Lieferanten',
            textFechaEmision: 'Ausstellungsdatum',
            textTipoDocumentoTributario: 'Steuerdokumenttyp',
            textNumeroDocumento: 'Dokumentnummer',
            textServicio: 'Dienstleistung',
            textDivisa: 'Währung',
            textImporte: 'Betrag',
            textTipoImpuesto: 'Steuerart',
            textoOtroImpuesto: 'Andere Steuer',
            textDesactivarOtroImpuesto: 'Andere Steuer deaktivieren',
            textImporteImpuesto: 'Steuerbetrag',
            textImporteTotal: 'Gesamtbetrag',
            textObservaciones: 'Bemerkungen',
            textDigitalizacionFactura: 'Rechnungsdigitalisierung',
        },
        validate: {
            errorOT: 'Wählen Sie eine OT aus',
            errorProveedor: 'Wählen Sie einen Lieferanten aus',
            errorTipoDocumentoProveedor: 'Geben Sie einen Dokumenttyp des Lieferanten ein',
            errorNumeroDocumentoProveedor: 'Geben Sie eine Dokumentnummer des Lieferanten ein',
            errorRazonSocialProveedor: 'Geben Sie die Firmierung des Lieferanten ein',
            errorFechaEmision: 'Geben Sie ein Ausstellungsdatum ein',
            errorTipoDocumento: 'Wählen Sie einen Dokumenttyp aus',
            errorNumeroDocumento: 'Geben Sie eine Dokumentnummer ein',
            errorServicio: 'Wählen Sie eine Dienstleistung aus',
            errorDivisa: 'Wählen Sie eine Währung aus',
            errorImporte: 'Geben Sie den Betrag ein',
            errorTipoImpuesto: 'Wählen Sie eine Steuerart aus',
            errorObservacion: 'Geben Sie eine Bemerkung ein',
            errorFiles: 'Geben Sie die Rechnungsdigitalisierung ein',
            errorNumeroDocumentoDuplicado: 'Ein Ausgabenbeleg mit derselben Dokumentnummer existiert bereits',
            errorSinFlujos: 'Diese OT/Delegation stellt keine Genehmigungsabläufe dar'
        }
    },
    moduloAnticipo: {
        navBar: [{ text: 'ABRECHNUNGEN', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'ANTRAG KLEINKASSE', navigate: true, path: '/main/caja-chica/solicitud-anticipo' }, { text: '', navigate: false, path: '' }],
        detail: {
            textTitle: 'Allgemeine Informationen',
            textOT: 'OT',
            textProyectos: 'Beschränkungen',
            textTarea: 'Aufgabe',
            textPersonal: 'Personal',
            textDivisa: 'Währung',
            textImporte: 'Betrag',
            textBanco: 'Bank',
            textCuentaBancaria: 'Bankkonto',
            textCuentaInterbancaria: 'Interbankkonto',
            textNota: 'Beschreibung',
            textoImporteMaximo: 'Höchstbetrag',
            textHelp: 'Damit Ihr Antrag auf Kleinkasse genehmigt wird, ist es wichtig, das ordnungsgemäß unterschriebene Dokument beizufügen. Die Vorlage finden Sie hier.',
            textDigitalizacionSolicitud: 'Digitalisierung des Antrags',
        },
        validate: {
            errorOT: 'Wählen Sie eine OT aus',
            errorProyectos: 'Wählen Sie ein Projekt aus',
            errorTarea: 'Wählen Sie eine Aufgabe aus',
            errorPersonal: 'Wählen Sie ein Personal aus',
            errorDivisa: 'Wählen Sie eine Währung aus',
            errorImporte: 'Geben Sie den Betrag ein',
            errorImporteValido: 'Geben Sie einen gültigen Betrag ein',
            errorBanco: 'Geben Sie eine Bank ein',
            errorCuentaBancaria: 'Geben Sie ein Bankkonto ein',
            errorCuentaInterbancaria: 'Geben Sie ein Interbankkonto ein',
            errorNota: 'Geben Sie eine Beschreibung ein'
        }
    },
    moduloRendicion: {
        navBar: [{ text: 'ABRECHNUNGEN', navigate: true, path: '/main/caja-chica' }, { text: 'ABRECHNUNG DER AUSGABEN', navigate: true, path: '/main/caja-chica/rendicion-gastos' }, { text: '', navigate: false, path: '' }],
        textoEstadoCuenta: "Mein Kontoauszug",
        textoSolicitudCajaChica: "Antrag auf Petty Cash",
        textoImportePendientePago: "Betrag Ausstehende Zahlung",
        textoTituloImporte: "Betrag",
        textoCodigo: "Code",
        textoTituloPendienteRendir: "Ausstehend zu Berichten",
        detail: {
            textTituloGeneral: 'Allgemeine Informationen',
            textOT: 'OT',
            textPersonal: 'Personal',
            textAnticipo: 'Kleinkassenantrag',
            textBanco: 'Bank',
            textCuentaBancaria: 'Bankkonto',
            textCuentaInterbancaria: 'Interbankkonto',
            textFecha: 'Datum',
            textDescripcion: 'Beschreibung',

            textTituloGastos: 'Ausgaben zuordnen',
            textProveedor: 'Lieferant',
            textImporte: 'Betrag',
            btnAprobacion: 'Genehmigung senden',

            textTituloNuevoGasto: 'Neue Ausgabe',
            textTituloFlujo: 'Fluss',
            textTituloAsociar: 'Ausgabe zuordnen',
            textGastoEncontrado: 'Gefundene Ausgaben',
            textoGastoVacio: 'Keine damit verbundenen Gebühren',
        },
        validate: {
            errorDetail: 'Detail dieses Datensatzes nicht gefunden',
            errorOT: 'Wählen Sie eine OT aus',
            errorPersonal: 'Wählen Sie ein Personal aus',
            errorAnticipo: 'Wählen Sie einen Kleinkassenantrag aus',
            errorBanco: 'Geben Sie eine Bank ein',
            errorCuentaBancaria: 'Geben Sie ein Bankkonto ein',
            errorFecha: 'Geben Sie ein Datum ein',
            errorDescripcion: 'Geben Sie eine Beschreibung ein',
            errorGasto: 'Wählen Sie mindestens eine Ausgabe aus',
            errorFlujo: 'Wählen Sie einen Fluss aus'
        }
    },
    moduloGastoAprobacion: {
        navBar: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'ABRECHNUNG AUSGABEN', navigate: true, path: '/main/approvals/gasto-aprobacion' }, { text: '', navigate: false, path: '' }],
        navBarHistorico: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'AUSGABEN HISTORIE', navigate: true, path: '/main/approvals/historico-gastos' }, { text: '', navigate: false, path: '' }],
        validate: {
            errorDetail: 'Detail des Datensatzes nicht gefunden',
            errorComentario: 'Geben Sie einen Kommentar ein',
            errorTitulo: 'Geben Sie einen Titel ein',
            errorDescripcion: 'Geben Sie eine Beschreibung ein',
        },
        question: {
            questApprove: `Sie stehen kurz davor, diese Ausgaben zu genehmigen. Bitte bestätigen Sie diese Aktion und fügen Sie einen Kommentar hinzu, in dem Sie erklären, warum Sie diese Ausgabe genehmigen.`,
            questReject: `Sie stehen kurz davor, diese Ausgaben abzulehnen. Bitte bestätigen Sie diese Aktion und fügen Sie einen Kommentar hinzu, in dem Sie erklären, warum Sie diese Ausgabe ablehnen.`,
        },
        generic: {
            textStatusRevision: 'In Überprüfung',
            textStatusAprobado: 'Genehmigt',
            textStatusRechazado: 'Abgelehnt',
            textProveedor: 'Lieferant',
            textOT: 'OT',
            textImporte: 'Betrag',
            textImpTotal: 'Gesamtbetrag',
            textComentario: 'Kommentar',
            textConfirmacion: 'Bestätigung',
        },
        viewReport: {
            title: 'Ausgabenbericht',
            textTitleDetail: 'Detail',
            textRendidor: 'Personal',
            textFechaEnvio: 'Versanddatum',
            textImporteAsociado: 'Zugeordneter Betrag',
            textCantidadLineas: 'Anzahl der Zeilen',
            textGastos: 'Ausgaben',
            textoRevisados: 'Überprüft',
            textFechaEmision: 'Ausstellungsdatum',
            textServicio: 'Dienstleistung',
            textoBuscar: 'Suchen',
        },
        viewDetail: {
            title: 'Ausgabe',
            textTabDetail: 'Detail',
            textTabArchivos: 'Dateien',
            textTitleGeneral: 'Allgemein',
            textTitleDetalleGasto: 'Ausgabendetail',
            textDelegacion: 'Delegation',
            textFecha: 'Datum',
            textImporte: 'Betrag',
            textImpuesto: 'Steuer',
            textTipoDoc: 'Dokumenttyp',
            textServicio: 'Dienstleistung',
            textObservaciones: 'Bemerkungen',
            textEstado: 'Status',
        },
        viewHistory: {
            title: 'Notizen',
            textTitleComentario: 'Notiz',
            textTitulo: 'Titel',
            textTitleNote: 'Notizen',
            textDescripcion: 'Beschreibung',
            btnAgregarComentario: 'Notiz hinzufügen',
        }
    },
    moduloDocucobra: {
        navBar: [{ text: 'DOCUCOBRA', navigate: true, path: '/main/docucobra/list' }],
        textoVersion: 'Version',
        textoResumen: 'Zusammenfassung',
        textoAbrirResumen: 'Zusammenfassung öffnen',
        textoAbrirDocumento: 'Dokument öffnen',
        textoVerRelacionados: 'Ver Verwandte',
        textoTituloFiltros: 'Suchfilter',
        textoFiltroPais: 'Land',
        textoBuscar: 'Suchen',
        textoVersiones: 'Versionen',
        textoDocumentos: 'Urkunden',
        textoRelacionado: 'Verwandt',

        textoTituloArchivo: 'Datei',
        textoTituloArchivosRelacionados: 'Verwandte Dateien',

        textoInputBuscar: 'Suchen...',
    },
    moduloTareo: {
        navBar: [{ text: 'ZEITERFASSUNG', navigate: false, path: '/main/tareo' }],
        navBarPersonal: [{ text: 'ZEITERFASSUNG', navigate: true, path: '/main/tareo' }, { text: 'MEINE ZEITERFASSUNG', navigate: true, path: '/main/tareo/personal' }, { text: '', navigate: false, path: '' }],
        navBarAprobacion: [{ text: 'GENEHMIGUNGEN', navigate: true, path: '/main/approvals' }, { text: 'ZEITERFASSUNG', navigate: true, path: '/main/approvals/tareo' }, { text: 'Detail', navigate: false, path: '' }],
        detail: {
            textDetalleAusencia: 'DETAIL ZUR ABWESENHEIT',
            textPeriodo: 'Zeitraum',
            textPeriodoInicial: 'Anfangszeitraum',
            textPeriodoFinal: 'Endzeitraum',
            textEstado: 'Status',
            textTotalHoras: 'Gesamtstunden',
            textHoras: 'Stunden',
            textTitle: 'ZEITERFASSUNGSDETAILS',
            textTitleGeneral: 'ALLGEMEINE DETAILS',
            textTitleModalAutocompletar: 'Zeitraum automatisch vervollständigen',
            textTituloDetalle: 'Detail',
            textOT: 'OT',
            textDisciplina: 'Disziplin',
            textFechaInicio: 'Startdatum',
            textFechaFinal: 'Enddatum',
            textExcluyeSabado: 'Samstag ausschließen',
            textExcluyeDomingo: 'Sonntag ausschließen',
            textExcluyeFeriado: 'Feiertage ausschließen',
            table: [
                { key: 'DiaMes', label: 'Datum' },
                { key: 'Nombre', label: 'Tag' },
                { key: 'OT', label: 'OT' },
                { key: 'HorasTrabajador', label: 'Gesamtstunden' },
                { key: 'Preview', label: '' }
            ],
            tableAusencia: [
                // { key: 'Delete', label: '' },
                { key: 'Preview', label: '' },
                { key: 'Estado', label: 'Status' },
                { key: 'FechaInicio', label: 'Startdatum' },
                { key: 'FechaFinal', label: 'Enddatum' },
            ]
        },
        ausencia: {
            textAgregar: 'Abwesenheit Hinzufügen',
            textVisualizar: 'Abwesenheit Ansehen',
            textEditar: 'Abwesenheit Bearbeiten',
            textTipo: 'Abwesenheitstyp',
            textObservacion: 'Bemerkung',
            textAdjuntarArchivo: 'Datei Anhängen',
            textArchivoDocumento: 'Dokumentdatei',
            btnEnviarAprobacion: 'Zur Genehmigung Senden'
        },        
        validate: {
            errorPeriodo: 'Zeitraum ist nicht konfiguriert',
            errorDetail: 'Detail für nicht gefunden',
            errorOTBase: 'Basis-OT ist nicht konfiguriert',
            errorOT: 'Ihre Basis-OT wurde nicht gefunden',
            errorOTRequired: 'Wählen Sie eine OT aus',
            errorDisciplinaRequired: 'Wählen Sie eine Disziplin aus',
            errorHoraRequired: 'Geben Sie eine Stunde ein',
            errorDetalleCantidad: 'Sie müssen mindestens einen Eintrag hinzufügen',
            errorHoraMaximaDia: 'Die Gesamtstunden pro Tag dürfen nicht überschritten werden',
            errorDuplicadoItem: 'Diese OT und Disziplin existiert bereits',
        },
        question: {
            questLimpiarPeriodo: 'Möchten Sie die Stunden für den gesamten Zeitraum löschen?',
            questAutocomplete: 'Alle aktuellen Stunden werden ersetzt und automatisch vervollständigt. Möchten Sie fortfahren?',
        },
        days: {
            LU: "Montag",
            MA: "Dienstag",
            MI: "Mittwoch",
            JU: "Donnerstag",
            VI: "Freitag",
            SA: "Samstag",
            DO: "Sonntag",
        }
    },
    menu: {
        '010': { titulo: 'GENEHMIGUNGEN' },
        '011': { titulo: 'Systemanforderung' },
        '012': { titulo: 'Einkaufsanforderung' },
        '013': { titulo: 'Vergleich' },
        '014': { titulo: 'Rechnung' },
        '015': { titulo: 'Kassenanforderung' },
        '016': { titulo: 'Ausgabenabrechnung' },
        '017': { titulo: 'Antragsblatt' },
        '018': { titulo: 'Arbeitsbedingungen' },
        '019': { titulo: 'Ausgabenabrechnung' },

        '020': { titulo: 'DOCUCOBRA' },
        '030': { titulo: 'ABRECHNUNGEN' },
        '031': { titulo: 'Ausgaben Genehmigung' },
        '032': { titulo: 'Ausgaben Historie' },
        '033': { titulo: 'Abrechnungen' },
        '036': { titulo: 'Kassenanforderung' },

        '040': { titulo: 'ZEITERFASSUNG' },
        '041': { titulo: 'Meine Zeiterfassung' },
        '042': { titulo: 'Gruppen-Zeiterfassung' },
        '043': { titulo: 'Zeiterfassung' },
        '044': { titulo: 'Abwesenheit' },
        '045': { titulo: 'Docucobra' },
    },
    languages: [
        { code: 'es', name: 'Spanisch' },
        { code: 'en', name: 'Englisch' },
        { code: 'de', name: 'Deutsch' },
        { code: 'it', name: 'Italienisch' },
    ]
}
