import { idioma_de } from "./idioma_de";
import { idioma_en } from "./idioma_en";
import { idioma_es } from "./idioma_es";
import { idioma_it } from "./idioma_it";

export const LIST_LANGUAGE: any = {
    es: idioma_es,
    en: idioma_en,
    de: idioma_de,
    it: idioma_it,
}