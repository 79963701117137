// Domain
import { EntityDataUsuario } from "../../../shared/Domain/EntityDataUsuario";
import { EntityDocucobra } from "../../../shared/Domain/EntityDocucobra";
import { EntityResponseCategoria } from "../Domain/EntityResponse";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntityMain } from "../Domain/EntityMain";

// Infraestructure
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { EntityUserAccess, EntityUserAccessData, GetDataForUserDocucobra } from "../../../shared/Domain/EntityUserAccess";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async getDocuments(filtros: { user: EntityDataUsuario }): Promise<EntityDocucobra[]> {
        let responseGlobal: EntityUserAccessData[] = [];
        try {
            let url = `${this.urlBase}/master/select`;
            let payloadRequest = {
                Filtros: [{ "$match": { "IdUser": filtros.user.IdUser, "Padre": { "$elemMatch": { "Padre": { $in: ["DocuCobra"] } } } } }],
                CollectionName: 'Master_UserPages',
                DataBase: 'gia'
            };
    
            // Obtener permisos asignados en DocuCobra
            let response: EntityUserAccess[] | null = (await this.service.call<any>("POST", url, JSON.stringify(payloadRequest), "basic", "json", 'json', {}, 0));
            const docucobra = response?.find(row => !row.Padre?.some(x => ["workflow", "procesos"].includes(x.Padre.toLocaleLowerCase())));

            // Parsear los permisos
            responseGlobal = (await this.service.call<any>("POST", `${this.urlBase}/master/selectGlobal`, JSON.stringify({ IdProceso: 2, data: docucobra?.Access?.Global || [] }), "bearer", "json", 'json', {}, 0));

        } catch(err) {
            return [];
        }

        // Formatear permisos como parámetros
        const responseFormatted = GetDataForUserDocucobra(responseGlobal);
        let arrFilial: Array<any> = []
        try {
            const data = {
                CollectionName: 'Acceso_Delegacion',
                DataBase: 'SISACCE',
                Filtros: [
                    {
                        $match: {
                            codigo: { $in: responseFormatted.Delegacion },
                            estado: true,
                        },
                    },
                ]
            }
            const responseFiliales = await this.service.call<any>("POST", `${this.urlBase}/master/select`, JSON.stringify(data), "bearer", "json", 'json', {}, 0)
            arrFilial = (responseFiliales || []).map((row: any) => row.filial.codigo);
        } catch(err) {}

        if (responseFormatted.Pais.length === 0) responseFormatted.Pais = [filtros.user.Pais.CDPais]
        if (responseFormatted.Grupo.length === 0) responseFormatted.OT = [filtros.user.DatosTrabajo.Delegacion]

        const paramsConfidencialidad = {
            'PUBLICO': ['PUBLICO'],
            'INTERNO': ['PUBLICO', 'INTERNO'],
            'RESTRINGIDA': ['PUBLICO', 'INTERNO', 'RESTRINGIDA'],
            'CONFIDENCIAL': ['PUBLICO', 'INTERNO', 'RESTRINGIDA', 'CONFIDENCIAL']
        };

        // Realizar filtro
        const filters = {
            fields: {},
            filter: {
                "$or": [
                    {
                        "Security.Codigo": { $in: paramsConfidencialidad[filtros.user.Confidencialidad.Codigo as keyof typeof paramsConfidencialidad] },
                        "Group.Codigo": { $in: [ ...responseFormatted.Grupo, "TODOS" ] },
                        "Country.Codigo": { $in: [ ...responseFormatted.Pais, "TODOS"] },
                        "Filial.Codigo": { $in: [ ...arrFilial, "TODOS" ] },
                    },
                    {
                        "Type": "FOLDER"
                    }
                ]
            }
        }

        try {
            let data: EntityDocucobra[] = (await this.service.call<EntityDocucobra[]>(
                "POST",
                `${this.urlBase}/v2/DocuCobra/Documentos/find`,
                JSON.stringify(filters),
                "bearer",
                "json",
                "json",
                { "request-decrypt-response": true },
                0
            )) as EntityDocucobra[];

            return data;
        } catch (error) {
            return []
        }
    }

    public async getFolderCategoria(): Promise<EntityResponseCategoria[]> {
        try {
            const payload = {
                "Filtros": [
                    {
                        "$match": {
                            "tipo": "Categoria"
                        }
                    }
                ],
                "CollectionName": "DocuCobra_Documentos",
                "DataBase": "gia"
            };

            let data: EntityResponseCategoria[] = (await this.service.call<EntityResponseCategoria[]>(
                "POST",
                `${this.urlBase}/master/selectCollection`,
                JSON.stringify(payload),
                "bearer",
                "json",
                "json",
                { "request-decrypt-response": true },
                0
            )) as EntityResponseCategoria[];

            return data;
        } catch(err) {
            return [];
        }
    }

    public async formatDocuments(payload: EntityMain): Promise<any[]> {
        const languageTranslate = LanguageTranslate();
        const documento = [];
        for (const row of (payload.dataCompleteDocucobra?.Archivos || [])) {
            documento.push({
                name: languageTranslate.textoArchivo,
                path: row,
                code: row.fileName,
                principal: true
            })
        }

        for (const row of payload.dataCompleteDocucobra?.Resumenes || []) {
            documento.push({
                name: languageTranslate.moduloDocucobra.textoResumen,
                path: row,
                code: row.fileName,
                principal: true
            })
        }

        for (const row of (payload.dataCompleteDocucobra?.Relacionados || [])) {
            try {
                let url: string = `${this.urlBase}/v2/DocuCobra/Documentos/find`;
                const [result] = await this.service.call<any>("POST", url, JSON.stringify({ fields: { Bitacora: 0, Historial: 0, Users: 0, Dates: 0 }, filter: { "Codigo": row.Codigo } }), 'bearer', "json", 'json', { "request-decrypt-response": true }, 0);

                if (result)
                    for (const item of result.Archivos) {
                        documento.push({
                            name: languageTranslate.moduloDocucobra.textoRelacionado,
                            path: item,
                            code: item.fileName,
                            principal: true,
                            dataCompleteDocucobra: result,
                            type: 'file-relacionado',
                        })
                    }
            } catch(err) {}
        }

        return documento;
    }
}