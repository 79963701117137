import { useEffect, useState } from "react";
import { Document, Page, pdfjs } from "react-pdf";
import ElementModalCostume from "./ElementModalCostume";
import { LanguageTranslate } from "../Infraestructure/LanguageTranslate";
import './ElementModalPDF.scss';

interface IPropsView {
    show: boolean;
    title: string;
    onCloseModal: () => void;
    src: string | null;
    downloadFile?: (src: string) => Promise<void>;
    textMarkOnDocument?: string;
}

const languageTranslate = LanguageTranslate();

const ElementModalPDF = (props: IPropsView) => {
    const { src, show, title, onCloseModal } = props;

    pdfjs.GlobalWorkerOptions.workerSrc = `https://cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;
    const [numPages, setNumPages] = useState(0);
    const [pageNumber, setPageNumber] = useState(1);
    const [loadingDownload, setLoadingDownload] = useState(false);

    const onDocumentLoadSuccess = ({ numPages }: any) => setNumPages(numPages);

    const goToPrevPage = () => setPageNumber(pageNumber - 1 <= 1 ? 1 : pageNumber - 1);

    const goToNextPage = () => setPageNumber(pageNumber + 1 >= numPages ? numPages : pageNumber + 1,);

    const prevDownload = async () => {
        if (props.downloadFile && src) {
            setLoadingDownload(true);
            await props.downloadFile(src);
            setLoadingDownload(false);
        }
    }

    useEffect(() => {
        if (src === null) {
            setPageNumber(1);
        }
    }, [src]);

    useEffect(() => {
        setPageNumber(1);
    }, [show])

    return (
        <ElementModalCostume
            show={show}
            title={title}
            closeModal={() => { onCloseModal(); }}
            height="92%"
        >
            <div className="row ElementModalPDF">
                <div className="col-12">
                    {
                        !!src
                            ? <div style={{ height: '100%', position: 'relative' }}>
                                <Document file={src} onLoadSuccess={onDocumentLoadSuccess} >
                                    <Page pageNumber={pageNumber} />
                                </Document>
                                {
                                    !!props.textMarkOnDocument && numPages > 0 ?
                                        <div className="ViewTextMarkOnDocument">
                                            <span>{ props.textMarkOnDocument }</span>
                                        </div>
                                    :
                                        null
                                }
                            </div>
                            : <></>
                    }
                </div>
                <div className="col-12">
                    <div className="divPaginado">
                        <button className="btn btn-sm btn-primary" onClick={goToPrevPage} disabled={pageNumber === 1}>{`<`}</button>
                        <p>
                            {languageTranslate.textoPagina} {pageNumber} {languageTranslate.textoPaginaDe} {numPages}
                        </p>
                        <button className="btn btn-sm btn-primary" onClick={goToNextPage} disabled={pageNumber === numPages}>{`>`}</button>
                    </div>
                    {
                        (props.downloadFile && props.src) ?
                        <button disabled={loadingDownload} className="btn btn-primary mt-4 w-100" onClick={prevDownload}>
                            { loadingDownload ? languageTranslate.textoDescargando : languageTranslate.textoDescargar }
                        </button>
                        :
                        null
                    }
                </div>
            </div>
        </ElementModalCostume>
    );
};

export default ElementModalPDF;