import React from 'react';
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import { EntityMain } from '../../Domain/EntityMain';
import { EntityModeMultipleSelect } from '../../Domain/EntityModeMultipleSelect';
import { EntityAnticipo } from '../../../../shared/Domain/EntityAnticipo';

interface ContainerProps {
    data: EntityMain<EntityAnticipo>[];
    onRedirectForm(id: string): any;
    show: boolean;
    copyCodeItem: (code: string) => Promise<void>;
    modeMultipleSelect: EntityModeMultipleSelect;
    onSelectItem: (code: string) => void;
}
const languageTranslate = LanguageTranslate();

const itemStatusSolicitud: any = {
    1: {
        color: "",
        text: languageTranslate.textoEstadoAprobado
    },
    2: {
        color: "",
        text: languageTranslate.textoEstadoAbierto
    },
    3: {
        color: "",
        text: languageTranslate.textoEstadoEnAprobacion
    },
    5: {
        color: "",
        text: languageTranslate.textoEstadoEnAprobacion
    },
    8: {
        color: "",
        text: languageTranslate.textoEstadoPagado
    },
    11: {
        color: "",
        text: languageTranslate.textoEstadoAnulado
    }
}

const itemStatusNavision: any = {
    "0": {
        color: "",
        text: "Pendiente Pago" //"Pendiente Navisión"
    },
    "5": {
        color: "",
        text: "Pendiente Pago" // Contabilizado
    },
    "6": {
        color: "",
        text: "Pendiente Pago" // Programado
    },
    "7": {
        color: "",
        text: "Depositado"//"Depositado"
    },
    "8": {
        color: "",
        text: "Depositado"
    },
}

export const ViewSolicitudAnticipo = (props: ContainerProps) => {
    if (!props.show) return null;

    return (
        <div className="row pt-3 mb-5">
            <div className="col-12 mb-5">
                {
                    props.data.map(row => {
                        if (props.modeMultipleSelect.active && row.isPendingSend) return null;
                        if (props.modeMultipleSelect.active && row.dataComplete.Status.Solicitud.IdStatus !== 2) return null;

                        return (
                            <div className="row pb-3 ItemGenericList" key={row.Codigo}>
                                <div className="col-12">
                                    <div className={`shadow-lg position-relative ${row.isPendingSend ? 'pending' : ''}`} style={{ width: '100%', padding: 5, margin: 0, borderRadius: '10px', cursor: 'pointer' }}>
                                        <div className={`${props.modeMultipleSelect.active ? 'ModeSelectItem' : ''} ${props.modeMultipleSelect.listCode.includes(row.Codigo) ? 'ModeSelectItem-select' : ''}`} onClick={() => props.onSelectItem(row.Codigo)} />
                                        <div className="row pt-1" style={{ margin: '1px' }} onClick={() => props.onRedirectForm(row.Codigo)} >
                                            <div className="col-10" style={{ fontSize: 12 }}>
                                                <div className='d-flex'>
                                                    <span>{languageTranslate.moduloGenericList.item.itemTextOT}:</span>
                                                    <span className='text-muted'> {row.dataComplete.DatosTrabajo.OT.Codigo} - {row.dataComplete.DatosTrabajo.OT.OT} </span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>{languageTranslate.moduloGenericList.item.itemTextImporte}:</span>
                                                    <span>{row.dataComplete.DatosImporte.Solicitud.ImporteTotal} {row.dataComplete.DatosImporte.Moneda.Moneda}</span>
                                                </div>
                                            </div>
                                            <div className="icon-arrow-item">
                                                <i className="fa-solid fa-angle-right" style={{ fontSize: '20px' }} />
                                            </div>

                                        </div>
                                        <div className="row pt-1" style={{ margin: '1px' }} onClick={() => props.onRedirectForm(row.Codigo)} >
                                            <div className="col-6">
                                                <span className='text-danger' style={{ fontSize: '13px' }}> {AdapterGenerico.calculoTiempo(row.FechaRegistro)} </span>
                                            </div>
                                            <div className="col-6 GroupBadge">
                                                <div className="badgeCustom">
                                                    {
                                                        row.dataComplete.Status.Solicitud.IdStatus !== 1 ?
                                                        <span>{itemStatusSolicitud[row.dataComplete.Status.Solicitud.IdStatus]?.text}</span>
                                                        : null
                                                    }
                                                    {
                                                        row.dataComplete.Status.Solicitud.IdStatus === 1 ?
                                                        <span>{itemStatusNavision[row.dataComplete.Status.Nav.IdStatus]?.text}</span>
                                                        : null
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}