// Components
import { TableGenericDoc } from "../generic/TableGenericDoc";
import { ElementCollapseCostume } from "../../../../shared/Components/ElementCollapseCostume";

// Infraestructure
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";

// Domain
import { EntityListContent } from "../../Domain/EntityListContent";
import { EntityDocucobra } from "../../../../shared/Domain/EntityDocucobra";
import { EntityRequestDownloadFile } from "../../Domain/EntityRequestDownloadFile";


interface ContainerProps {
    listContent: EntityListContent<EntityDocucobra>;
    viewDocument: (payload: EntityRequestDownloadFile, code: string) => Promise<any>;
    show: boolean;
}

export const ViewDocucobra = (props: ContainerProps) => {
    if (!props.show) return null;
    const languageTranslate = LanguageTranslate();

    return (
        <>
            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemDetail.textTitle}
                isOpenDefault
                persistsOpen
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <div className='d-flex'>
                        <span>Nombre:</span>
                        <span className='text-muted'> { props.listContent.dataComplete?.Name } </span>
                    </div>
                    <div className='d-flex'>
                        <span>Categoría:</span>
                        <span className='text-muted'> { props.listContent.dataComplete?.Categoria.Nombre } </span>
                    </div>
                    <div className='d-flex'>
                        <span>Descripción:</span>
                        <span className='text-muted'> { props.listContent.dataComplete?.Name } </span>
                    </div>
                    <div className='d-flex'>
                        <span>Grupo:</span>
                        <span className='text-muted'> { props.listContent.dataComplete?.Group?.Nombre } </span>
                    </div>
                    <div className='d-flex'>
                        <span>País:</span>
                        <span className='text-muted'> { props.listContent.dataComplete?.Country?.Nombre } </span>
                    </div>
                    <div className='d-flex'>
                        <span>Filial:</span>
                        <span className='text-muted'> { props.listContent.dataComplete?.Filial?.Nombre } </span>
                    </div>
                    <div className='d-flex'>
                        <span>Confidencial:</span>
                        <span className='text-muted'> { props.listContent.dataComplete?.Security?.Nombre } </span>
                    </div>
                    <div className='d-flex'>
                        <span>Versión actual:</span>
                        <span className='text-muted'> { props.listContent.dataComplete?.Version } </span>
                    </div>
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={'VERSIONES'}
                className={`my-3 ${props.listContent.dataComplete?.Versiones.length === 0 ? 'CardEmpty' : ''}`}
            >
                <div className='ContentItemCollapse'>
                    <TableGenericDoc
                        payload={props.listContent.dataComplete?.Versiones.map((row, index) => ({
                            name: `Versión ${index + 1}`,
                            path: row,
                            code: row.fileName,
                            principal: true,
                            autoDownload: false,
                            keyGroup: 'version',
                            textGroup: 'Versiones'
                        })) || []}
                        viewDocument={props.viewDocument}
                    />
                </div>
            </ElementCollapseCostume>

            <ElementCollapseCostume
                textTitle={languageTranslate.moduloGenericDetail.itemDocumentos.textTitle}
                className='my-3'
            >
                <div className='ContentItemCollapse'>
                    <TableGenericDoc
                        payload={props.listContent.documento}
                        viewDocument={props.viewDocument}
                    />
                </div>
            </ElementCollapseCostume>
        </>
    )
}