import { EntityMain } from "./EntityMain"

export interface EntityUtilsInformation {
    categoria: EntityMain[]
    data: EntityMain[]
    filter: { key: 'codigo' | 'name'; value: string };
    dataFiltered: EntityMain[];
    listPrevCodigo: string[];
}

export const initEntityUtilsInformation: EntityUtilsInformation = {
    categoria: [],
    data: [],
    filter: { key: 'codigo', value: '' },
    dataFiltered: [],
    listPrevCodigo: []
}

export interface EntityUtilsConfigModal {
    key: 'detalle' | 'pdf';
    show: boolean;
    data: EntityMain | null;
    src: string;
    fileName?: string;
    arrData: EntityMain[];
    countCurrentFilesSameFilters: number;
}

export const initEntityUtilsConfigModal: EntityUtilsConfigModal = {
    key: "detalle",
    show: false,
    data: null,
    src: "",
    countCurrentFilesSameFilters: 0,
    arrData: []
}