import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EntityDataUsuario } from '../Domain/EntityDataUsuario';
import { EntityFuncionalidad } from '../Domain/EntityFuncionalidad';
import { EntityMenu } from '../Domain/EntityMenu';
import { EntityRol } from '../Domain/EntityRol';
import { AdapterStorage } from './AdapterStorage';
import { EntityResponseFiles } from '../Domain/EntityResponseFile';

var {
    auth,
    token,
    tokenRefresh,
    user,
    menu,
    rol,
    funcionalidad,
    permisoVariables,

    rolesSisacce,
    paisesSisacce,
    filialesSisacce,
    opcionesSisacce,
} = AdapterStorage.get([
    'auth',
    'token',
    'tokenRefresh',
    'user',
    'menu',
    'rol',
    'funcionalidad',
    'permisoVariables',

    'rolesSisacce',
    'paisesSisacce',
    'filialesSisacce',
    'opcionesSisacce',
]);

if (!!auth) {
    var { [`preference${(user as EntityDataUsuario).IdUser}`]: preference } = AdapterStorage.get(`preference${(user as EntityDataUsuario).IdUser}`);
}

interface SliceAuthenticationState {
    auth: boolean;
    token: string | null;
    tokenRefresh: string | null;

    user: EntityDataUsuario;

    menu: Array<EntityMenu>;
    rol: EntityRol;
    funcionalidad: EntityFuncionalidad;

    permisoVariables: {
        arrIdPaises: Array<string>;
        arrIdGrupos: Array<string>;
        arrIdDelegaciones: Array<string>;
        arrIdOT: Array<string>;
    };
    preferencia: {};

    rolesSisacce: Array<string>;
    paisesSisacce: Array<{ _id: string; codigo: string; nombre: string; }>;
    filialesSisacce: Array<{ _id: string; codigo: string; nombre: string; }>;
    opcionesSisacce: Array<EntityResponseFiles>;
};

const userDefault: EntityDataUsuario = {
    IdUser: 0,
    Codigo: '',
    User: '',
    username: '',
    LastNameFather: '',
    LastNameMother: '',
    Name: '',
    Empresa: {
        CDEmpresa: '',
        CodEmpresa: '',
        Empresa: '',
        Grupo: ''
    },
    DatosTrabajo: {
        Area: '',
        Cargo: '',
        Delegacion: '',
        OT: ''
    },
    TipoDocumento: {
        IdTipoDocumento: 0,
        TipoDocumento: ''
    },
    FullName: '',
    Identificacion: '',
    Profile: [],
    Email: '',
    Tasa: false,
    Foto: {
        Directory: [],
        File: ''
    },
    Pais: {
        CDPais: '',
        Code: '',
        Name: ''
    },
    TypeLogin: 0,
    ChangePasswordObligate: false,
    MessageObligate: '',
    Confidencialidad: {
        Codigo: '',
        Nombre: ''
    },
    Rol: {
        Code: '',
        Name: ''
    }
};

const menuDefault: Array<EntityMenu> = [];

const initialState: SliceAuthenticationState = {
    auth: !!auth ? true : false,
    token,
    tokenRefresh,
    user: !!user ? user : userDefault,
    menu: !!menu ? menu : menuDefault,
    // menu: !!menu ? menu : [],
    rol: !!rol ? rol : {},
    funcionalidad: !!funcionalidad ? funcionalidad : [],

    permisoVariables: !!permisoVariables ? permisoVariables : {
        arrIdPaises: [],
        arrIdGrupos: [],
        arrIdDelegaciones: [],
        arrIdOT: [],
    },

    preferencia: !!preference ? preference : { pais: null, delegacion: null, ot: null },

    rolesSisacce: !!rolesSisacce ? rolesSisacce : [],
    paisesSisacce: !!paisesSisacce ? paisesSisacce : [],
    filialesSisacce: !!filialesSisacce ? filialesSisacce : [],
    opcionesSisacce: !!opcionesSisacce ? opcionesSisacce : [],
};


const authenticationSlice = createSlice({
    name: 'authenticationSlice',
    initialState,
    reducers: {
        signIn: (state, { payload }: PayloadAction<any>) => {
            AdapterStorage.set('auth', true);
            AdapterStorage.set('user', payload.user ?? state.user);
            AdapterStorage.set('menu', payload.menu ?? state.menu);
            AdapterStorage.set('token', payload.token ?? state.token);
            AdapterStorage.set('tokenRefresh', payload.tokenRefresh ?? state.tokenRefresh);

            AdapterStorage.set('rolesSisacce', payload.rolesSisacce ?? state.rolesSisacce);
            AdapterStorage.set('paisesSisacce', payload.paisesSisacce ?? state.paisesSisacce);
            AdapterStorage.set('filialesSisacce', payload.filialesSisacce ?? state.filialesSisacce);
            AdapterStorage.set('opcionesSisacce', payload.opcionesSisacce ?? state.opcionesSisacce);

            return {
                ...state,
                auth: true,
                token: payload.token ?? state.token,
                tokenRefresh: payload.tokenRefresh ?? state.tokenRefresh,
                user: payload.user ?? state.user,
                menu: payload.menu ?? state.menu,

                rolesSisacce: payload.rolesSisacce ?? state.rolesSisacce,
                paisesSisacce: payload.paisesSisacce ?? state.paisesSisacce,
                filialesSisacce: payload.filialesSisacce ?? state.filialesSisacce,
                opcionesSisacce: payload.opcionesSisacce ?? state.opcionesSisacce,
            };
        },
        signOut: state => {
            AdapterStorage.remove(['auth', 'token', 'tokenRefresh', 'user', 'menu', 'rolesSisacce', 'paisesSisacce', 'filialesSisacce', 'opcionesSisacce']);

            return {
                ...state,
                user: userDefault,
                menu: menuDefault,
                auth: false,
                token: null,
                tokenRefresh: null,

                rolesSisacce: [],
                paisesSisacce: [],
                filialesSisacce: [],
                opcionesSisacce: [],
            };
        },
        refreshTokenSisace: (state, { payload }: PayloadAction<any>) => {
            return {
                ...state,
                token: payload.token,
                tokenRefresh: payload.tokenRefresh,
            };
        },

        changePreference: (state, { payload }: PayloadAction<any>) => {
            let preferencia = {
                ...state.preferencia,
                ...payload
            };

            AdapterStorage.set(`preference${state.user.IdUser}`, preferencia);

            return { ...state, preferencia };
        },

        changePermisoVariable: (state, { payload }: PayloadAction<any>) => {
            let permisoVariables = {
                ...state.permisoVariables,
                arrIdPaises: payload.arrIdPaises,
                arrIdGrupos: payload.arrIdGrupos,
                arrIdDelegaciones: payload.arrIdDelegaciones,
                arrIdOT: payload.arrIdOT,
            };

            AdapterStorage.set('permisoVariables', permisoVariables);

            return { ...state, permisoVariables };
        },

        signInInvite: state => {
            AdapterStorage.set('auth', true);
            return {
                ...state,
                auth: true,
            };
        }
    },
});

export const {
    signIn,
    signOut,
    refreshTokenSisace,
    changePreference,
    signInInvite,
    changePermisoVariable
} = authenticationSlice.actions;

export default authenticationSlice.reducer;