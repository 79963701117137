// libray
import { useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Dispatch } from "redux";
import { useFormik } from "formik";
import * as Yup from 'yup';

// Domain
import { EntityConfigForm, EntityConfigModalAsociar, EntityConfigModalFlujo, EntityConfigModalForm, EntityConfigModalNotes, EntityNavBar, ModeForm, initConfigEntityModalAsociar, initEntityConfigForm, initEntityConfigModalFlujo, initEntityConfigModalForm, initEntityConfigModalNotes, keyModule, nameModule } from "../Domain/EntityUtils";
import { EntityRequestFormServiceSendApproval, EntityRequestNote, EntityRequestUpdateGasto, initEntityRequestGenericForm } from "../Domain/EntityRequest";
import { EntityOT } from "../../../../../shared/Domain/Catalogos/EntityOT";
import { EntityGasto } from "../../../../../shared/Domain/EntityGasto";

// Use case
import { UseCaseLoadInitialData } from "../Application/UseCaseLoadInitialData";
import { UseCaseFind } from "../Application/UseCaseFind";

// Infraestructure
import { addLoading, changeSaludo, removeLoading } from "../../../../../shared/Infraestructure/SliceGenerico";
import { LanguageTranslate } from "../../../../../shared/Infraestructure/LanguageTranslate";
import { AdapterGenerico } from "../../../../../shared/Infraestructure/AdapterGenerico";
import { AdapterValidator } from "../../../../../shared/Infraestructure/AdapterValidator";
import { RootState } from "../../../../../shared/Infraestructure/AdapterStore";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { AdapterConfigure } from "./AdapterConfigure";

// Manager
import { ViewGastoCustomManager } from "../../../Gasto/Form";
import { EntityFlujoOT } from "../../../../../shared/Domain/Catalogos/EntityFlujoOT";


const languageTranslate = LanguageTranslate();
let nameStore: keyModule = '';
let modeForm: ModeForm = '';


export const Controller = () => {
    const ManagerModuleGasto = ViewGastoCustomManager();
    const [configForm, setConfigForm] = useState<EntityConfigForm>(initEntityConfigForm)
    const [configModalAsociar, setConfigModalAsociar] = useState<EntityConfigModalAsociar>(initConfigEntityModalAsociar);
    const [configModalForm, setConfigModalForm] = useState<EntityConfigModalForm>(initEntityConfigModalForm);
    const [configModalNotes, setConfigModalNotes] = useState<EntityConfigModalNotes>(initEntityConfigModalNotes);
    const [configModalFlujo, setConfigModalFlujo] = useState<EntityConfigModalFlujo>(initEntityConfigModalFlujo);
    const { generico: { websocket, dbLocal }, auth: { user } } = useSelector((state: RootState) => state);
    const dispatch: Dispatch = useDispatch();
    const params = useParams();
    const navigate = useNavigate();

    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const formikForm = useFormik({
        initialValues: initEntityRequestGenericForm,
        onSubmit: () => {},
        validationSchema: Yup.object({
            Codigo: Yup.string().nullable(),
            OT: Yup.object().required(languageTranslate.moduloRendicion.validate.errorOT).nullable(),
            Personal: Yup.object().required(languageTranslate.moduloRendicion.validate.errorPersonal).nullable(),
            Anticipo: Yup.object().when(['ActivarAnticipo', 'Status'], {
                is: (ActivarAnticipo: boolean, Status: any) => (Status.IdStatus !== 10 && (ActivarAnticipo || configForm.dataOptions.anticipo.length > 0)),
                then: () => Yup.object().required(languageTranslate.moduloRendicion.validate.errorAnticipo).nullable() 
            }).nullable(),
            Banco: Yup.string()/*.when(['ActivarAnticipo'], {
                is: (ActivarAnticipo: boolean) => !ActivarAnticipo,
                then: () => Yup.string().required(languageTranslate.moduloRendicion.validate.errorBanco).nullable()
            })*/.nullable(),
            CuentaBancaria: Yup.string().nullable(), /*.when(['ActivarAnticipo'], {
                is: (ActivarAnticipo: boolean) => !ActivarAnticipo,
                then: () => Yup.string().required(languageTranslate.moduloRendicion.validate.errorCuentaBancaria).nullable()
            }).nullable(),*/
            CuentaInterbancaria: Yup.string().nullable(),
            Fecha: Yup.string().required(languageTranslate.moduloRendicion.validate.errorFecha).nullable(),
            Descripcion: Yup.string().required(languageTranslate.moduloRendicion.validate.errorDescripcion).nullable(),
            GastoAsociado: Yup.array().min(1, languageTranslate.moduloRendicion.validate.errorGasto).nullable()
        }),
        enableReinitialize: true
    });

    /* =============================================CONFIG FORM========================================================= */

    const init = () => {
        dispatch(changeSaludo(false));
        configModeType();
        AdapterGenerico.scrollTopByClassName('ViewRendicionGastoForm');
    };

    const configModeType = async () => {
        // Carga la información de los select
        const dataOptions = await new UseCaseLoadInitialData(repository).exec();

        // Variables
        let navBar: EntityNavBar[] = languageTranslate.moduloRendicion.navBar;
        nameStore = 'RendicionGasto';

        if (params.id) {
            // Modo editar
            modeForm = 'edit';
            const findForm = await new UseCaseFind(repository).exec(`${params.id}`, nameStore, dataOptions);
            if (!findForm) {
                AdapterGenerico.createMessage(languageTranslate.textoError, languageTranslate.moduloRendicion.validate.errorDetail, 'error').then(res => navigate(AdapterConfigure.LIST_RENDICION)); 
                return;
            }
            formikForm.setValues(findForm);
            dataOptions.gasto = [...dataOptions.gasto, ...findForm.GastoAsociado]
            if (![0, 10].includes(findForm.Status.IdStatus)) modeForm = 'preview';
        } else {
            // Modo crear
            modeForm = 'create';

            // Autogenera Código
            formikForm.setFieldValue('Codigo', `${Date.now()}`)

            // Verifica la OT
            if (user.DatosTrabajo.OT) {
                const resultOT = dataOptions.ot.find(row => row.dataComplete.Code === user.DatosTrabajo.OT)

                if (!resultOT) {
                    AdapterGenerico.createMessage(languageTranslate.textoError, `OT '${user.DatosTrabajo.OT}' no encontrada`, 'error').then(res => navigate(AdapterConfigure.LIST_RENDICION)); 
                    return;
                }
                onChange('OT', resultOT);
            }

            // Verifica el personal para obtener los datos del banco
            const resultPersonal = await repository.findPersonal(user.Identificacion);
            if (!resultPersonal) {
                AdapterGenerico.createMessage(languageTranslate.textoError, `No se encontró el usuario: ${user.Identificacion}`, 'error').then(res => navigate(AdapterConfigure.LIST_RENDICION)); 
                return;
            }

            // resultPersonal.DatosBanco.Banco.Name = 'BCP';
            // resultPersonal.DatosBanco.CuentaCorriente = '455743859348573498';
            /*if (!resultPersonal.DatosBanco.CuentaCorriente) {
                AdapterGenerico.createMessage('Error', `El usuario no tiene configurado su Cuenta Bancaria`, 'error').then(res => navigate(AdapterConfigure.LIST_RENDICION)); 
                return;
            }*/

            formikForm.setFieldValue('Personal', {
                ApellidoMaterno: resultPersonal.ApellidoMaterno,
                ApellidoPaterno: resultPersonal.ApellidoPaterno,
                Identificacion: resultPersonal.Identificacion,
                Nombres: resultPersonal.Nombres,
                TipoDocumento: resultPersonal.TipoDocumento.Key
            });
            formikForm.setFieldValue('Banco', resultPersonal.DatosBanco?.Banco?.Name || resultPersonal.DatosBanco?.Banco?.Key || '');
            formikForm.setFieldValue('CuentaBancaria', resultPersonal.DatosBanco?.CuentaCorriente || '');
            formikForm.setFieldValue('CuentaInterbancaria', resultPersonal.DatosBanco?.CuentaInterbancaria || '');
            formikForm.setFieldValue('Fecha', AdapterGenerico.convertDateToString(new Date(), 6));

            if (dataOptions.anticipo.length > 0) {
                const lastAnticipo = dataOptions.anticipo[dataOptions.anticipo.length - 1];
                formikForm.setFieldValue('ActivarAnticipo', true);
                formikForm.setFieldValue('Anticipo', lastAnticipo);
            }
        }

        // Modifica el navbar para el lenguaje
        if (navBar.length >= 3) navBar[2].text = nameModule[modeForm];

        // Guarda la información general y la del usuario
        setConfigForm(() => ({
            ...configForm,
            loadingForm: false,
            dataOptions: dataOptions,
            key: nameStore,
            navBar: navBar,
            mode: modeForm,
            step: 1
        }));

        formikForm.setFieldValue("User", { IdUser: user.IdUser, User: user.User, LastName: `${user.LastNameFather} ${user.LastNameMother}`, Name: user.Name, Perfil: user.Profile.find(row => row.Principal === 'SI')?.Perfil || '' })
    };

    const onChange = (name: string, value: any) => {
        switch (name) {
            case 'OT':
                const otSelected: EntityOT = value.dataComplete;
                formikForm.setFieldValue('Pais', otSelected.DatosPais);
                formikForm.setFieldValue('Empresa', otSelected.DatosEmpresa);
                formikForm.setFieldValue('Delegacion', otSelected.DatosTrabajo.Delegacion);
                formikForm.setFieldValue('Personal', null)
                formikForm.setFieldValue('Banco', null);
                formikForm.setFieldValue('CuentaBancaria', null);
                formikForm.setFieldValue('CuentaInterbancaria', null);
                break;
        }
        formikForm.setFieldValue(name, value);
    };

    const onChangeStep = async (newCurrentStep: number) => {
        if (newCurrentStep <= configForm.step) {
            setConfigForm((prev) => ({
                ...prev,
                step: newCurrentStep
            }))
            AdapterGenerico.scrollTopByClassName('ViewRendicionGastoForm')
        } else {
            try { await formikForm.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formikForm.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }
            
            setConfigForm((prev) => ({
                ...prev,
                step: newCurrentStep
            }))
            AdapterGenerico.scrollTopByClassName('ViewRendicionGastoForm')
        }
    }

    const onSubmit = async (e: Event) => {
        try {
            e.preventDefault();
            e.stopPropagation();

            try { await formikForm.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formikForm.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const dataFormatted = await repository.formatDataToSave(formikForm.values);
            const dataGasto: EntityRequestUpdateGasto = {
                GastoEnd: formikForm.values.GastoAsociado,
                GastoInit: formikForm.values.GastoAsociadoInit
            }
            await repository.save(dataFormatted, configForm.mode, dataGasto)

            dispatch(removeLoading());
            navigate(AdapterConfigure.LIST_RENDICION, { replace: true });
        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        };
    };

    const onCancelForm = () => {
        navigate(AdapterConfigure.LIST_RENDICION);
    };

    /* ========================================CONFIG MODAL ASOCIAR===================================================== */

    const openModalAsociar = () => {
        const optionsForOT = configForm.dataOptions.gasto.filter(row => row.DatosTrabajo.OT.Codigo === formikForm.values.OT?.dataComplete.Code);

        setConfigModalAsociar((prev) => ({
            ...prev,
            listGasto: [...optionsForOT],
            showListGasto: [...optionsForOT],
            loadingSearch: false,
            show: true,
            textSearch: '',
            selectGasto: [...formikForm.values.GastoAsociado]
        }))
    }

    const closeModalAsociar = () => {
        setConfigModalAsociar((prev) => ({
            ...prev,
            show: false,
            selectGasto: []
        }))
    }

    const onChangeSearchMA = (name: string, value: string) => {
        setConfigModalAsociar((prev) => ({
            ...prev,
            textSearch: value
        }))
    }

    const onChangeItemMA = (gasto: EntityGasto[]) => {
        setConfigModalAsociar((prev) => ({
            ...prev,
            selectGasto: gasto
        }))
    }

    const onSubmitSearchMA = () => {
        setConfigModalAsociar((prev) => ({
            ...prev,
            showListGasto:
                configModalAsociar.textSearch ?
                    configModalAsociar.listGasto.filter(row =>
                        row.DatosEmpresa.NroDocumento.toLocaleLowerCase().search(configModalAsociar.textSearch.toLocaleLowerCase()) >= 0 ||
                        row.DatosEmpresa.RazonSocial.toLocaleLowerCase().search(configModalAsociar.textSearch.toLocaleLowerCase()) >= 0
                    ) :
                    configModalAsociar.listGasto
        }))
    }

    const onSubmitMA = () => {
        formikForm.setFieldValue('GastoAsociado', configModalAsociar.selectGasto)
        closeModalAsociar()
    }

    /* =======================================CONFIG MODAL FORM======================================================== */

    const openModalForm = async (codigo?: string) => {
        if (codigo) {
            const result = await ManagerModuleGasto.controller.findFormConfig(codigo, modeForm);
            if (result) setConfigModalForm(() => ({ show: true, codigo }));
        } else {
            ManagerModuleGasto.controller.onChange('OT', formikForm.values.OT);
            ManagerModuleGasto.controller.createFormConfig();
            setConfigModalForm(() => ({
                show: true,
                codigo: ''
            }));
        }
    }

    const closeModalForm = () => {
        ManagerModuleGasto.controller.resetForm();
        setConfigModalForm(() => ({
            show: false,
            codigo: ''
        }));
    }

    const onSubmitMF = async (evt: Event) => {
        const result = await ManagerModuleGasto.controller.onSubmit(evt, true, formikForm.values.GastoAsociado);
        if (!result) return;

        const GastoAsociado = formikForm.values.GastoAsociado;

        if (configModalForm.codigo) {
            const indexFinded = GastoAsociado.findIndex(row => row.Codigo === result.Codigo);
            if (indexFinded !== -1) GastoAsociado[indexFinded] = result;
            formikForm.setFieldValue('GastoAsociado', [...GastoAsociado]);
            setConfigForm((prev) => ({
                ...prev,
                dataOptions: {
                    ...prev.dataOptions,
                    gasto: GastoAsociado
                }
            }));
        } else {
            formikForm.setFieldValue('GastoAsociado', [...GastoAsociado, result]);
            setConfigForm((prev) => ({
                ...prev,
                dataOptions: {
                    ...prev.dataOptions,
                    gasto: [...prev.dataOptions.gasto, result]
                }
            }))
        }

        ManagerModuleGasto.controller.resetForm();
        setConfigModalForm(() => ({
            show: false,
            codigo: ''
        }));
    }

    const onDeleteMF = async (data: EntityGasto) => {
        try {
            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            await ManagerModuleGasto.controller.deleteFormConfig([data.Codigo]);

            const newList: EntityGasto[] = formikForm.values.GastoAsociado;
            const indexFinded = newList.findIndex(row => row.Codigo === data.Codigo);
            if (indexFinded >= 0) newList.splice(indexFinded, 1);

            onChange('GastoAsociado', newList);
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    /* =======================================CONFIG MODAL FLUJO======================================================== */

    const openModalFlujo = () => {
        const flujoOT = configForm.dataOptions.flujo.filter(row =>
            row.OT.Code === formikForm.values.OT?.dataComplete.Code
        ).filter(row => 
            (formikForm.values.ActivarAnticipo && row.TipoOperacion.Codigo === 'O03') ||
            (!formikForm.values.ActivarAnticipo && row.TipoOperacion.Codigo === 'O02')
        );
        const flujoDelegacion: any[] = configForm.dataOptions.flujoDelegacion.filter(row =>
            row.Delegacion.Code === formikForm.values.Delegacion?.Code
        ).filter(row => 
            (formikForm.values.ActivarAnticipo && row.TipoOperacion.Codigo === 'O03') ||
            (!formikForm.values.ActivarAnticipo && row.TipoOperacion.Codigo === 'O02')
        );

        setConfigModalFlujo((prev) => ({
            show: true,
            flujo: flujoOT.length > 0 ? flujoOT : flujoDelegacion,
            flujoSelected: undefined
        }));
    }

    const closeModalFlujo = () => {
        setConfigModalFlujo(() => ({
            show: false,
            flujo: [],
            flujoSelected: undefined
        }));
    }

    const onChangeMFL = (flujo: EntityFlujoOT) => {
        setConfigModalFlujo((prev) => ({
            ...prev,
            flujoSelected: flujo
        }));
    }

    const onSubmitMFL = async (evt: Event) => {
        if (!configModalFlujo.flujoSelected) return AdapterGenerico.createMessage(languageTranslate.textoIncompleto, 'Seleccione un Flujo', 'warning', false);
        try {

            // Guardar Rendición
            try { await formikForm.submitForm(); } catch (error) { }
            try { AdapterValidator.validate(await formikForm.validateForm()); } catch (error) { AdapterGenerico.createMessage(languageTranslate.textoIncompleto, (error as Error).message, 'warning', false); return null; }

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const dataFormatted = await repository.formatDataToSave(formikForm.values);
            const dataGasto: EntityRequestUpdateGasto = {
                GastoEnd: formikForm.values.GastoAsociado,
                GastoInit: formikForm.values.GastoAsociadoInit
            }
            const responseSave = await repository.save(dataFormatted, configForm.mode, dataGasto)
            if (!responseSave) return;
            
            // Enviar a aprobación
            const dataApproval: EntityRequestFormServiceSendApproval = {
                codigo: responseSave.Codigo,
                flujo: {
                    Id: configModalFlujo.flujoSelected.Id,
                    Codigo: configModalFlujo.flujoSelected.Codigo,
                    Code: configModalFlujo.flujoSelected.Code,
                    Name: configModalFlujo.flujoSelected.Name,
                    Descripcion: configModalFlujo.flujoSelected.Descripcion,
                    Aprobacion: configModalFlujo.flujoSelected.Aprobacion
                },
                user: {
                    lastname: formikForm.values.User.LastName,
                    name: formikForm.values.User.Name,
                    user: formikForm.values.User.User
                }
            }
            await repository.sendApproval(dataApproval);

            dispatch(removeLoading());
            navigate(AdapterConfigure.LIST_RENDICION, { replace: true });
        } catch (error) {
            dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        };
    }

    /* =======================================CONFIG MODAL NOTAS======================================================== */

    const openModalNotes = () => {
        setConfigModalNotes((prev) => ({
            ...prev,
            show: true,
            titulo: '',
            comentario: ''
        }))
    }

    const closeModalNotes = () => {
        setConfigModalNotes((prev) => ({
            ...prev,
            show: false,
            titulo: '',
            comentario: ''
        }))
    }

    const onChangeMN = (name: 'comentario' | 'titulo', value: string) => {
        setConfigModalNotes((prev) => ({
            ...prev,
            [name]: value
        }))
    }

    const onSubmitMN = async (evt: Event) => {
        evt.preventDefault();
        evt.stopPropagation();

        // Validaciones
        /*if (!configModalNotes.titulo) {
            AdapterGenerico.createMessage(languageTranslate.textoIncompleto, languageTranslate.moduloGastoAprobacion.validate.errorTitulo, 'warning', false);
            return null;
        }*/
        if (!configModalNotes.comentario) {
            AdapterGenerico.createMessage(languageTranslate.textoIncompleto, languageTranslate.moduloGastoAprobacion.validate.errorDescripcion, 'warning', false);
            return null;
        }

        try {

            dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
            const payload: EntityRequestNote = {
                codigo: formikForm.values.Codigo,
                nota: {
                    titulo: configModalNotes.titulo,
                    descripcion: configModalNotes.comentario
                },
                user: {
                    user: user.User,
                    lastname: `${user.LastNameFather} ${user.LastNameMother}`,
                    name: user.Name
                }
            }

            const response = await repository.addNote(payload);

            // Actualiza la información
            if (response.Notas.length > 0) formikForm.setFieldValue('Notas', response.Notas)
            if (response.Bitacora.length > 0) formikForm.setFieldValue('Bitacora', response.Bitacora)

            // Cierra el modal de notas
            closeModalNotes();
        } catch (error) {
            AdapterGenerico.createMessage(languageTranslate.textoAlerta, (error as Error).message, 'warning', false);
        } finally {
            dispatch(removeLoading());
        }
    }

    return ({
        // Formulario
        init,
        onSubmit,
        onCancelForm,
        onChange,
        configModeType,
        onChangeStep,
        languageTranslate,
        configForm,
        formikForm,

        // Modal Asociar
        openModalAsociar,
        closeModalAsociar,
        onChangeSearchMA,
        onChangeItemMA,
        onSubmitSearchMA,
        onSubmitMA,
        configModalAsociar,

        // Modal Formulario
        componentFormGasto: ManagerModuleGasto.component,
        configModalForm,
        openModalForm,
        closeModalForm,
        onSubmitMF,
        onDeleteMF,

        // Modal Flujo
        configModalFlujo,
        openModalFlujo,
        closeModalFlujo,
        onSubmitMFL,
        onChangeMFL,

        // Modal Notas
        configModalNotes,
        openModalNotes,
        closeModalNotes,
        onChangeMN,
        onSubmitMN,
    });
}