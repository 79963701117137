// Library
import React from 'react';
import './Style.scss';

// Components
import ElementStepperContent from '../../../../../shared/Components/ElementStepperContent';
import { InputCheck, InputDate, InputSelect, InputTextArea } from '../../../../../shared/Components/ElementInputsCostume';

// Domain
import { EntityConfigForm } from '../Domain/EntityUtils';
import { EntityLanguage } from '../../../../../shared/Domain/EntityLanguage';
import { EntityGenericDetailForm } from '../../../../../shared/Domain/EntityGenericDetailForm';
import { EntityRequestGenericForm } from '../Domain/EntityRequest';

// Style
import './Style.scss';
import { ElementTableCostume } from '../../../../../shared/Components/ElementTableCostume';
import { AdapterFilterOption } from '../../../../../shared/Infraestructure/AdapterFilterOptions';
import { AdapterGenerico } from '../../../../../shared/Infraestructure/AdapterGenerico';
import { EntityGasto } from '../../../../../shared/Domain/EntityGasto';
import { ElementEmptyList } from '../../../../../shared/Components/ElementEmptyList';
import { ElementBreadCrumb } from '../../../../../shared/Components/ElementBreadCrumb';
import ElementStepperHeader from '../../../../../shared/Components/ElementStepperHeader';
import { ElementCollapseCostume } from '../../../../../shared/Components/ElementCollapseCostume';

interface ContainerProps {
    configForm: EntityConfigForm;
    languageTranslate: EntityLanguage;
    formikForm: EntityGenericDetailForm<EntityRequestGenericForm>;
    onChange: (name: string, value: any) => void;
    onSubmit: (e: any) => Promise<any>;
    onCancelForm: () => void;
    onChangeStep: (newCurrentStep: number) => void;
    openModalAsociar(): void;
    openModalForm(Codigo?: string): void;
    openModalFlujo: () => void
    onDeleteMF: (data: EntityGasto) => Promise<void>
    openModalNotes: () => void
}

export const ViewMain = (props: ContainerProps) => {

    const itemStatusSolicitud: any = {
        1: {
            color: "",
            text: props.languageTranslate.textoEstadoAprobado
        },
        2: {
            color: "",
            text: props.languageTranslate.textoEstadoAbierto
        },
        3: {
            color: "",
            text: props.languageTranslate.textoEstadoEnAprobacion
        },
        5: {
            color: "",
            text: props.languageTranslate.textoEstadoEnAprobacion
        },
        11: {
            color: "",
            text: props.languageTranslate.textoEstadoAnulado
        }
    }

    return (
        <div className='ViewRendicionGastoForm'>
            <div className="container-fluid">
                <ElementBreadCrumb list={props.configForm.navBar} />
            </div>
            <div className="container-form">
                <ElementStepperContent
                    step={1}
                    currentStep={props.configForm.step}
                    title={props.languageTranslate.moduloRendicion.detail.textTituloGeneral}
                    onSubmit={props.onChangeStep}
                    removeButtonSubmit
                >
                    <>
                        <div className="form-row mb-0">
                            {
                                props.formikForm.values.Flujo && props.formikForm.values.Flujo.Aprobacion.length > 0 ?
                                <>
                                    <label style={{ fontSize: 14 }}>{props.languageTranslate.moduloGenericDetail.itemDetail.textAprobadores}</label>
                                    <ElementStepperHeader
                                        data={props.formikForm.values.Flujo.Aprobacion.map(row => ({ label: row.Aprobador.FullName, value: row.Aprobador.Identificacion }))}
                                        onChange={() => {}}
                                        stepPosition={props.formikForm.values.Status.IdStatus === 1 ? 999 : props.formikForm.values.Aprobacion.Nivel + 1}
                                    />
                                    <div className='mb-1'>
                                        <label style={{ fontSize: 14 }}>{props.languageTranslate.moduloGenericDetail.itemDetail.textCodigoSolicitud}:&nbsp;</label>
                                        <span className='text-muted' style={{ fontSize: 14 }}>{props.formikForm.values.Codigo}</span>
                                    </div>
                                    <div className='mb-3'>
                                        <label style={{ fontSize: 14 }}>{props.languageTranslate.moduloGenericDetail.itemDetail.textEstado}:&nbsp;</label>
                                        {
                                            props.formikForm.values.Status.IdStatus !== 1 ?
                                            <span className='text-muted' style={{ fontSize: 14 }}>{itemStatusSolicitud[props.formikForm.values.Status.IdStatus]?.text}</span>
                                            : null
                                        }
                                    </div>
                                </> : null
                            }

                            {
                                props.formikForm.values.Status.IdStatus !== 0 ?
                                    <div>
                                        <ElementCollapseCostume
                                            textTitle={props.languageTranslate.moduloGenericDetail.itemNotas.textTitle}
                                            className='my-3'
                                        >
                                            <div className='ContentItemCollapse'>
                                                {
                                                    props.formikForm.values.Status.IdStatus !== 1 ?
                                                        <div className="w-100 mb-2" style={{ textAlign: 'end' }}>
                                                            <button className="btn btn-primary" onClick={props.openModalNotes}>{props.languageTranslate.btnAgregar}</button>
                                                        </div>
                                                    : null
                                                }
                                                <ElementTableCostume
                                                    listHeader={props.languageTranslate.moduloGenericDetail.itemNotas.headerTable}
                                                    listData={props.formikForm.values.Notas || []}
                                                    className="CustomTableNota"
                                                />
                                            </div>
                                        </ElementCollapseCostume>

                                        <ElementCollapseCostume
                                            textTitle={props.languageTranslate.moduloGenericDetail.itemBitacora.textTitle}
                                            className='mt-3 mb-5'
                                        >
                                            <div className='ContentItemCollapse'>
                                                <ElementTableCostume
                                                    listHeader={props.languageTranslate.moduloGenericDetail.itemBitacora.headerTable}
                                                    listData={props.formikForm.values.Bitacora || []}
                                                />
                                            </div>
                                        </ElementCollapseCostume>
                                    </div>
                                : null
                            }

                            {
                                ((props.formikForm.values.Flujo && props.formikForm.values.Flujo.Aprobacion.length > 0) || props.formikForm.values.Status.IdStatus !== 0) ? <hr/> : null
                            }

                            <InputSelect
                                label={props.languageTranslate.moduloRendicion.detail.textOT}
                                options={props.configForm.dataOptions.ot}
                                values={props.formikForm.values}
                                onChange={props.onChange}
                                loading={props.configForm.loadingForm}
                                disabled
                                className='form-group w-100'
                                name='OT'
                                isRequired
                                arrFilterOption={AdapterFilterOption.OT}
                            />

                            {/* <InputSelect
                                label={props.languageTranslate.moduloRendicion.detail.textPersonal}
                                options={
                                    props.configForm.dataOptions.personal?.filter(row =>
                                        row.dataComplete.Country.Code ===
                                        props.formikForm.values.Pais.Code
                                    )
                                }
                                values={props.formikForm.values}
                                onChange={props.onChange}
                                loading={props.configForm.loadingForm}
                                disabled={props.configForm.mode === 'preview'}
                                className='form-group w-100'
                                name='Personal'
                                isRequired
                            /> */}

                            <InputCheck
                                label={props.languageTranslate.moduloRendicion.detail.textAnticipo}
                                values={props.formikForm.values}
                                onChange={props.onChange}
                                disabled={props.configForm.mode === 'preview'}
                                className='form-group w-100'
                                name='ActivarAnticipo'
                            />

                            {
                                props.formikForm.values.ActivarAnticipo ?
                                <InputSelect
                                    label={''}
                                    options={props.configForm.dataOptions.anticipo}
                                    values={props.formikForm.values}
                                    onChange={props.onChange}
                                    loading={props.configForm.loadingForm}
                                    disabled={props.configForm.mode === 'preview'}
                                    className='form-group w-100 mt-0'
                                    name='Anticipo'
                                    arrFilterOption={AdapterFilterOption.Anticipo}
                                />
                                :
                                <>
                                    {/*
                                    <InputText
                                        label={props.languageTranslate.moduloRendicion.detail.textBanco}
                                        values={props.formikForm.values}
                                        onChange={props.onChange}
                                        disabled={props.configForm.mode === 'preview'}
                                        className='form-group w-100'
                                        name='Banco'
                                        isRequired
                                    />

                                    <InputText
                                        label={props.languageTranslate.moduloRendicion.detail.textCuentaBancaria}
                                        values={props.formikForm.values}
                                        onChange={props.onChange}
                                        disabled={props.configForm.mode === 'preview'}
                                        className='form-group w-100'
                                        name='CuentaBancaria'
                                        isRequired
                                    />

                                    <InputText
                                        label={props.languageTranslate.moduloRendicion.detail.textCuentaInterbancaria}
                                        values={props.formikForm.values}
                                        onChange={props.onChange}
                                        disabled={props.configForm.mode === 'preview'}
                                        className='form-group w-100'
                                        name='CuentaInterbancaria'
                                    /> */}
                                </>         
                            }

                            <InputDate
                                label={props.languageTranslate.moduloRendicion.detail.textFecha}
                                values={props.formikForm.values}
                                onChange={props.onChange}
                                disabled={props.configForm.mode === 'preview'}
                                className='form-group w-100'
                                name='Fecha'
                                isRequired
                            />

                            <InputTextArea
                                label={props.languageTranslate.moduloRendicion.detail.textDescripcion}
                                type=''
                                values={props.formikForm.values}
                                onChange={props.onChange}
                                disabled={props.configForm.mode === 'preview'}
                                className='form-group w-100'
                                name='Descripcion'
                                isRequired
                            />

                        </div>
                        <hr />
                        <div className="form-row mb-0 GroupGastos">
                            {
                                props.configForm.mode !== 'preview' ?
                                    <div className='d-flex'>
                                        <button
                                            onClick={() => props.openModalForm()}
                                            style={{ marginLeft: 'auto', marginRight: 10 }}
                                            className='btn btn-primary'
                                        >
                                            <i style={{ fontSize: 14, marginRight: 10 }} className='fa-solid fa-plus'/> {props.languageTranslate.btnAgregar}
                                        </button>
                                        {/* <button onClick={props.openModalAsociar} className='btn btn-primary'>{props.languageTranslate.btnSeleccionar}</button> */}
                                    </div>
                                : null
                            }
                            {
                                props.formikForm.values.GastoAsociado.length === 0 ?
                                <ElementEmptyList text={props.languageTranslate.moduloCajaChica.textoSinGastosAsociados} className='my-5 CustomEmptyList' /> :
                                <ElementTableCostume
                                    className={`${props.configForm.mode === 'preview' ? 'mb-3' : 'my-4'} TableNoWrap`}
                                    listData={props.formikForm.values.GastoAsociado.map(row => ({...row, DatosImporte: { ...row.DatosImporte, Total: AdapterGenerico.formatoDinero(row.DatosImporte.Total) }}))}
                                    listHeader={[
                                        { 
                                            key: 'Delete', label: '', render: (text, data) =>
                                            {
                                                return (
                                                    props.configForm.mode !== 'preview' ?
                                                    <i className="fa-solid fa-trash" onClick={() => props.onDeleteMF(data)} style={{ fontSize: 12 }} />
                                                    : <></>
                                                )
                                            }
                                        },
                                        { 
                                            key: 'Update', label: '', render: (text, data) =>
                                            {
                                                return (
                                                    props.configForm.mode !== 'preview' ?
                                                    <i className="fa-solid fa-pen" onClick={() => props.openModalForm(data.Codigo)} style={{ fontSize: 12 }} />
                                                    :
                                                    <i className="fa-solid fa-eye" onClick={() => props.openModalForm(data.Codigo)} style={{ fontSize: 12 }} />
                                                )
                                            }
                                        },
                                        // { key: 'FechaEmision.Fecha', label: props.languageTranslate.moduloRendicion.detail.textFecha },
                                        { key: 'DatosEmpresa.RazonSocial', label: props.languageTranslate.moduloRendicion.detail.textProveedor },
                                        { key: 'DatosImporte.Total', label: props.languageTranslate.moduloRendicion.detail.textImporte },
                                    ]}
                                />
                            }

                            {
                                props.formikForm.values.GastoAsociado.length > 0 ?
                                <div className='ImporteTotalRendido'>
                                    <span>{props.languageTranslate.moduloGasto.detail.textImporteTotal}: <span className='text-danger'>{
                                        AdapterGenerico.formatoDinero(
                                            props.formikForm.values.GastoAsociado.reduce((prev, current) => { prev += current.DatosImporte.Total; return prev; }, 0)
                                        )}
                                    </span></span>
                                </div>
                                : null
                            }


                            {
                                props.formikForm.values.ActivarAnticipo ?
                                <div className='ImporteTotalRendido mt-1'>
                                    <span>{props.languageTranslate.moduloCajaChica.textoImporteTotalSolicitud}: <span className='text-danger'>{
                                        AdapterGenerico.formatoDinero(
                                            props.formikForm.values.Anticipo?.dataComplete.DatosImporte.Solicitud.ImporteTotal || 0
                                        )}
                                    </span></span>
                                </div>
                                : null
                            }

                        </div>
                        <div className='group-button d-flex flex-column' style={{ gap: 10 }}>
                            { props.configForm.mode !== 'preview' ? <button className='btn btn-primary w-100' onClick={props.onSubmit}> {props.languageTranslate.btnGuardar} </button> : null }
                            { props.configForm.mode !== 'preview' ? <button className='btn btn-primary w-100' onClick={props.openModalFlujo}> {props.languageTranslate.moduloRendicion.detail.btnAprobacion} </button> : null }
                        </div>
                    </>
                </ElementStepperContent>
            </div>
        </div>
    )
}