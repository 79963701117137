import React, { useState } from 'react';
import { ElementTableCostume } from '../../../../shared/Components/ElementTableCostume';
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico';
import { ElementEmptyList } from '../../../../shared/Components/ElementEmptyList';
import { useNavigate } from 'react-router';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';

interface ContainerProps {
    color: string;
    title: string;
    amount: number;
    data: {
        OT: string;
        Importe: number;
        Codigo: string;
    }[]
}

export const ItemWrapper = (props: ContainerProps) => {
    const [show, setShow] = useState(false);
    const navigate = useNavigate();
    const language = LanguageTranslate();

    return (
        <>
            <div className={`ItemMyAccount mt-3 ${show ? 'ItemMyAccount-Open' : ''}`} style={{ border: `1px solid ${props.color}` }} onClick={() => setShow(!show)}>
                <div className='GroupIcon' style={{ backgroundColor: `${props.color}` }}>
                    <i className="fa-regular fa-credit-card" style={{ color: 'white' }} />
                </div>
                <div className='Information'>
                    <span className='text-dark fw-bold'>{AdapterGenerico.formatoDinero(props.amount)}</span>
                    <span className='text-muted'>{props.title}</span>
                    <i className={`IconArrow ${show ? 'fa-solid fa-sort-up' : 'fa-solid fa-sort-down Active' }`} style={{ color: props.color }} />
                </div>
            </div>
            {
                show ?
                    <div className='ListItemMyAccount' style={{ border: `1px solid ${props.color}` }} >
                        {
                            props.data.length === 0 ?
                            <ElementEmptyList text={language.textoSinDatos} />
                            :
                            <ElementTableCostume
                                listData={props.data}
                                listHeader={[
                                    { key: 'Codigo', label: 'View', render: (text, data) => <i onClick={() => navigate(data.Codigo)} className="fa-solid fa-eye" /> },
                                    { key: 'OT', label: 'OT' },
                                    { key: 'Importe', label: language.moduloRendicion.textoTituloImporte }
                                ]}
                            />
                        }
                    </div>
                :
                    null
            }
        </>
    )
} 