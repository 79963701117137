// Library
import { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

// Infraestructure
import { AdapterConfigure } from "./AdapterConfigure";
import { RepositoryImplMain } from "./RepositoryImplMain";
import { RootState } from "../../../shared/Infraestructure/AdapterStore";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { addLoading, changeSaludo, removeLoading } from "../../../shared/Infraestructure/SliceGenerico";

// Domain
import { EntityMain } from "../Domain/EntityMain";
import { EntityUtilsConfigModal, EntityUtilsInformation, initEntityUtilsConfigModal, initEntityUtilsInformation } from "../Domain/Utils";

// Use Case
import { UseCaseLoadInformation } from "../Application/UseCaseLoadInformation";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { UseCaseFormatDocuments } from "../Application/UseCaseFormatDocuments";

const languageTranslate = LanguageTranslate();

export const Controller = () => {
    const dispatch = useDispatch();
    const [data, setData] = useState<EntityUtilsInformation>(initEntityUtilsInformation);
    const [configModal, setConfigModal] = useState<EntityUtilsConfigModal>(initEntityUtilsConfigModal);
    const [configModalPDF, setConfigModalPDF] = useState<EntityUtilsConfigModal>(initEntityUtilsConfigModal);
    const { generico: { websocket, dbLocal, countProcess }, auth: { user }, } = useSelector((state: RootState) => state);
    const repository: RepositoryImplMain = new RepositoryImplMain(websocket, dbLocal, dispatch, AdapterConfigure.SCHEMA, AdapterConfigure.ENTITY);

    const init = async () => {
        dispatch(changeSaludo(false));
        await reloadData();
    }
    
    const reloadData = async () => {
        dispatch(addLoading({ textLoading: languageTranslate.textoCargando }));
        const result = await (new UseCaseLoadInformation(repository).exec({ user: user }));
        if (result) setData((prev) => ({...prev, ...result, data: [...result.categoria, ...result.data], dataFiltered: result.categoria}));
        dispatch(removeLoading());
    }

    const onClickItem = async (param: EntityMain) => {
        // Validar si es folder o archivo
        if (param.type === 'folder') {
            // Busca por la categoría
            
            // const searchItems = data.data.filter(row => row.codigoCategoria === param.codigo);
            const searchItems = data.data.filter(row => (row.codigoCategoria === param.codigo && [null, "NINGUNO"].includes(row.codigoPadre)) || row.codigoPadre === param.codigo);
            const prevCodigo = param.codigo;
    
            // Guardar información
            setData((prev) => ({
                ...prev,
                listPrevCodigo: [...prev.listPrevCodigo, prevCodigo],
                dataFiltered: searchItems
            }))
        }

        if (["file", "file-relacionado"].includes(param.type))
            openModalDetalle(param);
    }

    const onPreviousFile = () => {
        // Validar que el ítem previo exista
        const _listPrevCodigo = data.listPrevCodigo;
        const findItem = data.data.find(row => _listPrevCodigo[_listPrevCodigo.length - 2] === row.codigo);
        let searchItems: EntityMain[] = [];
        if (!findItem) {
            // Buscar en la Raíz
            searchItems = data.data.filter(row => row.codigoCategoria === "CATEGORY");
        } else {
            // Buscar dentro de una subcarpeta
            searchItems = data.data.filter(row => (row.codigoCategoria === findItem.codigo && [null, "NINGUNO"].includes(row.codigoPadre)) || row.codigoPadre === findItem.codigo);
        }

        // Eliminar el último item
        _listPrevCodigo.pop();

        // Guardar información
        setData((prev) => ({
            ...prev,
            dataFiltered: searchItems,
            listPrevCodigo: _listPrevCodigo
        }))
    }

    const onChangeSearch = (valueSearch: string) => {
        setData(prev => ({
            ...prev,
            filter: {
                ...prev.filter,
                value: valueSearch
            }
        }))
    }

    const onSubmitSearch = (evt?: Event) => {
        evt?.preventDefault();

        const _data = data.data;
        const _textFiltered = data.filter.value.toLocaleLowerCase();

        if (!_textFiltered) {
            setData((prev) => ({
                ...prev,
                filter: {
                    ...prev.filter,
                    value: ""
                },
                dataFiltered: prev.categoria
            }))

            return;
        }

        const _filtered = _data.filter(row =>
            (AdapterGenerico.searchText(_textFiltered, row.codigo) ||
            AdapterGenerico.searchText(_textFiltered, row.name)) &&
            row.type !== 'folder'
        );

        setData((prev) => ({
            ...prev,
            dataFiltered: _filtered,
            listPrevCodigo: []
        }))
    }

    // Función para el modal
    const openModalDetalle = async (param: EntityMain | null, isBack?: boolean) => {
        dispatch(addLoading({ text: languageTranslate.textoCargando }))

        let listDocuments: any[] = [];
        let versiones: any[] = [];
        if (param) listDocuments = await new UseCaseFormatDocuments(repository).exec(param);

        for (const row of (param?.dataCompleteDocucobra?.Archivos || [])) {
            versiones.push(row);
        }

        // Adjuntar versiones
        if (Array.isArray(param?.dataCompleteDocucobra?.Versiones) && param?.dataCompleteDocucobra) {
            param.dataCompleteDocucobra.Versiones = versiones.concat(param?.dataCompleteDocucobra?.Versiones || []);
        }

        let _data = param ? {
            ...param, 
            listDocuments: listDocuments
        } : null;


        // Si se usa para regresar, se eliminará el último, caso contrario agregará los archivos relacionados
        let arrData: Array<EntityMain> = [];
        if (isBack) {
            arrData = _data ? [...configModal.arrData] : [];
            arrData.pop();
        } else {
            arrData = _data ? (_data?.type === 'file-relacionado' ? [...configModal.arrData, _data] : [_data]) : [];
        }

        setConfigModal((prev) => ({
            data: _data,
            key: 'detalle',
            show: true,
            src: "",
            countCurrentFilesSameFilters: _data?.type === 'file-relacionado' ? 0 : data.dataFiltered.filter(row => row.type !== 'folder').length,
            arrData: arrData
        }))

        dispatch(removeLoading());
    }

    const closeModalGeneric = () => {
        // Función solo cuando se usa en el detalle y se abrió mas detalles (relacionados)
        if (configModal.arrData.length > 1) {
            openModalDetalle(configModal.arrData[configModal.arrData.length - 2], true);
            return;
        }

        setConfigModal((prev) => ({
            data: null,
            key: 'detalle',
            show: false,
            src: "",
            countCurrentFilesSameFilters: 0,
            arrData: []
        }))
    }

    // Función para ver el archivo del modal
    const openModalPDF = async (param: any) => {
        dispatch(addLoading({ text: languageTranslate.textoCargando }));
        const responseFile = await repository.downloadFile(param.path);
        if (!responseFile) {
            AdapterGenerico.createMessage('Error', 'No se pudo obtener el archivo, intente nuevamente', 'error');
            dispatch(removeLoading());
            return;
        } 
        const fileBase64 = await AdapterGenerico.toBase64(responseFile) as string;

        setConfigModalPDF((prev) => ({
            ...prev,
            data: param,
            key: 'pdf',
            show: true,
            src: fileBase64,
            fileName: param?.path.fileName || "",
            countCurrentFilesSameFilters: 0
        }))

        dispatch(removeLoading());
    }

    const closeModalPDF = () => {
        setConfigModalPDF((prev) => ({
            data: null,
            key: 'pdf',
            show: false,
            src: "",
            countCurrentFilesSameFilters: 0,
            arrData: []
        }))
    }

    const downloadFile = async () => {
        AdapterGenerico.downloadDocument(configModalPDF.src, configModalPDF?.fileName || '')
    }

    const onChangeTabsDocument = async (codigo: string, type: 'next' | 'prev') => {
        const _data = data.dataFiltered.filter(row => row.type !== 'folder');
        const indexFinded = _data.findIndex(row => row.codigo === codigo);
        if (indexFinded === -1) return;
        if (_data.length === 1) return;

        const newIndexNextSelected = (type === 'next' && indexFinded === _data.length - 1) ? 0 : (indexFinded + 1);
        const newIndexPrevSelected = (type === 'prev' && indexFinded === 0) ? _data.length - 1 : (indexFinded - 1);
        const _dataFinded = _data[type === 'next' ? newIndexNextSelected : newIndexPrevSelected];

        openModalDetalle(_dataFinded);
    }


    return ({
        init,
        countProcess,
        reloadData,
        data,
        onClickItem,
        onPreviousFile,
        onChangeSearch,
        onSubmitSearch,

        configModal,
        openModalDetalle,
        closeModalGeneric,
        onChangeTabsDocument,

        configModalPDF,
        openModalPDF,
        closeModalPDF,
        downloadFile,
    })
}