import "./ElementModalCostume.scss";

interface IViewModal {
  show: boolean;
  closeModal: () => void;
  height?: string
  title?: string;
  children?: any;
  disabledButtonClose?: boolean;
  childrenTitle?: JSX.Element | JSX.Element[];
}

const ElementModalCostume = (props: IViewModal) => {

  return (
    <>
      {props.show ? (
        <div className="ElementModalCostume">
          <div className="container-modal" style={{ height: props.height }} >
            <div className="header d-flex">
              {
                props.childrenTitle || <h6 className="mb-0" style={{ fontWeight: 600 }}> {props.title} </h6>
              }
              {
                !props.disabledButtonClose ?
                  <p className="mb-0" onClick={props.closeModal}>
                    <i className="fa-sharp fa-solid fa-circle-xmark" style={{ fontSize: "20px" }} />
                  </p>
                  : null
              }
            </div>
            <div className="body" style={{ maxHeight: '85%' }}>
              {
                props.children
              }
            </div>
          </div>
        </div>
      ) : null}
    </>
  );
};

export default ElementModalCostume;
