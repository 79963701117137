// Domain
import { EntityMain } from "../Domain/EntityMain";
import { RepositoryMain } from "../Domain/RepositoryMain";
import { EntityRequestLoadInformation } from "../Domain/EntityRequest";

export class UseCaseLoadInformation {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: EntityRequestLoadInformation): Promise<{ categoria: EntityMain[], data: EntityMain[] } | null> {
        return await this._exec(params);
    }

    private async _exec(params: EntityRequestLoadInformation): Promise<{ categoria: EntityMain[], data: EntityMain[] } | null> {
        let [documents, categoria] = await Promise.all([
            this.repository.getDocuments(params),
            this.repository.getFolderCategoria()
        ]);

        categoria = categoria.map(row => ({
            ...row
        }));
        console.log(documents, categoria);
        return {
            data: documents.map(row => ({
                name: row.Description || row.Name || row.Comentario || '',
                type: row.Type.toLocaleLowerCase(),
                codigo: row.Codigo,
                listDocuments: [],
                codigoCategoria: row.Categoria?.Codigo,
                codigoPadre: row.Padre,
                countFiles: documents.filter(_row => (_row.Categoria?.Codigo === row.Codigo && [null, "NINGUNO"].includes(_row.Padre)) || _row.Padre === row.Codigo).length || 0,
                dataCompleteDocucobra: row,
            })), 
            categoria : categoria.map(row => ({
                type: 'folder',
                name: row.nombre,
                codigo: row.codigo,
                listDocuments: [],
                codigoCategoria: 'CATEGORY',
                codigoPadre: 'CATEGORY',
                countFiles: documents.filter(_row => (_row.Categoria?.Codigo === row.codigo && [null, "NINGUNO"].includes(_row.Padre)) || _row.Padre === row.codigo).length || 0,
                dataCompleteCategoria: row
            }))
        };
    }
}