import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { ElementItemCard } from '../../../shared/Components/ElementItemCard';
import { EntityInformationDataInitial } from '../../../shared/Domain/EntityInformationDataInitial';
import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import './Style.scss';

interface PropsViewMain {
    informationDataInitial: EntityInformationDataInitial;
    onClickLoadCatalogo: Function;
    onClickLoadAll: Function;
};
const languageTranslate = LanguageTranslate();

export const ViewMain = (props: PropsViewMain) => {

    return (
        <div className='ViewVariable'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <div className="row mb-2 mt-2 ml-1 position-relative">
                    <ElementBreadCrumb list={[{ text: languageTranslate.moduloCatalogo.textoTitulo, navigate: false, path: '' }]} />
                    <button className='position-absolute btn btn-sm btn-primary w-auto btn-accion' onClick={() => { props.onClickLoadAll(); }}>
                        <i className="fa-solid fa-refresh" style={{ paddingRight: '5px' }} /> {languageTranslate.moduloCatalogo.textoRefrescarTodo}
                    </button>
                </div>

                {Object.keys(props.informationDataInitial).map((key: string) => {
                    if (['CTPeriodo'].includes(key)) return null;
                    return (
                        <ElementItemCard
                            key={key}
                            textHeader={key}
                            textSubHeader={
                                <span style={{ fontSize: '13px', paddingLeft: '5px', color: 'black' }} onClick={() => props.onClickLoadCatalogo(key)}>
                                    <i className="fa-solid fa-refresh" style={{ fontSize: '14px', cursor: 'pointer', paddingRight: '10px' }} />{languageTranslate.moduloCatalogo.textoRefrescar}
                                </span>
                            }
                            ago={!props.informationDataInitial[key as keyof typeof props.informationDataInitial].called ? '' : AdapterGenerico.calculoTiempo(props.informationDataInitial[key as keyof typeof props.informationDataInitial].date as Date, languageTranslate.code as 'es' | 'en')}
                            state={props.informationDataInitial[key as keyof typeof props.informationDataInitial].called ? languageTranslate.moduloCatalogo.textoSincronizado : languageTranslate.moduloCatalogo.textoNoSincronizado }
    
                        />
                    )
                })}
            </div>
        </div >
    )
};