import { EntityLanguage } from "../context/shared/Domain/EntityLanguage";

/* NOTA: modificar solo el valor, no la llave */
export const idioma_it: EntityLanguage = {
    code: 'it',
    nombreProyecto: 'Gestore Integrato di Amministrazione',
    textoLogin: 'Accedi',
    textoConfiguracion: 'Impostazioni',
    textoCerrarSesion: 'Disconnettersi',
    textoModoCrear: 'CREA',
    textoModoEditar: 'MODIFICA',
    textoModoVisualizar: 'VISUALIZZA',
    textoNavbarInicio: 'Inizio',
    textoConfigurandoAreaTrabajo: 'Configurando il tuo ambiente di lavoro...',
    textoNuevaVersion: 'Nuova versione, clicca qui per aggiornare',
    textoGuardando: 'Salvando...',
    textoCopiar: 'Copiato negli appunti',
    textoCargando: 'Caricando...',
    textoEnviando: 'Invio...',
    textoDescargando: 'Scaricamento...',
    textoDescargar: 'Scarica',
    textoPagina: 'Pagina',
    textoPaginaDe: 'di',
    textoAlerta: 'Avviso',
    textoError: 'Errore',
    textoAceptar: 'Accettare',
    textoArchivo: 'File',
    textoDocumentoNoValido: 'Documento non valido',
    textoVisualizarPDF: 'Visualizza PDF',
    textoSinDatos: 'Nessun dato',
    textoFiltros: 'Filtri',
    textoBuscar: 'Per trovare',
    textoEstado: 'Stato',
    textoAproados: 'Approvato',
    textoEnAprobacion: 'In approvazione',
    textoEstadoAprobado: 'Approvato',
    textoEstadoAbierto: 'Aperto',
    textoEstadoEnAprobacion: 'In Approvazione',
    textoEstadoPagado: 'Pagato',
    textoEstadoAnulado: 'Annullato',
    textoEstadoDevuelto: 'Ritornato',
    textoIncompleto: 'Incompleto',
    textoSinConexion: 'Nessuna Connessione',
    textoConexionEstablecida: 'Connessione stabilita',
    textoDescripcionSinConexion: 'Nessuna connessione a Internet',
    textSinResultados: 'Nessun Risultato',
    textoExitoso: 'Successo',
    textoExitosoCambioPassword: 'Cambio della password avvenuto con successo',
    textoExitosoRecuperacionPassword: 'Recupero della password avvenuto con successo, riceverai un messaggio all\'indirizzo email registrato',
    textoEliminar: 'Sei sicuro di voler eliminare i seguenti elementi?',
    btnCancelar: 'Annulla',
    btnGuardar: 'Salva',
    btnSiguiente: 'Avanti',
    btnVerImagen: 'Visualizza immagini',
    btnRegresar: 'Indietro',
    btnSeleccionar: 'Seleziona',
    btnDescartar: 'Scarta',
    btnEliminar: 'Elimina',
    btnAgregar: 'Aggiungi',
    btnRechazar: 'Rifiuta',
    btnAprobar: 'Approva',
    btnSubirArchivo: 'Carica File',
    btnTodo: 'Tutto',
    btnDownloadTemplate: 'Scarica Modello',
    btnEnviar: 'Invia',
    btnCancelarFlujo: 'Annulla Flusso',
    btnEnviarAprobacion: 'Invia Approvazione',
    btnAutocompletar: 'Autocompleta',
    btnLimpiar: 'Pulisci',
    moduloConfiguracion: {
        textoIdioma: 'Lingua',
        textoCambioPassword: 'Cambia Password',
        textoNuevaPassword: 'Nuova Password',
        textoRepetirNuevaPassword: 'Ripeti Nuova Password',
        textoBotonSaveCambioPassword: 'Salva',
        nombre: 'Spagnolo',
    },
    moduloLogin: {
        textoTitulo: 'Accesso',
        txtUsuario: 'Utente',
        txtPassword: 'Password',
        btnLogin: 'ACCESSO',
        chkRecordarUsuario: 'Ricordami',
        textoOlvidoContrasenia: 'Hai dimenticato la password?',
    },
    moduloRecuperarPassword: {
        textoTitulo: 'Recupera Password',
        textoUsuario: 'Utente',
        textoIdentificacion: 'Numero di Identificazione',
        textoCorreoElectronico: 'Email',
        textoAccion: 'Recupera',
        textoRegresarLogin: 'Torna al Login'
    },
    modulo404: {
        textoDescripcion: 'La risorsa richiesta non è disponibile',
        textoHome: 'Torna alla Inizio'
    },
    moduloNotAvailable: {
        textoCabecera: 'Risorsa Non Abilitata',
        textoDescripcion: 'La risorsa richiesta non è disponibile per il tuo dispositivo',
    },
    moduloMain: {
        textoTitulo: 'Sistema di Gestione della Sicurezza Operativa',
        textoBienvenida: 'Benvenuto!',
        textoInicio: 'Inizio',
        textoAyuda: 'Aiuto',
        textoTituloPreguntaAyuda: 'Reindirizzamento',
        textoDescripcionPreguntaAyuda: 'Sarai reindirizzato alla documentazione di aiuto del sistema, sei sicuro?',
        textoReporte: 'Segnala',
        textoRefrescarDatos: 'Aggiorna dati',
        textoTituloPreguntaReporte: 'Reindirizzamento',
        textoDescripcionPreguntaReporte: 'Sarai reindirizzato al Sistema di Gestione dei Servizi, sei sicuro?',
    },
    moduloCatalogo: {
        textoTitulo: 'AGGIORNA DATI',
        textoRefrescarTodo: 'Aggiorna Tutto',
        textoRefrescar: 'Aggiorna',
        textoSincronizado: 'Sincronizzato',
        textoNoSincronizado: 'Non sincronizzato',
    },
    moduloApprovals: {
        navBar: [{ text: 'APPROVAZIONI', navigate: false, path: '' }],
        question: {
            textoDesea: 'Desideri',
            textoAprobar: 'approvare',
            textoDevolver: 'restituire',
            textoComparativo: 'il seguente comparativo?',
            textoFactura: 'la seguente fattura?',
            textoRequerimientoSistema: 'la seguente richiesta di sistema?',
            textoRequerimientoCompra: 'la seguente richiesta di acquisto?',
            textoSolicitudCajaChica: 'la seguente richiesta di cassa piccola?',
            textoRendicionGastoCajaChica: 'la seguente rendicontazione spese di cassa piccola?',
            textoHojaPeticion: 'il seguente modulo di richiesta?',
            textoCondicionLaboral: 'la seguente condizione lavorativa?'
        }
    },
    moduloGenericDetail: {
        navBarComparativo: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'COMPARATIVO', navigate: true, path: '/main/approvals/comparative' }, { text: 'DETTAGLIO', navigate: false, path: '' }],
        navBarFactura: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'FATTURA', navigate: true, path: '/main/approvals/invoice' }, { text: 'DETTAGLIO', navigate: false, path: '' }],
        navBarRequerimientoSistemas: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'RICHIESTA SISTEMI', navigate: true, path: '/main/approvals/system-requirement' }, { text: 'DETTAGLIO', navigate: false, path: '' }],
        navBarRequerimientoCompra: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'RICHIESTA ACQUISTO', navigate: true, path: '/main/approvals/purchase-requirement' }, { text: 'DETTAGLIO', navigate: false, path: '' }],
        navBarSolicitudCajaChica: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'RICHIESTA CASSA PICCOLA', navigate: true, path: '/main/approvals/petty-cash-request' }, { text: 'DETTAGLIO', navigate: false, path: '' }],
        navBarRendicionGastosCajaChica: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'RENDICONTAZIONE SPESE', navigate: true, path: '/main/approvals/surrender-petty-cash-expenses' }, { text: 'DETTAGLIO', navigate: false, path: '' }],
        navBarHojaPeticion: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'MODULO RICHIESTA', navigate: true, path: '/main/approvals/request-form' }, { text: 'DETTAGLIO', navigate: false, path: '' }],
        navBarWorkingConditions: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'CONDIZIONI LAVORATIVE', navigate: true, path: '/main/approvals/working-conditions' }, { text: 'DETTAGLIO', navigate: false, path: '' }],
        navBarAusencia: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'ASSENZA', navigate: true, path: '/main/approvals/ausencia' }, { text: 'DETTAGLIO', navigate: false, path: '' }],
        navBarDocucobra: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'DOCUCOBRA', navigate: true, path: '/main/approvals/docucobra' }, { text: 'DETTAGLIO', navigate: false, path: '' }],
        btnTextDevolver: 'Restituire',
        btnTextAprobar: 'Approva',
        itemDetail: {
            textCodigo: 'Codice',
            textTitle: 'DETTAGLIO',
            textPais: 'Paese',
            textGrupo: 'Gruppo',
            textEmpresa: 'Azienda',
            textProveedor: 'Fornitore',
            textOT: 'OT',
            textFechaRecepcion: 'Data Ricezione',
            textFormaPago: 'Metodo di Pagamento',
            textTipoPago: 'Tipo di Pagamento',
            textTerminoPago: 'Termini di Pagamento',
            textMoneda: 'Valuta',
            textImporte: 'Importo',
            textComentario: 'Commento',
            textPedidoCompra: 'Ordine di Acquisto',
            textImporteFacturaAsociado: 'Importo Fattura Associato',
            textSolicitante: 'Richiedente',
            textMultiProveedor: 'Multi Fornitore',
            textContratoMarco: 'Contratto Quadro',
            textFechaRegistro: 'Data Registrazione',
            textTrabajador: 'Lavoratore',
            textAreaSolicitante: 'Area',
            textPuestoSolicitante: 'Ruolo',
            textCargoSolicitante: 'Carica',
            textObservaciones: 'Osservazioni',
            textAprobadores: 'Approvatori',
            textCodigoSolicitud: 'Codice Richiesta',
            textEstado: 'Stato',
        },
        itemPersonal: {
            textTitle: 'PERSONALE',
            textModalidadTrabajo: 'Modalità di Lavoro',
            textMotivoSolicitud: 'Motivo della Richiesta',
            textTipoContrato: 'Tipo di Contratto',
            textPuesto: 'Ruolo',
            textTipoDocumento: 'Tipo di Documento',
            textIdentificacion: 'Identificazione',
            textNombreCompleto: 'Nome Completo',
            textFechaAlta: 'Data Inizio',
            textFechaBaja: 'Data Fine',
            textVehiculoPropio: 'Veicolo Proprio',
            textVehiculoEmpresa: 'Veicolo Aziendale',
            textHoraExtra: 'Ore Straordinarie',
            textPrimaProduccion: 'Premio Produzione',
            textBonoTurnoNoche: 'Bonus Turno Notte',
            textBonoTurnos: 'Bonus Turni',
            textRetribucionMensual: 'Retribuzione Mensile',
            textRetribucionAnual: 'Retribuzione Annuale',
            textFunciones: 'Funzioni'
        },
        itemEmpleado: {
            textTitle: 'DIPENDENTE',
            textTitleActuales: 'Dati Attuali',
            textTitlePropuestas: 'Dati Proposti',
            textEmpleado: 'Dipendente',
            textDelegacion: 'Delegazione',
            textOT: 'OT',
            textPuesto: 'Ruolo',
            textRemuneracionMensual: 'Retribuzione Mensile',
            textRemuneracionAnual: 'Retribuzione Annuale',
            textRemuneracionAnualEuros: 'Retribuzione Annuale Euro',
            textTipoCoteo: 'Tipo di Costeo',
            textFechaEfectiva: 'Data Effettiva',
            textVarianza: 'Varianza',
            textMotivoAumentoSalarial: 'Motivo Aumento Salariale',
            textJustificacionSolicitud: 'Giustificazione della richiesta'
        },
        itemDistribuccion: {
            textTitle: 'DISTRIBUZIONE',
            textTitleEmpty: 'NESSUNA DISTRIBUZIONE',
            textProveedor: 'Fornitore',
            textMoneda: 'Valuta',
            headerTable: [
                { label: 'Conto', key: 'cuenta' },
                { label: 'OT', key: 'ot' },
                { label: 'Importo', key: 'importe' },
            ],
            textImporteTotal: 'Importo Totale',
            textImporteDistribuido: 'Importo Distribuito',
            textImportePendiente: 'Importo Pendente',
        },
        itemProducto: {
            textTitle: 'PRODOTTO',
            headerTable: [
                { label: 'Descrizione', key: 'descripcion' },
                { label: 'Quantità', key: 'cantidad' },
                { label: 'Unità', key: 'unidad' },
                { label: 'Prezzo Unitario', key: 'precioUnitario' },
                { label: 'Sub Totale', key: 'subTotal' },
            ],
            headerTableWithoutPrice: [
                { label: 'Descrizione', key: 'descripcion' },
                { label: 'Quantità', key: 'cantidad' },
                { label: 'Unità', key: 'unidad' },
            ],
            headerTableRQSistema: [
                { label: 'Personale', key: 'personal' },
                { label: 'Descrizione', key: 'descripcion' },
                { label: 'Quantità', key: 'cantidad' },
                { label: 'Unità', key: 'unidad' },
            ],
            textMontoTotal: 'Importo Totale',
        },
        itemValorizaciones: {
            textTitle: 'VALUTAZIONE',
            headerTablePedidoCompra: [
                { label: 'Serie', key: 'serie' },
                { label: 'Numero Documento', key: 'numeroDocumento' },
                { label: 'Descrizione', key: 'descripcion' },
                { label: 'Quantità', key: 'cantidad' },
                { label: 'Prezzo Unitario', key: 'precioUnitario' },
                { label: 'Sub Totale', key: 'subTotal' },
            ],
            headerTableContrato: [
                { label: 'Numero Valutazione', key: 'numeroValorizacion' },
                { label: 'Contratto', key: 'contrato' },
                { label: 'Servizio', key: 'servicio' },
                { label: 'Importo', key: 'monto' },
            ],
            textMontoTotal: 'Importo Totale',
        },
        itemRendicion: {
            textTitle: 'SPESE',
            headerTable: [
                { label: 'Nome Servizio', key: 'nombreServicio' },
                { label: 'Importo', key: 'importe' },
            ],
            headerTableNuevo: [
                { label: '', key: 'checkbox' },
                { label: '', key: 'preview' },
                { label: 'Nome Servizio', key: 'DatosServicio.Servicio' },
                { label: 'Importo', key: 'DatosImporte.Importe', special: 'amountFormat' },
            ],
            textImporteTotal: 'Importo Totale',
        },
        itemNotas: {
            textTitle: 'NOTE',
            headerTable: [
                { label: 'Osservazione', key: 'observacion' },
                { label: 'Data', key: 'fecha' },
                { label: 'Utente', key: 'usuario' }
            ],
        },
        itemBitacora: {
            textTitle: 'LOG',
            headerTable: [
                { label: 'Azione', key: 'accion' },
                { label: 'Data', key: 'fecha' },
                { label: 'Descrizione', key: 'descripcion' },
                { label: 'Utente', key: 'usuario' },
            ],
        },
        itemDocumentos: {
            textTitle: 'DOCUMENTI',
        },
        errors: {
            comparativoErrorDetalle: 'Dettaglio comparativo non trovato',
            facturaErrorDetalle: 'Dettaglio fattura non trovato',
            requerimientoSistemaErrorDetalle: 'Dettaglio richiesta sistema non trovato',
            requerimientoCompraErrorDetalle: 'Dettaglio richiesta acquisto non trovato',
            solicitudCajaChicaErrorDetalle: 'Dettaglio richiesta cassa piccola non trovato',
            rendicionGastoCajaChicaErrorDetalle: 'Dettaglio rendicontazione spese cassa piccola non trovato',
            hojaPeticionErrorDetalle: 'Dettaglio modulo richiesta non trovato',
            condicionLaboralErrorDetalle: 'Dettaglio condizione lavorativa non trovato',
            docucobraErrorDetalle: 'Dettaglio docucobra non trovato',
            facturaNoAsociada: 'Non è possibile approvare la fattura senza un\'associazione',
            aproveBatch: 'Si è verificato un errore nell\'approvazione dei record, per favore riprova',
            returnBatch: 'Si è verificato un errore nel ritorno dei record, per favore riprova'
        },
        successBatch: 'Record eliminati',
    },
    moduloGenericList: {
        navBarComparativo: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'COMPARATIVO', navigate: false, path: '' }],
        navBarFactura: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'FATTURA', navigate: false, path: '' }],
        navBarRequerimientoCompra: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'RICHIESTA DI ACQUISTO', navigate: false, path: '' }],
        navBarRequerimientoSistema: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'RICHIESTA DI SISTEMA', navigate: false, path: '' }],
        navBarSolicitudCajaChica: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'RICHIESTA CASSA PICCOLA', navigate: false, path: '' }],
        navBarRendicionGastosCajaChica: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'RENDICONTAZIONE SPESE', navigate: false, path: '' }],
        navBarHojaPeticion: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'MODULO RICHIESTA', navigate: false, path: '' }],
        navBarWorkingConditions: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'CONDIZIONI LAVORATIVE', navigate: false, path: '' }],
        navBarGastoAprobacion: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'RENDICONTAZIONE SPESE', navigate: false, path: '' }],
        navBarTareo: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'TAREO', navigate: false, path: '' }],
        navBarAusencia: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'ASSENZA', navigate: false, path: '' }],
        navBarDocucobra: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'DOCUCOBRA', navigate: false, path: '' }],

        navBarHistoricoGastos: [{ text: 'RENDICONTAZIONI', navigate: true, path: '/main/caja-chica#aprobador' }, { text: 'STORICO SPESE', navigate: false, path: '' }],
        navBarRendicionGastos: [{ text: 'RENDICONTAZIONI', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'RENDICONTAZIONE SPESE', navigate: false, path: '' }],
        navBarIngresoGastos: [{ text: 'RENDICONTAZIONI', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'INGRESSO SPESE', navigate: false, path: '' }],
        navBarIngresoGastosAsociados: [{ text: 'RENDICONTAZIONI', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'INGRESSO SPESE ASSOCIATI', navigate: false, path: '' }],
        navBarSolicitudAnticipo: [{ text: 'RENDICONTAZIONI', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'RICHIESTA ANTICIPO', navigate: false, path: '' }],

        navBarTareoPersonal: [{ text: 'TAREO', navigate: true, path: '/main/tareo' }, { text: 'IL MIO TAREO', navigate: false, path: '' }],
        navBarTareoGrupal: [{ text: 'TAREO', navigate: true, path: '/main/tareo' }, { text: 'GRUPPALE', navigate: false, path: '' }],
        item: {
            textoListaVacia: 'Nessun record',
            itemTextProveedor: 'Fornitore',
            itemTextOT: 'OT',
            itemTextMoneda: 'Valuta',
            itemTextImporte: 'Importo',
            itemTextFechaRegistro: 'Data Registrazione',
            itemTextFaltaAsociacion: 'Manca associazione',
            itemTextSociedad: 'Società',
            itemTextTrabajador: 'Lavoratore',
            itemTextSolicitante: 'Richiedente',
            itemTextEmpresa: 'Azienda',
            itemTextPersonal: 'Personale',
            itemTextImporteAprobado: 'Importo Approvato',
            itemTextRendidor: 'Renditore',
            itemTextProceso: 'In processo',
            itemTextPeriodo: 'Periodo',
            itemTextPeriodoInicial: 'Periodo Iniziale',
            itemTextPeriodoFinal: 'Periodo Finale',
            itemTextHoras: 'Ore',
            itemTextCantidadLinea: 'Numero di linee',
        }
    },
    moduloCajaChica: {
        navBar: [{ text: 'RENDICONTAZIONI', navigate: false, path: '' }],
        textoImporteTotalSolicitud: 'Importo Totale Richiesto',
        textoSinGastosAsociados: 'Nessuna Spesa Associata',
    },
    moduloGasto: {
        navBarGastosPendientes: [{ text: 'RENDICONTAZIONI', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'INGRESSO SPESE', navigate: true, path: '/main/caja-chica/ingresso-gastos' }, { text: '', navigate: false, path: '' }],
        navBarGastosAsociados: [{ text: 'RENDICONTAZIONI', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'INGRESSO SPESE ASSOCIATI', navigate: true, path: '/main/caja-chica/ingresso-gastos-asociados' }, { text: '', navigate: false, path: '' }],
        detail: {
            textTitle: 'Informazioni Generali',
            textOT: 'OT',
            textProveedor: 'Fornitore',
            textOtroProveedor: 'Altro Fornitore',
            textDesactivarOtroProveedor: 'Disattiva Altro Fornitore',
            textTipoDocumentoProveedor: 'Tipo Documento Fornitore',
            textNumeroDocumentoProveedor: 'Numero Documento Fornitore',
            textRazonSocialProveedor: 'Ragione Sociale Fornitore',
            textFechaEmision: 'Data Emissione',
            textTipoDocumentoTributario: 'Tipo di documento fiscale',
            textNumeroDocumento: 'Numero del documento',
            textServicio: 'Servizio',
            textDivisa: 'Divisa',
            textImporte: 'Importo',
            textTipoImpuesto: 'Tipo Imposta',
            textoOtroImpuesto: 'Altra Imposta',
            textDesactivarOtroImpuesto: 'Disattiva Altra Imposta',
            textImporteImpuesto: 'Importo Imposta',
            textImporteTotal: 'Importo Totale',
            textObservaciones: 'Osservazioni',
            textDigitalizacionFactura: 'Digitalizzazione Fattura',
        },
        validate: {
            errorOT: 'Seleziona un OT',
            errorProveedor: 'Seleziona un Fornitore',
            errorTipoDocumentoProveedor: 'Inserisci un Tipo Documento Fornitore',
            errorNumeroDocumentoProveedor: 'Inserisci un Numero Documento Fornitore',
            errorRazonSocialProveedor: 'Inserisci Ragione Sociale Fornitore',
            errorFechaEmision: 'Inserisci una Data Emissione',
            errorTipoDocumento: 'Seleziona un Tipo Documento',
            errorNumeroDocumento: 'Inserisci Numero Documento',
            errorServicio: 'Seleziona un Servizio',
            errorDivisa: 'Seleziona una Divisa',
            errorImporte: 'Inserisci l\'Importo',
            errorTipoImpuesto: 'Seleziona un Tipo Imposta',
            errorObservacion: 'Inserisci un\'Osservazione',
            errorFiles: 'Inserisci Digitalizzazione Fattura',
            errorNumeroDocumentoDuplicado: 'Esiste già una spesa con lo stesso Numero Documento',
            errorSinFlujos: 'Questo OT/Delega non presenta Flussi di Approvazione'
        }
    },
    moduloAnticipo: {
        navBar: [{ text: 'RENDICONTAZIONI', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'RICHIESTA ANTICIPO', navigate: true, path: '/main/caja-chica/solicitud-anticipo' }, { text: '', navigate: false, path: '' }],
        detail: {
            textTitle: 'Informazioni Generali',
            textOT: 'OT',
            textProyectos: 'Restrizioni',
            textTarea: 'Compito',
            textPersonal: 'Personale',
            textDivisa: 'Divisa',
            textImporte: 'Importo',
            textBanco: 'Banca',
            textCuentaBancaria: 'Conto Bancario',
            textCuentaInterbancaria: 'Conto Interbancario',
            textNota: 'Descrizione',
            textoImporteMaximo: 'Importo Massimo',
            textHelp: 'Per far approvare la tua richiesta di cassa piccola, è essenziale allegare il documento debitamente firmato. Puoi trovare il modello qui.',
            textDigitalizacionSolicitud: 'Digitalizzazione Richiesta',
        },
        validate: {
            errorOT: 'Seleziona un OT',
            errorProyectos: 'Seleziona un Progetto',
            errorTarea: 'Seleziona un Compito',
            errorPersonal: 'Seleziona un Personale',
            errorDivisa: 'Seleziona una Divisa',
            errorImporte: 'Inserisci l\'Importo',
            errorImporteValido: 'Inserisci un Importo valido',
            errorBanco: 'Inserisci una Banca',
            errorCuentaBancaria: 'Inserisci Conto Bancario',
            errorCuentaInterbancaria: 'Inserisci Conto Interbancario',
            errorNota: 'Inserisci Descrizione'
        }
    },
    moduloRendicion: {
        navBar: [{ text: 'RENDICONTAZIONI', navigate: true, path: '/main/caja-chica' }, { text: 'RENDICONTAZIONE SPESE', navigate: true, path: '/main/caja-chica/rendicion-gastos' }, { text: '', navigate: false, path: '' }],
        textoEstadoCuenta: "Il Mio Estratto Conto",
        textoSolicitudCajaChica: "Richiesta Cassa Piccola",
        textoImportePendientePago: "Importo in Sospeso",
        textoTituloImporte: "Importo",
        textoCodigo: 'Codice',
        textoTituloPendienteRendir: "In Sospeso da Rendere",
        detail: {
            textTituloGeneral: 'Informazioni Generali',
            textOT: 'OT',
            textPersonal: 'Personale',
            textAnticipo: 'Richiesta Cassa Piccola',
            textBanco: 'Banca',
            textCuentaBancaria: 'Conto Bancario',
            textCuentaInterbancaria: 'Conto Interbancario',
            textFecha: 'Data',
            textDescripcion: 'Descrizione',

            textTituloGastos: 'Associa Spese',
            textProveedor: 'Fornitore',
            textImporte: 'Importo',
            btnAprobacion: 'Invia Approvazione',

            textTituloNuevoGasto: 'Nuova Spesa',
            textTituloFlujo: 'Flusso',
            textTituloAsociar: 'Associa Spesa',
            textGastoEncontrado: 'Spese trovate',
            textoGastoVacio: 'Nessun costo associato',
        },
        validate: {
            errorDetail: 'Dettaglio di questo record non trovato',
            errorOT: 'Seleziona un OT',
            errorPersonal: 'Seleziona un Personale',
            errorAnticipo: 'Seleziona una Richiesta Cassa Piccola',
            errorBanco: 'Inserisci una Banca',
            errorCuentaBancaria: 'Inserisci un Conto Bancario',
            errorFecha: 'Inserisci una Data',
            errorDescripcion: 'Inserisci una descrizione',
            errorGasto: 'Seleziona almeno una spesa',
            errorFlujo: 'Seleziona un Flusso'
        }
    },
    moduloGastoAprobacion: {
        navBar: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'RENDICONTAZIONE SPESE', navigate: true, path: '/main/approvals/gasto-aprobacion' }, { text: '', navigate: false, path: '' }],
        navBarHistorico: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'STORICO SPESE', navigate: true, path: '/main/approvals/historico-gastos' }, { text: '', navigate: false, path: '' }],
        validate: {
            errorDetail: 'Dettaglio del record non trovato',
            errorComentario: 'Inserisci un Commento',
            errorTitulo: 'Inserisci un titolo',
            errorDescripcion: 'Inserisci una descrizione',
        },
        question: {
            questApprove: `Stai per approvare queste spese. Per favore conferma questa azione e aggiungi un commento spiegando perché stai approvando questa spesa.`,
            questReject: `Stai per rifiutare queste spese. Per favore conferma questa azione e aggiungi un commento spiegando perché stai rifiutando questa spesa.`,
        },
        generic: {
            textStatusRevision: 'In Revisione',
            textStatusAprobado: 'Approvato',
            textStatusRechazado: 'Rifiutato',
            textProveedor: 'Fornitore',
            textOT: 'OT',
            textImporte: 'Importo',
            textImpTotal: 'Imp. Totale',
            textComentario: 'Commento',
            textConfirmacion: 'Conferma',
        },
        viewReport: {
            title: 'Rapporto Spesa',
            textTitleDetail: 'Dettaglio',
            textRendidor: 'Personale',
            textFechaEnvio: 'Data di invio',
            textImporteAsociado: 'Importo Associato',
            textCantidadLineas: 'Numero Linee',
            textGastos: 'Spese',
            textoRevisados: 'revisati',
            textFechaEmision: 'Data emissione',
            textServicio: 'Servizio',
            textoBuscar: 'Cerca',
        },
        viewDetail: {
            title: 'Spesa',
            textTabDetail: 'Dettaglio',
            textTabArchivos: 'File',
            textTitleGeneral: 'Generale',
            textTitleDetalleGasto: 'Dettaglio della Spesa',
            textDelegacion: 'Delegazione',
            textFecha: 'Data',
            textImporte: 'Importo',
            textImpuesto: 'Imposta',
            textTipoDoc: 'Tipo Doc',
            textServicio: 'Servizio',
            textObservaciones: 'Osservazioni',
            textEstado: 'Stato',
        },
        viewHistory: {
            title: 'Note',
            textTitleComentario: 'Nota',
            textTitulo: 'Titolo',
            textTitleNote: 'Note',
            textDescripcion: 'Descrizione',
            btnAgregarComentario: 'Aggiungi nota',
        }
    },
    moduloDocucobra: {
        navBar: [{ text: 'DOCUCOBRA', navigate: true, path: '/main/docucobra/list' }],
        textoVersion: 'Versione',
        textoResumen: 'Riepilogo',
        textoAbrirResumen: 'Apri Riepilogo',
        textoAbrirDocumento: 'Apri Documento',
        textoVerRelacionados: 'Visualizza Correlati',
        textoTituloFiltros: 'Filtri di ricerca',
        textoFiltroPais: 'Paese',
        textoBuscar: 'Cerca',
        textoVersiones: 'Versioni',
        textoDocumentos: 'Documenti',
        textoRelacionado: 'Imparentato',

        textoTituloArchivo: 'File',
        textoTituloArchivosRelacionados: 'File Correlati',

        textoInputBuscar: 'Cerca...',
    },
    moduloTareo: {
        navBar: [{ text: 'TAREO', navigate: false, path: '/main/tareo' }],
        navBarPersonal: [{ text: 'TAREO', navigate: true, path: '/main/tareo' }, { text: 'IL MIO TAREO', navigate: true, path: '/main/tareo/personal' }, { text: '', navigate: false, path: '' }],
        navBarAprobacion: [{ text: 'APPROVAZIONI', navigate: true, path: '/main/approvals' }, { text: 'TAREO', navigate: true, path: '/main/approvals/tareo' }, { text: 'Dettaglio', navigate: false, path: '' }],
        detail: {
            textDetalleAusencia: "DETTAGLIO DELL'ASSENZA",
            textPeriodo: 'Periodo',
            textPeriodoInicial: 'Periodo Iniziale',
            textPeriodoFinal: 'Periodo Finale',
            textEstado: 'Stato',
            textTotalHoras: 'Totale Ore',
            textHoras: 'Ore',
            textTitle: 'DETTAGLIO TAREO',
            textTitleGeneral: 'DETTAGLIO GENERALE',
            textTitleModalAutocompletar: 'Autocompletamento Periodo',
            textTituloDetalle: 'Dettaglio',
            textOT: 'OT',
            textDisciplina: 'Disciplina',
            textFechaInicio: 'Data Inizio',
            textFechaFinal: 'Data Fine',
            textExcluyeSabado: 'Esclude Sabato',
            textExcluyeDomingo: 'Esclude Domenica',
            textExcluyeFeriado: 'Esclude Festivi',
            table: [
                { key: 'DiaMes', label: 'Data' },
                { key: 'Nombre', label: 'Giorno' },
                { key: 'OT', label: 'OT' },
                { key: 'HorasTrabajador', label: 'Totale Ore' },
                { key: 'Preview', label: '' }
            ],
            tableAusencia: [
                { key: 'Preview', label: '' },
                { key: 'Estado', label: 'Stato' },
                { key: 'FechaInicio', label: 'Data Inizio' },
                { key: 'FechaFinal', label: 'Data Fine' },
            ]
        },
        ausencia: {
            textAgregar: 'Aggiungi Assenza',
            textVisualizar: 'Visualizza Assenza',
            textEditar: 'Modifica Assenza',
            textTipo: 'Tipo di Assenza',
            textObservacion: 'Osservazione',
            textAdjuntarArchivo: 'Allega File',
            textArchivoDocumento: 'File Documento',
            btnEnviarAprobacion: 'Invia per Approvazione'
        },        
        validate: {
            errorPeriodo: 'Periodo non configurato',
            errorDetail: 'Dettaglio non trovato per',
            errorOTBase: 'OT base non configurata',
            errorOT: 'OT base non trovata',
            errorOTRequired: 'Seleziona un OT',
            errorDisciplinaRequired: 'Seleziona una disciplina',
            errorHoraRequired: 'Inserisci un’Ora',
            errorDetalleCantidad: 'Devi aggiungere almeno un elemento',
            errorHoraMaximaDia: 'Le ore totali per giorno non devono superare',
            errorDuplicadoItem: 'Questo OT e Disciplina esistono già',
        },
        question: {
            questLimpiarPeriodo: 'Vuoi pulire tutte le ore del periodo?',
            questAutocomplete: 'Tutte le ore attuali verranno sostituite e autocomplete con quelle attuali. Vuoi continuare?',
        },
        days: {
            LU: "Lunedì",
            MA: "Martedì",
            MI: "Mercoledì",
            JU: "Giovedì",
            VI: "Venerdì",
            SA: "Sabato",
            DO: "Domenica",
        }
    },
    menu: {
        '010': { titulo: 'APPROVAZIONI' },
        '011': { titulo: 'Richiesta di Sistema' },
        '012': { titulo: 'Richiesta di Acquisto' },
        '013': { titulo: 'Comparativo' },
        '014': { titulo: 'Fattura' },
        '015': { titulo: 'Richiesta Cassa Piccola' },
        '016': { titulo: 'Rendicontazione Spese' },
        '017': { titulo: 'Modulo di Richiesta' },
        '018': { titulo: 'Condizioni di Lavoro' },
        '019': { titulo: 'Rendicontazione Spese' },

        '020': { titulo: 'DOCUCOBRA' },
        '030': { titulo: 'RENDICONTAZIONI' },
        '031': { titulo: 'Spese Approvazione' },
        '032': { titulo: 'Storico Spese' },
        '033': { titulo: 'Rendicontazioni' },
        '036': { titulo: 'Richiesta Cassa Piccola' },

        '040': { titulo: 'TAREO' },
        '041': { titulo: 'Il Mio Tareo' },
        '042': { titulo: 'Tareo di Gruppo' },
        '043': { titulo: 'Tareo' },
        '044': { titulo: 'Assenza' },
        '045': { titulo: 'Docucobra' },
    },
    languages: [
        { code: 'es', name: 'Spagnolo' },
        { code: 'en', name: 'Inglese' },
        { code: 'de', name: 'Tedesco' },
        { code: 'it', name: 'Italiano' },
    ]    
}
