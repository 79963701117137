// Domain
import { EntityProyectos } from "../../../../../shared/Domain/Catalogos/EntityProyectos";
import { EntityTipoDocumentoTributario } from "../../../../../shared/Domain/Catalogos/EntityTipoDocumentoTributario";
import { EntityAnticipo } from "../../../../../shared/Domain/EntityAnticipo";
import { EntityRequestAddNote, EntityRequestCancelFlow, EntityRequestFormService, EntityRequestFormServiceSendApproval, EntityRequestGenericForm } from "../Domain/EntityRequest";
import { EntityResponseAddNote, EntityResponseInitialData } from "../Domain/EntityResponse";
import { keyModule } from "../Domain/EntityUtils";
import { RepositoryMain } from "../Domain/RepositoryMain";

// Infraestructure
import { AdapterData } from "../../../../../shared/Infraestructure/AdapterData";
import { RepositoryImplGenerico } from "../../../../../shared/Infraestructure/RepositoryImplGenerico";
import { AdapterGenerico } from "../../../../../shared/Infraestructure/AdapterGenerico";
import { addCountProcess } from "../../../../../shared/Infraestructure/SliceGenerico";
import { EntityPersonal } from "../../../../../shared/Domain/Catalogos/EntityPersonal";

export class RepositoryImplMain extends RepositoryImplGenerico<any> implements RepositoryMain {

    public async save(params: EntityRequestFormService, type: 'create' | 'edit' | 'preview'  | ''): Promise<EntityAnticipo | null> {
        try {
            let response = await this._save(params, type);
            let rowLocal = await this._saveLocal(params, type);

            await this.dbLocal.deleteByIndexStore({ nameStore: 'SolicitudAnticipo', value: rowLocal.Codigo });
            await this.dbLocal.insertDataStore({ nameStore: 'SolicitudAnticipo', data: response ? response : rowLocal });

            return !!response ? response : rowLocal;
        } catch (error) {
            throw error;
        }
    }

    private async _saveLocal(params: EntityRequestFormService, type: 'create' | 'edit' | 'preview'  | ''): Promise<any> {
        const StatusOffline = { Estado: "No Enviado", IdEstado: -1 }
        const { Codigo, Users, ...rest } = params;

        let dataSend = type === 'create' ? [{...params, Users}] : {
            filter: {
                CodigoAnticipo: params.Codigo
            },
            update: rest,
            user: params.Users
        }
        let response = { ...params, modeSave: type, Status: StatusOffline, dataSend };
        if (!!params.Codigo) return response;

        await this.dbLocal.insertDataStore({ nameStore: 'SolicitudAnticipo', data: response });
        return response;
    }

    private async _save(params: EntityRequestFormService, modeSave: 'create' | 'edit' | 'preview'  | ''): Promise<EntityAnticipo | null> {
        const url = `${this.urlBaseGastos}${modeSave === 'create' ? '/Navision/Solicitudes/saveUpload' : '/Navision/Solicitudes/updateUpload'}`
        const formData = new FormData();
        if (!navigator.onLine) { this.dispatch(addCountProcess()); return null; }
        
        if (modeSave === 'create') {
            const { Codigo, Files, ...rest } = params;
            formData.append('data', JSON.stringify({...rest, Files: { SolicitudPDF: Files.map(row => row.value.name) }}))
            for (const item of Files) {
                formData.append('file', item.value, item.value.name);
            }
        } else {
            const { Codigo, Users, Files, ...rest } = params;
            let _params = {
                filter: {
                    Codigo: params.Codigo
                },
                update: {
                    ...rest,
                    Files: { SolicitudPDF: Files.map(row => row.type === 'local' ? row.value.name : row.value) }
                },
                user: params.Users
            };

            formData.append('data', JSON.stringify(_params));
            for (const item of Files) {
                if (item.type === 'local') formData.append('File', item.value, item.value.name);
            }
        }
        
        const response = await this.service.call<any>(modeSave === 'create' ? "POST" : 'PATCH', url, formData, "bearer", "form", 'json',  { "request-decrypt-response": true }, 0);
        return response;
        // if (!response.status) throw Error(response.message);
    }

    public async formatDataToSave(form: EntityRequestGenericForm): Promise<EntityRequestFormService> {
        const DateRegister = AdapterGenerico.getFechaLocal();
        const importe = parseFloat(parseFloat(`${form.Importe}`).toFixed(2));

        const dataOptional = form.Proyectos?.dataComplete ? {
            MovimientosPresupuesto: {
                Estado: true,
                Descripcion: "Activo",
                Proyecto: form.Proyectos.dataComplete.Code,
                Tarea: form.Tarea.dataComplete.Code,
                FechaRegistro: {
                    IdFecha: AdapterGenerico.getIdFechaLocal(DateRegister),
                    Fecha: DateRegister
                },
                Monto: importe
            }
        } : {}

        return ({
            ...dataOptional,
            Codigo: form.Codigo,
            DatosPersonal: {
                TipoDocumento: form.Personal.TipoDocumento,
                ApellidoMaterno: form.Personal.ApellidoMaterno,
                ApellidoPaterno: form.Personal.ApellidoPaterno,
                Identificacion: form.Personal.Identificacion,
                Nombres: form.Personal.Nombres
            },
            DatosEmpresa: {
                CDEmpresa: form.Empresa.CDEmpresa,
                CodEmpresa: form.Empresa.CDEmpresa,
                Empresa: form.Empresa.Empresa,
                Grupo: form.Empresa.Grupo
            },
            DatosImporte: {
                Inicial: {
                    Importe: 0,
                    ImportePendiente: 0
                },
                Solicitud: {
                    Importe: importe,
                    IGV: 0,
                    ImporteIGV: 0,
                    ImporteTotal: importe
                },
                Moneda: {
                    Codigo: '',
                    Descripcion: 'Moneda Local',
                    Moneda: 'Moneda Local',
                    Name: 'Moneda Local'
                    // Codigo: form.Divisa.dataComplete.Key,
                    // Descripcion: form.Divisa.dataComplete.Name,
                    // Moneda: form.Divisa.dataComplete.Name,
                    // Name: form.Divisa.dataComplete.Name
                }
            },
            DatosBanco: {
                Banco: form.Banco,
                NroCuenta: form.CuentaBancaria,
                CuentaInterbancaria: form.CuentaInterbancaria
            },
            DatosTrabajo: {
                Delegacion: {
                    Code: form.Delegacion.Code,
                    Codigo: form.Delegacion.Codigo,
                    Delegacion: form.Delegacion.Name,
                },
                OT: {
                    Codigo: form.OT.dataComplete.Codigo,
                    Code: form.OT.dataComplete.Code,
                    OT: form.OT.dataComplete.Name
                }
            },
            DatosPais: form.Pais,
            Users: form.User,
            Descripcion: form.Descripcion,
            Files: form.Files
        })
    }
    
    public async loadInitialData(): Promise<EntityResponseInitialData> {
        const { ot } = AdapterData;
        const [tipoDocumentoTributario, proyectos, flujoOT, flujoDelegacion] = await this.dbLocal.selectAllStore(['CTTipoDocumentoTributario', 'CTProyectos', 'CTFlujosOT', 'CTFlujosDelegacion']);

        return ({
            ot: ot.map(row => ({ label: `${row.Codigo} - ${row.Name.trim()}`, value: row.Id, dataComplete: row })),
            // moneda: moneda.map((row: EntityMoneda) => ({ label: row.Name.trim(), value: row.IdRegister, dataComplete: row })),
            tipoDocumentoTributario: tipoDocumentoTributario.map((row: EntityTipoDocumentoTributario) => ({ label: row.Descripcion, value: row.Codigo, dataComplete: row })),
            proyecto: proyectos.map((row: EntityProyectos) => ({ label: row.Name, value: row.IdRegister, dataComplete: row })),
            flujoOT,
            flujoDelegacion
        })
    }

    public async findForm(code: string, keyModule: keyModule, options: EntityResponseInitialData): Promise<{ data: EntityRequestGenericForm, dataComplete: EntityAnticipo } | null> {   
        const response: EntityAnticipo | null = await this.dbLocal.selectByIndexStore({ nameStore: keyModule, value: code });
        if (!response) return null;

        const resultOT = options.ot.find(row => row.dataComplete.Code === response.DatosTrabajo.OT.Codigo) || { label: response.DatosTrabajo.OT.OT, value: response.DatosTrabajo.OT.Codigo, dataComplete: response.DatosTrabajo.OT as any };
        // const resultDivisa = options.moneda.find(row => row.dataComplete.Key === response.DatosImporte.Moneda.Codigo);
        const resultProyecto = options.proyecto.find(row => row.dataComplete.Code === response.MovimientosPresupuesto?.Proyecto);
        const findDatosTarea = resultProyecto ? resultProyecto.dataComplete.DatosTareas.find(row => row.Code === response.MovimientosPresupuesto.Tarea) : undefined;
        const resultDatosTarea = findDatosTarea ? { label: findDatosTarea.Name, value: findDatosTarea.Code, dataComplete: findDatosTarea } : undefined;

        let resultFile: any[] = [];
        if (Array.isArray(response.Files?.SolicitudPDF) && response.Files.SolicitudPDF[0]) {
            let responseFile = await this.downloadFile(response.Files.SolicitudPDF[0])
            if (responseFile)
                resultFile = [{
                    type: 'server',
                    value: response.Files.SolicitudPDF[0],
                    valueToShow: (window.URL ? URL : webkitURL).createObjectURL(responseFile)
                }]
                else
                resultFile = [{
                    type: 'server',
                    value: response.Files.SolicitudPDF[0],
                    valueToShow: ''
                }]
        }

        return ({
            data: {
                Codigo: response.Codigo,
                OT: resultOT,
                Delegacion: {
                    Code: response.DatosTrabajo.Delegacion.Codigo,
                    Codigo: response.DatosTrabajo.Delegacion.Codigo,
                    Name: response.DatosTrabajo.Delegacion.Delegacion
                },
                Empresa: {
                    CDEmpresa: response.DatosEmpresa.CDEmpresa,
                    Empresa: response.DatosEmpresa.Empresa,
                    Grupo: response.DatosEmpresa.Grupo
                },
                Pais: {
                    CDPais: response.DatosPais.CDPais,
                    Code: response.DatosPais.Code || response.DatosPais.Codigo,
                    Name: response.DatosPais.Name
                },
                Proyectos: resultProyecto as any,
                Tarea: resultDatosTarea as any,
                Personal: response.DatosPersonal,
                Importe: response.DatosImporte.Solicitud.ImporteTotal,
                Banco: response.DatosBanco.Banco,
                CuentaBancaria: response.DatosBanco.NroCuenta,
                CuentaInterbancaria: response.DatosBanco.CuentaInterbancaria,
                Nota: '',
                User: {
                    IdUser: 0,
                    LastName: '',
                    Name: '',
                    Perfil: '',
                    User: ''
                },
                Estado: {
                    IdStatus: response.Status.Solicitud.IdStatus, 
                    Status: response.Status.Solicitud.Status
                },
                Aprobacion: {
                    Nivel: response.Aprobacion?.Nivel || 0
                },
                Flujo: {
                    Aprobacion: response.Flujo?.Aprobacion || [],
                    Code: response.Flujo?.Code || "",
                    Codigo: response.Flujo?.Codigo || "",
                    Descripcion: response.Flujo?.Descripcion || "",
                    Id: response.Flujo?.Id || 0,
                    Name: response.Flujo?.Name || ""
                },
                EstadoNav: {
                    IdStatus: response.Status.Nav?.IdStatus || 0, 
                    Status: response.Status.Nav?.Status || ''
                },
                Notas: (response?.Notas || []).map(row => ({
                    observacion: row.Description,
                    fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3),
                    usuario: `${row.User.Name} ${row.User.LastName}`,
                    action: row.Action
                })).reverse(),
                Bitacora: (response?.Bitacora || []).map(row => ({
                    accion: row.Action,
                    fecha: AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.Date.Date), 3),
                    descripcion: row.Description,
                    usuario: `${row.User.Name} ${row.User.LastName}`
                })).reverse(),
                Files: resultFile,
                Descripcion: response.Descripcion
            },
            dataComplete: response
        });
    }

    public async sendApproval(params: EntityRequestFormServiceSendApproval): Promise<boolean> {
        const url = `${this.urlBase}/navision/enviarWorfFlowAnticipoCajaChica`;
        const response = (await this.service.call<any>("POST", url, JSON.stringify(params.paramsSend), "bearer", "json", 'json', { "request-decrypt-response": true }, 0));
        if (!response) return false;
        
        // Eliminar registro
        await this.dbLocal.deleteByIndexStore({ nameStore: 'SolicitudAnticipo', value: params.extraData.Codigo });

        return true;
    }

    public async addNote(payload: EntityRequestAddNote): Promise<EntityResponseAddNote | null> {
        let url: string = `${this.urlBase}/navision/agregarNotaSolicitudCajaChicaWorkFlow`;
        return (await this.service.call<any>("POST", url, JSON.stringify(payload.paramsSend), 'bearer', "json", 'json', {}, 0)) || null;
    }

    public async findPersonal(identifier: string): Promise<EntityPersonal | null> {
        const result = await this.dbLocal.selectByIndexStore({ nameStore: 'CTPersonal', value: identifier })
        return result;
    }
    
    public async cancelarFlow(payload: EntityRequestCancelFlow): Promise<void> {
        const url = `${this.urlBaseGastos}/Navision/Solicitudes/cancelFlow`;
        const response = await this.service.call<any>("POST", url, JSON.stringify(payload), "bearer", "json", 'json',  { "request-decrypt-response": true }, 0);
        return response;
    }
}