import { InputSelect } from "../../../../shared/Components/ElementInputsCostume";
import ElementModalCostume from "../../../../shared/Components/ElementModalCostume";
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";
import { EntityModalFiltro } from "../../Domain/EntityUtils";

interface ContainerProps {
    ModalFiltroConfig: EntityModalFiltro;
    closeModal(): void;
    onChangeModalFiltro(name: string, value: any): void;
    onSubmitModalFiltro(): Promise<void>;
}

export const ViewModalFiltro = (props: ContainerProps) => {
    const language = LanguageTranslate();
    const OptionsSelect = [
        { label: language.textoEnAprobacion, value: 'pending' },
        { label: language.textoAproados, value: 'history' },
    ]
    if (!props.ModalFiltroConfig.show) return null;

    return (
        <ElementModalCostume
            show={props.ModalFiltroConfig.show}
            title={'Filtros'}
            closeModal={props.closeModal}
            height="100px"
        >
            <div>
                <InputSelect
                    label={language.textoEstado}
                    name="statusSelected"
                    onChange={props.onChangeModalFiltro}
                    values={props.ModalFiltroConfig}
                    options={OptionsSelect}
                />
                
                <button className="btn btn-primary w-100" onClick={() => props.onSubmitModalFiltro()}> {language.textoBuscar} </button>
            </div>
        </ElementModalCostume>
    )
}