// Domain
import { EntityMain } from "../Domain/EntityMain";
import { RepositoryMain } from "../Domain/RepositoryMain";

export class UseCaseFormatDocuments {
    private repository: RepositoryMain;

    constructor(_repository: RepositoryMain) {
        this.repository = _repository;
    }

    public async exec(params: EntityMain): Promise<any[]> {
        return await this._exec(params);
    }

    private async _exec(params: EntityMain): Promise<any[]> {
        return await this.repository.formatDocuments(params)
    }
}