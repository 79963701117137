import React from 'react';
import { AdapterGenerico } from '../../../../shared/Infraestructure/AdapterGenerico';
import { EntityMain } from '../../Domain/EntityMain';
import { EntityTareoPersonal } from '../../../../shared/Domain/Tareo/EntityTareoPersonal';
import { LanguageTranslate } from '../../../../shared/Infraestructure/LanguageTranslate';
import { AdapterConfigure } from '../../Infraestructure/AdapterConfigure';

interface ContainerProps {
    data: EntityMain<EntityTareoPersonal>[];
    onRedirectForm(id: string): any;
    show: boolean;
    copyCodeItem: (code: string) => Promise<void>;
}

const languageTranslate = LanguageTranslate();

const codeStatus: any = {
    "-1": 'Pendiente',
    1: languageTranslate.textoEstadoAprobado,
    2: languageTranslate.textoEstadoAbierto,
    3: languageTranslate.textoEstadoEnAprobacion,
    5: languageTranslate.textoEstadoEnAprobacion,
}

export const ItemTareoPersonal = (props: ContainerProps) => {
    if (!props.show) return null;

    return (
        <div className="row pt-3 mb-5">
            <div className="col-12 mb-5">
                {
                    props.data.map(row => {
                        const isItemReject = AdapterConfigure.ACTION_ITEM_REJECT.includes(row.dataComplete.Bitacora[row.dataComplete.Bitacora.length - 1]?.Action);
                        
                        return (
                            <div className="row pb-3 ItemGenericList" key={row.Codigo}>
                                <div className="col-12">
                                    <div className={`shadow-lg position-relative ${(row.isPendingSend || isItemReject) ? 'pending' : ''}`} style={{ width: '100%', padding: 5, margin: 0, borderRadius: '10px', cursor: 'pointer' }}>
                                        <div className="row pt-1" style={{ margin: '1px' }} onClick={() => props.onRedirectForm(row.Codigo)} >
                                            <div className="col-10" style={{ fontSize: 12 }}>
                                                <div className='d-flex'>
                                                    <span>{languageTranslate.moduloGenericList.item.itemTextPeriodo}:</span>
                                                    <span className='text-muted'> {row.dataComplete.Periodo.Criterio} </span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>{languageTranslate.moduloGenericList.item.itemTextPeriodoInicial}:</span>
                                                    <span className='text-muted'> {AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.dataComplete.Periodo.FechaInicio), 3)} </span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>{languageTranslate.moduloGenericList.item.itemTextPeriodoFinal}:</span>
                                                    <span className='text-muted'> {AdapterGenerico.convertDateToString(AdapterGenerico.convertStringToDate(row.dataComplete.Periodo.FechaFinal), 3)} </span>
                                                </div>
                                                <div className='d-flex'>
                                                    <span>{languageTranslate.moduloGenericList.item.itemTextHoras}:</span>
                                                    <span className='text-muted'> {row.dataComplete.CantidadHoras} </span>
                                                </div>
                                            </div>
                                            <div className="icon-arrow-item">
                                                <i className="fa-solid fa-angle-right" style={{ fontSize: '20px' }} />
                                            </div>

                                        </div>
                                        <div className="row pt-1" style={{ margin: '1px' }} onClick={() => props.onRedirectForm(row.Codigo)} >
                                            <div className="col-6">
                                                <span className='text-danger' style={{ fontSize: '13px' }}> {AdapterGenerico.calculoTiempo(row.FechaRegistro)} </span>
                                            </div>
                                            <div className="col-6 GroupBadge">
                                                <div className="badgeCustom">
                                                    {
                                                        row.isPendingSend ? 
                                                            <span>Pendiente</span> :
                                                            <span>{ isItemReject ? 'Devuelto' : codeStatus[row.dataComplete.Status.IdStatus] }</span>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    })
                }
            </div>
        </div>
    )
}