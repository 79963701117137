export interface EntityUserAccess {
  _id: string;
  IdUser: number
  Code: number
  icon: string
  Description: string
  Access: {
    Perfil: {
      IdPerfil: number
      Perfil: string
    }
    Global: Array<EntityGlobal>;
    Globals: Array<EntityGlobal>;
  }
  Padre: Array<{
    IdPadre: number
    Padre: string
  }>
  Mostrar: string
}

interface EntityGlobal {
  Code: string
  Child?: Array<EntityGlobal>
  Status: number
  Check: boolean
}

export interface EntityUserAccessData {
  Code: string
  Codigo: string
  Name: string
  Mostrar: string
  Child: Array<EntityUserAccessData>
  Status: number
  Check: boolean
  DataAdicional: {
    Open: boolean
    type: string
  }
}

export interface EntityUserAccessDataFormatted {
  OT: string[];
  Delegacion: string[];
  Pais: string[];
  Grupo: string[];
}

export const deleteDuplicatePermiss = (data: EntityUserAccessData[]): EntityUserAccessData[] => {
  const uniqueObjects = new Set(data.map((row) => JSON.stringify(row)));
  return Array.from(uniqueObjects).map((row) => JSON.parse(row));
}

// export const GetDataForUser = (data: EntityUserAccessData[]): EntityUserAccessDataFormatted => {
//   return data.reduce((prev, current, index) => {
//     let key = current.DataAdicional.type.toLocaleLowerCase();
//     switch (key) {
//       case 'pais': prev.Pais.push(current.Codigo); break;
//       case 'delegacion': prev.Delegacion.push(current.Code); break;
//       case 'ot': prev.OT.push(current.Code); break;
//       default: prev.Grupo.push(current.Codigo);
//     }
//     if (current.Child && current.Child.length > 0) {
//       const result = GetDataForUser(current.Child);
//       prev.OT = prev.OT.concat(result.OT).filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);
//       prev.Delegacion = prev.Delegacion.concat(result.Delegacion).filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);
//       prev.Pais = prev.Pais.concat(result.Pais).filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);
//       prev.Grupo = prev.Grupo.concat(result.Grupo).filter((valor, indice, arreglo) => arreglo.indexOf(valor) === indice);
//     }
//     return prev;
//   }, { OT: [], Delegacion: [], Pais: [], Grupo: [] } as EntityUserAccessDataFormatted)
// }

export const GetDataForUser = (data: EntityUserAccessData[]): EntityUserAccessDataFormatted => {
  const processChildData = (childData: EntityUserAccessData[]) => {
    const childResult = childData.reduce((acc, item) => {
      const key = item.DataAdicional.type.toLowerCase();

      switch (key) {
        case 'pais':
          acc.Pais.add(item.Codigo);
          break;
        case 'delegacion':
          acc.Delegacion.add(item.Code);
          break;
        case 'ot':
          acc.OT.add(item.Code);
          break;
        default:
          acc.Grupo.add(item.Codigo);
      }

      if (item.Child && item.Child.length > 0) {
        const childProcessed = processChildData(item.Child);
        childProcessed.OT.forEach((value) => acc.OT.add(value));
        childProcessed.Delegacion.forEach((value) => acc.Delegacion.add(value));
        childProcessed.Pais.forEach((value) => acc.Pais.add(value));
        childProcessed.Grupo.forEach((value) => acc.Grupo.add(value));
      }

      return acc;
    }, {
      OT: new Set<string>(),
      Delegacion: new Set<string>(),
      Pais: new Set<string>(),
      Grupo: new Set<string>(),
    });

    return {
      OT: Array.from(childResult.OT),
      Delegacion: Array.from(childResult.Delegacion),
      Pais: Array.from(childResult.Pais),
      Grupo: Array.from(childResult.Grupo),
    };
  };

  const processedData = processChildData(data);

  return {
    OT: processedData.OT,
    Delegacion: processedData.Delegacion,
    Pais: processedData.Pais,
    Grupo: processedData.Grupo,
  };
};

export const GetDataForUserDocucobra = (data: EntityUserAccessData[]): EntityUserAccessDataFormatted => {
  const processChildData = (childData: EntityUserAccessData[]) => {
    const childResult = childData.reduce((acc, item) => {
      const key = (item.DataAdicional.type || '').toLowerCase();

      switch (key) {
        case 'pais':
          acc.Pais.add(item.Codigo);
          break;
          case 'delegacion':
          acc.Delegacion.add(item.Codigo);
          break;
        default:
          acc.Grupo.add(item.Codigo);
      }

      if (item.Child && item.Child.length > 0 && key !== 'delegacion') {
        const childProcessed = processChildData(item.Child);
        childProcessed.Pais.forEach((value) => acc.Pais.add(value));
        childProcessed.Grupo.forEach((value) => acc.Grupo.add(value));
        childProcessed.Delegacion.forEach((value) => acc.Delegacion.add(value));
      }

      return acc;
    }, {
      Pais: new Set<string>(),
      Grupo: new Set<string>(),
      Delegacion: new Set<string>(),
    });

    return {
      Pais: Array.from(childResult.Pais),
      Grupo: Array.from(childResult.Grupo),
      Delegacion: Array.from(childResult.Delegacion)
    };
  };

  const processedData = processChildData(data);

  return {
    OT: [],
    Delegacion: processedData.Delegacion,
    Pais: processedData.Pais,
    Grupo: processedData.Grupo,
  };
};