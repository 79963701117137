import { ElementBreadCrumb } from "../../../shared/Components/ElementBreadCrumb";
import { ElementEmptyList } from "../../../shared/Components/ElementEmptyList";
import { InputTextIcon } from "../../../shared/Components/ElementInputsCostume";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";
import { EntityMain } from "../Domain/EntityMain";
import { EntityUtilsInformation } from "../Domain/Utils";
import './View.scss';

export interface PropsViewMain {
    reloadData: () => Promise<void>;
    data: EntityUtilsInformation;
    onClickItem(param: EntityMain): Promise<void>;
    onPreviousFile(): void;
    onChangeSearch(text: string): void;
    onSubmitSearch(e?: any): void;
}

export const ViewMain = (props: PropsViewMain) => {
    const languageTranslate = LanguageTranslate();

    return (
        <div className="ViewGenericList">
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb
                    list={[...languageTranslate.moduloDocucobra.navBar]}
                />

                <div className="w-100 mb-4">
                    <form onSubmit={props.onSubmitSearch}>
                        <InputTextIcon
                            label=""
                            placeholder={languageTranslate.moduloDocucobra.textoBuscar}
                            name="search"
                            onChange={(_, value) => props.onChangeSearch(value)}
                            values={{ search: props.data.filter.value }}
                            icon="fa-solid fa-magnifying-glass"
                            onSubmit={props.onSubmitSearch}
                        />
                    </form>
                </div>

                <div>
                    {
                        props.data.listPrevCodigo.length > 0 ?
                            <span onClick={props.onPreviousFile}>
                                <i className="fa-solid fa-left-long px-2 mb-4" style={{ fontSize: 18 }}></i>
                                {languageTranslate.btnRegresar}
                            </span>
                            :
                            null
                    }

                </div>

                <div className="d-flex gap-4 px-2" style={{ flexFlow: 'wrap' }}>

                    { props.data.dataFiltered.length === 0 && (<ElementEmptyList className="data-empty" text={languageTranslate.moduloGenericList.item.textoListaVacia} />) }
                    {
                        props.data.dataFiltered.map(row =>
                            <div
                                className="card text-center position-relative"
                                style={{ height: "100px", width: 100, cursor: "pointer", marginBottom: "20px", }}
                                onClick={() => { props.onClickItem(row); }}
                                key={row.codigo}
                            >
                                { row.countFiles ? <div className="position-absolute" style={{ top: -10, right: -10, height: 20, width: 20, background: '#fe8b8b', borderRadius: '50%', color: 'white' }}>{ row.countFiles }</div> : null }
                                <div className="card-body" style={{ padding: "5px" }}>
                                    <div className="card-text">
                                        {
                                            row.type === 'folder' ?
                                                <i className="fa-solid fa-folder-open" style={{ fontSize: "40px", paddingBottom: "10px", paddingTop: "10px", color: "wheat", }} />
                                                :
                                                <i className="fa-solid fa-file" style={{ fontSize: "40px", paddingBottom: "10px", paddingTop: "10px", color: "#1b458f" }} />
                                        }
                                        <div className="row">
                                            <div className="col-12 text-truncate" style={{ fontSize: "10px" }}>
                                                { row.name }
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                </div>

                <div
                    className="position-fixed reload bg-primary"
                    onClick={() => props.reloadData()}
                    >
                    <i
                        className="fa-sharp fa-solid fa-arrow-rotate-right"
                        style={{ fontSize: 20 }}
                    />
                </div>
            </div>
        </div>
    )
}