    import React from 'react';
    import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
    import { EntityLanguage } from '../../../shared/Domain/EntityLanguage';
    import { EntityMenu } from '../../../shared/Domain/EntityMenu';
    import { ElementChartCostume } from '../../../shared/Components/ElementChartCostume';
    import './Style.scss';
    import { ItemOptionMenu } from './Items/ItemOptionMenu';
    import { ItemWrapper } from './Items/ItemWrapper';
    import { EntityStatsMyAccount } from '../Domain/EntityUtils';
    import { AdapterGenerico } from '../../../shared/Infraestructure/AdapterGenerico';

    interface ContainerProps {
        listMenu: EntityMenu[];
        onClickMenu(row: EntityMenu): void;
        languageTranslate: EntityLanguage;
        statsAccount: EntityStatsMyAccount;
        reloadStatsAccount: () => Promise<void>;
    }

    const listSiglaRendidor = ["GIAAPP_RENDICIONGASTO", "GIAAPP_INGRESOGASTO", "GIAAPP_INGRESOGASTOASOCIADO", "GIAAPP_ANTICIPO"];
    // const listSiglaAprobador = ["GIAAPP_GASTOAPROBACCION", "GIAAPP_HISTORICOGASTOS"];

    export const ViewMain = (props: ContainerProps) => {

        return (
            <div className="ViewMainApprovals">
                <div className="container-fluid">
                    <ElementBreadCrumb list={props.languageTranslate.moduloCajaChica.navBar} />
                    <div className="container">
                            <div className='ContainerMyAccount'>
                                { props.statsAccount.loading ? <div className='LoadingMyAccount'> <span>{props.languageTranslate.textoCargando}</span> </div> : null }
                                <label>{props.languageTranslate.moduloRendicion.textoEstadoCuenta}</label>
                                {
                                    !props.statsAccount.show ?
                                        <div className='MessageErrorMyAccount' onClick={props.reloadStatsAccount}>
                                            <span>No se pudo cargar su estado de cuenta</span>
                                            <i className="fa-solid fa-rotate-right" />
                                        </div>
                                    :
                                        null
                                }
                                <ElementChartCostume
                                    data={[
                                        {
                                            color: props.statsAccount.Monto.Total === 0 ? '#dfdfdf' : '#1B458F',
                                            label: '',
                                            percentage: props.statsAccount.Monto.Pendiente
                                        },
                                        {
                                            color: '#dfdfdf',
                                            label: '',
                                            percentage: props.statsAccount.Monto.PendientePago
                                        }
                                    ]}
                                    total={props.statsAccount.Monto.Total}
                                >
                                    <div className='StatsChart'>
                                        <span className='text-dark fw-bold text-center mb-1' style={{ fontSize: 12, lineHeight: 'normal', marginTop: -20 }}>{props.languageTranslate.moduloRendicion.textoTituloImporte} <br/> {props.languageTranslate.moduloRendicion.textoTituloPendienteRendir}</span>
                                        <span className='text-primary fw-bold' style={{ fontSize: 20 }}>{AdapterGenerico.formatoDinero(props.statsAccount.Monto.Total)}</span>
                                        <span className='text-muted mb-2' style={{ fontSize: 12 }}>{AdapterGenerico.convertDateToString(new Date(), 8)}</span>
                                    </div>
                                </ElementChartCostume>

                                <ItemWrapper amount={props.statsAccount.Monto.Pendiente} color='#1B458F' data={props.statsAccount.Anticipos} title={props.languageTranslate.moduloRendicion.textoSolicitudCajaChica}/>
                                <ItemWrapper amount={props.statsAccount.Monto.PendientePago} color='#F13939' data={props.statsAccount.AnticiposPendientePago} title={props.languageTranslate.moduloRendicion.textoImportePendientePago}/>

                                {/* <ItemWrapper amount={props.statsAccount.Monto.Total - props.statsAccount.Monto.Pendiente} color='#1B458F' data={props.statsAccount.Rendiciones} title='Solicitud Caja Chica '/>
                                <ItemWrapper amount={props.statsAccount.Monto.Pendiente} color='#F13939' data={props.statsAccount.Anticipos} title='Importe Pendiente por Rendir'/> */}

                            </div>
                        {
                            props.listMenu.filter(row => listSiglaRendidor.includes(row.sigla))
                            .map(row =>
                                <ItemOptionMenu
                                    row={row}
                                    onClickMenu={props.onClickMenu}
                                    languageTranslate={props.languageTranslate}
                                />
                            )
                        }
                    </div>
                </div>
            </div>
        )
    }