import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";

export interface EntityModalFiltro {
    show: boolean;
    statusSelected: { label: string; value: string; };
}

export const initEntityModalFiltro: EntityModalFiltro = {
    show: false,
    statusSelected: { label: LanguageTranslate().textoEnAprobacion, value: 'pending' }
}