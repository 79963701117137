import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import './Style.scss';

import foto from '../../../../assets/img/user.png';
import { EntityDataUsuario } from '../../../shared/Domain/EntityDataUsuario';
import esFlag from '../../../../assets/img/es.png';
import usFlag from '../../../../assets/img/us.png';
import deFlag from '../../../../assets/img/de.png';
import itFlag from '../../../../assets/img/it.png';
import { Collapse } from 'react-bootstrap';

interface PropsViewMain {
    auth: boolean;
    user: EntityDataUsuario;

    showLanguage: boolean;
    setShowLanguage: Function;
    language?: string;
    onChangeLanguage?: Function;

    showChangePassword: boolean;
    setShowChangePassword: Function;
    formChangePassword: { values: any; touched: any; errors: any; handleBlur: Function; };
    onChangePassword: Function;
    onSubmitChangePassword: Function;
};

// const animatedComponents = makeAnimated();
export const ViewMain = (props: PropsViewMain) => {
    const languageTranslate = LanguageTranslate();

    const flags = {
        es: esFlag,
        en: usFlag,
        de: deFlag,
        it: itFlag
    };

    return (
        <div className='ViewProfile'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={[{ text: languageTranslate.textoConfiguracion, navigate: false, path: '' }]} />
                <div className="row">
                    <div className="col-4 text-center">
                        <img src={foto} className="img-thumbnail" alt="Profile" style={{ borderRadius: '50%' }} width={90} />
                    </div>
                    <div className="col-8">
                        <p style={{ marginBottom: '0.2rem' }}>
                            <span style={{ fontWeight: 'bold', fontSize: '0.9rem' }}>{`${props.user.Name} ${props.user.LastNameFather} ${props.user.LastNameMother}`.trim()}</span>
                        </p>
                        {/* <p style={{ marginBottom: '0.2rem' }}>
                            <span style={{ fontSize: '11px' }}>{`${props.user.Empresa?.Nombre || props.user.Empresa['Empresa' as keyof typeof props.user.Empresa] || ''} - ${props.user.PaisBase.Pais}`}</span>
                        </p> */}
                        <p style={{ marginBottom: '0.2rem' }}>
                            <span style={{ fontSize: '0.65rem' }}>{`${props.user.DatosTrabajo.Area} - ${props.user.DatosTrabajo.Cargo}`}</span>
                        </p>
                    </div>
                </div>
                <div hidden={props.user.IdUser === 0}>
                    <div className="row">

                        <div className="col-12 pt-3">
                            <div className="card">
                                <div className="card-header fondoSeccion" onClick={() => { props.setShowLanguage(!props.showLanguage) }}>
                                    <h6>{languageTranslate.moduloConfiguracion.textoIdioma}</h6>
                                    <i className={`fa-solid fa-play ${props.showLanguage ? 'iconShow' : 'iconNotShow'}`} />
                                </div>
                                <Collapse in={props.showLanguage}>
                                    <div className="card-body">
                                        <div className="row pt-2">
                                            {
                                                languageTranslate.languages.map(row => (
                                                    <div className="col-4" key={row.code}>
                                                        <input
                                                            type="radio"
                                                            className="btn-check"
                                                            name="options-outlined"
                                                            id={`inputIdioma${row.code}`}
                                                            value={row.code}
                                                            onChange={(evt) => evt.target.checked ? !!props.onChangeLanguage ? props.onChangeLanguage(row.code) : undefined : undefined}
                                                            checked={props.language === row.code ? true : false}
                                                        />
                                                        <label className="btn btn-sm btn-outline-primary" htmlFor={`inputIdioma${row.code}`}>
                                                            <img src={flags[row.code as keyof typeof flags]} alt="flag" /> {row.name}
                                                        </label>
                                                    </div>
                                                ))
                                            }
                                        </div>
                                    </div>
                                </Collapse>
                            </div>
                        </div>

                        <div className="col-12 pt-3">
                            <div className="card">
                                <div className="card-header fondoSeccion" onClick={() => { props.setShowChangePassword(!props.showChangePassword) }}>
                                    <h6>{languageTranslate.moduloConfiguracion.textoCambioPassword}</h6>
                                    <i className={`fa-solid fa-play ${props.showChangePassword ? 'iconShow' : 'iconNotShow'}`} />
                                </div>
                                <Collapse in={props.showChangePassword}>
                                    <div className="card-body">
                                        <form className="row" noValidate onSubmit={(e) => props.onSubmitChangePassword(e)}>

                                            <div className="col-12 pt-2 mb-2">
                                                <p className='mb-1' style={{ fontSize: 14 }}> {languageTranslate.moduloConfiguracion.textoNuevaPassword} <span className='text-danger'>*</span></p>
                                                <div className="input-group input-group-sm">
                                                    <span className="input-group-text" >
                                                        <i className="fa-solid fa-key" />
                                                    </span>
                                                    <input
                                                        type="password"
                                                        name="newPassword"
                                                        className="form-control"
                                                        required
                                                        placeholder={languageTranslate.moduloConfiguracion.textoNuevaPassword}
                                                        value={props.formChangePassword.values['newPassword']}
                                                        onChange={(evt) => { props.onChangePassword('newPassword', evt.target.value) }}
                                                    />
                                                </div>
                                            </div>

                                            <div className="col-12 pt-2 mb-2">
                                                <p className='mb-1' style={{ fontSize: 14 }}> {languageTranslate.moduloConfiguracion.textoRepetirNuevaPassword} <span className='text-danger'>*</span></p>
                                                <div className="input-group input-group-sm">
                                                    <span className="input-group-text" >
                                                        <i className="fa-solid fa-key" />
                                                    </span>
                                                    <input
                                                        type="password"
                                                        name="reNewPassword"
                                                        className="form-control"
                                                        required
                                                        placeholder={languageTranslate.moduloConfiguracion.textoRepetirNuevaPassword}
                                                        value={props.formChangePassword.values['reNewPassword']}
                                                        onChange={(evt) => { props.onChangePassword('reNewPassword', evt.target.value) }}
                                                    />
                                                </div>
                                            </div>
                                            <div className="col-12 pt-2">
                                                <button type="submit" className="btn btn-primary" style={{ width: '100%', backgroundColor: '#01397D' }}>
                                                    {languageTranslate.moduloConfiguracion.textoBotonSaveCambioPassword}
                                                </button>
                                            </div>
                                        </form>
                                    </div>
                                </Collapse>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div >
    )
};