import { EntityLanguage } from "../context/shared/Domain/EntityLanguage";

/* NOTA: modificar solo el valor, no la llave */
export const idioma_es: EntityLanguage = {
    code: 'es',
    nombreProyecto: 'Gestor Integral de Administración',
    textoLogin: 'Iniciar Sesión',
    textoConfiguracion: 'Configuración',
    textoCerrarSesion: 'Cerrar Sesión',
    textoModoCrear: 'CREAR',
    textoModoEditar: 'EDITAR',
    textoModoVisualizar: 'VISUALIZAR',
    textoNavbarInicio: 'Inicio',
    textoConfigurandoAreaTrabajo: 'Configurando su ambiente de trabajo...',
    textoNuevaVersion: 'Nueva versión, click aquí para actualizar',
    textoGuardando: 'Guardando...',
    textoCopiar: 'Copiado al portapapeles',
    textoCargando: 'Cargando...',
    textoEnviando: 'Enviando...',
    textoDescargando: 'Descargando...',
    textoDescargar: 'Descargar',
    textoPagina: 'Página',
    textoPaginaDe: 'de',
    textoAlerta: 'Alerta',
    textoError: 'Error',
    textoAceptar: 'Aceptar',
    textoArchivo: 'Archivo',
    textoDocumentoNoValido: 'Documento no válido',
    textoVisualizarPDF: 'Visualizar PDF',
    textoSinDatos: 'Sin Datos',
    textoFiltros: 'Filtros',
    textoBuscar: 'Buscar',
    textoEstado: 'Estado',
    textoAproados: 'Aprobados',
    textoEnAprobacion: 'En Aprobación',    
    textoEstadoAprobado: 'Aprobado',
    textoEstadoAbierto: 'Abierto',
    textoEstadoEnAprobacion: 'En Aprobación',
    textoEstadoPagado: 'Pagado',
    textoEstadoAnulado: 'Anulado',
    textoEstadoDevuelto: 'Devuelto',
    textoIncompleto: 'Incompleto',
    textoSinConexion: 'Sin Conexión',
    textoConexionEstablecida: 'Conexión establecida',
    textoDescripcionSinConexion: 'No posee conexión a internet',
    textSinResultados: 'Sin Resultados',
    textoExitoso: 'Exitoso',
    textoExitosoCambioPassword: 'Se realizó el cambio de contraseña correctamente',
    textoExitosoRecuperacionPassword: 'Se realizó la recuperación de contraseña correctamente, recibirá un mensaje al correo electrónico registrado',
    textoEliminar: '¿Está seguro de eliminar los siguientes ítems?',
    btnCancelar: 'Cancelar',
    btnGuardar: 'Guardar',
    btnSiguiente: 'Siguiente',
    btnVerImagen: 'Ver imágenes',
    btnRegresar: 'Regresar',
    btnSeleccionar: 'Seleccionar',
    btnDescartar: 'Descartar',
    btnEliminar: 'Eliminar',
    btnAgregar: 'Agregar',
    btnRechazar: 'Rechazar',
    btnAprobar: 'Aprobar',
    btnSubirArchivo: 'Subir Archivo',
    btnTodo: 'Todo',
    btnDownloadTemplate: 'Descargar Plantilla',
    btnEnviar: 'Enviar',
    btnCancelarFlujo: 'Cancelar Flujo',
    btnEnviarAprobacion: 'Enviar Aprobación',
    btnAutocompletar: 'Autocompletar',
    btnLimpiar: 'Limpiar',
    moduloConfiguracion: {
        textoIdioma: 'Idioma',
        textoCambioPassword: 'Cambiar Contraseña',
        textoNuevaPassword: 'Nueva Contraseña',
        textoRepetirNuevaPassword: 'Repetir Nueva Contraseña',
        textoBotonSaveCambioPassword: 'Guardar',
        nombre: 'Español',
    },
    moduloLogin: {
        textoTitulo: 'Login',
        txtUsuario: 'Usuario',
        txtPassword: 'Password',
        btnLogin: 'LOGIN',
        chkRecordarUsuario: 'Recuérdame',
        textoOlvidoContrasenia: '¿Olvido su contraseña?',
    },
    moduloRecuperarPassword: {
        textoTitulo: 'Recuperar Contraseña',
        textoUsuario: 'Usuario',
        textoIdentificacion: 'Número de Identificación',
        textoCorreoElectronico: 'Correo Electrónico',
        textoAccion: 'Recuperar',
        textoRegresarLogin: 'Regresar Login'
    },
    modulo404: {
        textoDescripcion: 'El recurso solicitado no esta disponible',
        textoHome: 'Volver al Inicio'
    },
    moduloNotAvailable: {
        textoCabecera: 'Recurso No Habilitado',
        textoDescripcion: 'El recurso solicitado no se encuentra disponible para su dispositivo',
    },
    moduloMain: {
        textoTitulo: 'Sistema de Gestión de Seguridad Operacional',
        textoBienvenida: '¡Te damos la bienvenida!',
        textoInicio: 'Inicio',
        textoAyuda: 'Ayuda',
        textoTituloPreguntaAyuda: 'Redirección',
        textoDescripcionPreguntaAyuda: 'Será redirigido a la documentación de ayuda del sistema, ¿Está seguro?',
        textoReporte: 'Reportar',
        textoRefrescarDatos: 'Refrescar datos',
        textoTituloPreguntaReporte: 'Redirección',
        textoDescripcionPreguntaReporte: 'Será redirigido a la Sistema de Gestión de Servicios, ¿Está seguro?',
    },
    moduloCatalogo: {
        textoTitulo: 'REFRESCAR DATOS',
        textoRefrescarTodo: 'Refrescar Todo',
        textoRefrescar: 'Refrescar',
        textoSincronizado: 'Sincronizado',
        textoNoSincronizado: 'No sincronizado',
    },
    moduloApprovals: {
        navBar: [{ text: 'APROBACIONES', navigate: false, path: '' }],
        question: {
            textoDesea: '¿Desea',
            textoAprobar: 'aprobar',
            textoDevolver: 'devolver',
            textoComparativo: 'el siguiente comparativo?',
            textoFactura: 'la siguiente factura?',
            textoRequerimientoSistema: 'el siguiente requerimiento de sistema?',
            textoRequerimientoCompra: 'el siguiente requerimiento de compra?',
            textoSolicitudCajaChica: 'la siguiente solicitud de caja chica?',
            textoRendicionGastoCajaChica: 'la siguiente rendición gatos de caja chica?',
            textoHojaPeticion: 'la siguiente hoja de petición?',
            textoCondicionLaboral: 'la siguiente condición laboral?'
        }
    },
    moduloGenericDetail: {
        navBarComparativo: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'COMPARATIVO', navigate: true, path: '/main/approvals/comparative' }, { text: 'DETALLE', navigate: false, path: '' }],
        navBarFactura: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'FACTURA', navigate: true, path: '/main/approvals/invoice' }, { text: 'DETALLE', navigate: false, path: '' }],
        navBarRequerimientoSistemas: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'REQUERIMIENTO SISTEMAS', navigate: true, path: '/main/approvals/system-requirement' }, { text: 'DETALLE', navigate: false, path: '' }],
        navBarRequerimientoCompra: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'REQUERIMIENTO COMPRA', navigate: true, path: '/main/approvals/purchase-requirement' }, { text: 'DETALLE', navigate: false, path: '' }],
        navBarSolicitudCajaChica: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'SOLICITUD CAJA CHICA', navigate: true, path: '/main/approvals/petty-cash-request' }, { text: 'DETALLE', navigate: false, path: '' }],
        navBarRendicionGastosCajaChica: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'RENDICIÓN GASTOS', navigate: true, path: '/main/approvals/surrender-petty-cash-expenses' }, { text: 'DETALLE', navigate: false, path: '' }],
        navBarHojaPeticion: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'HOJA PETICIÓN', navigate: true, path: '/main/approvals/request-form' }, { text: 'DETALLE', navigate: false, path: '' }],
        navBarWorkingConditions: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'CONDICIÓN LABORAL', navigate: true, path: '/main/approvals/working-conditions' }, { text: 'DETALLE', navigate: false, path: '' }],
        navBarAusencia: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'AUSENCIA', navigate: true, path: '/main/approvals/ausencia' }, { text: 'DETALLE', navigate: false, path: '' }],
        navBarDocucobra: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'DOCUCOBRA', navigate: true, path: '/main/approvals/docucobra' }, { text: 'DETALLE', navigate: false, path: '' }],
        btnTextDevolver: 'Devolver',
        btnTextAprobar: 'Aprobar',
        itemDetail: {
            textCodigo: 'Codigo',
            textTitle: 'DETALLE',
            textPais: 'País',
            textGrupo: 'Grupo',
            textEmpresa: 'Empresa',
            textProveedor: 'Proveedor',
            textOT: 'OT',
            textFechaRecepcion: 'Fecha Recepción',
            textFormaPago: 'Forma Pago',
            textTipoPago: 'Tipo Pago',
            textTerminoPago: 'Termino de Pago',
            textMoneda: 'Moneda',
            textImporte: 'Importe',
            textComentario: 'Comentario',
            textPedidoCompra: 'Pedido Compra',
            textImporteFacturaAsociado: 'Importe Factura Asociado',
            textSolicitante: 'Solicitante',
            textMultiProveedor: 'Multi Proveedor',
            textContratoMarco: 'Contrato Marco',
            textFechaRegistro: 'Fecha Registro',
            textTrabajador: 'Trabajador',
            textAreaSolicitante: 'Área',
            textPuestoSolicitante: 'Puesto',
            textCargoSolicitante: 'Cargo',
            textObservaciones: 'Observaciones',
            textAprobadores: 'Aprobadores',
            textCodigoSolicitud: 'Código Solicitud',
            textEstado: 'Estado',
        },
        itemPersonal: {
            textTitle: 'PERSONAL',
            textModalidadTrabajo: 'Modalidad Trabajo',
            textMotivoSolicitud: 'Motivo Solicitud',
            textTipoContrato: 'Tipo Contrato',
            textPuesto: 'Puesto',
            textTipoDocumento: 'Tipo Documento',
            textIdentificacion: 'Identificación',
            textNombreCompleto: 'Nombre Completo',
            textFechaAlta: 'Fecha Alta',
            textFechaBaja: 'Fecha Baja',
            textVehiculoPropio: 'Vehículo Propio',
            textVehiculoEmpresa: 'Vehículo Empresa',
            textHoraExtra: 'Hora Extra',
            textPrimaProduccion: 'Prima Producción',
            textBonoTurnoNoche: 'Bono Turno Noche',
            textBonoTurnos: 'Bono por Turnos',
            textRetribucionMensual: 'Retribución Mensual',
            textRetribucionAnual: 'Retribución Anual',
            textFunciones: 'Funciones'
        },
        itemEmpleado: {
            textTitle: 'EMPLEADO',
            textTitleActuales: 'Datos Actuales',
            textTitlePropuestas: 'Datos Propuestos',
            textEmpleado: 'Empleado',
            textDelegacion: 'Delegación',
            textOT: 'OT',
            textPuesto: 'Puesto',
            textRemuneracionMensual: 'Remuneración Mensual',
            textRemuneracionAnual: 'Remuneración Anual',
            textRemuneracionAnualEuros: 'Remuneración Anual Euros',
            textTipoCoteo: 'Tipo Costeo',
            textFechaEfectiva: 'Fecha Efectiva',
            textVarianza: 'Varianza',
            textMotivoAumentoSalarial: 'Motivo Aumento Salarial',
            textJustificacionSolicitud: 'Justificación de la solicitud'
        },
        itemDistribuccion: {
            textTitle: 'DISTRIBUCIÓN',
            textTitleEmpty: 'NO HAY DISTRIBUCIÓN',
            textProveedor: 'Proveedor',
            textMoneda: 'Moneda',
            headerTable: [
                { label: 'Cuenta', key: 'cuenta' },
                { label: 'OT', key: 'ot' },
                { label: 'Importe', key: 'importe' },
            ],
            textImporteTotal: 'Importe Total',
            textImporteDistribuido: 'Importe Distribuido',
            textImportePendiente: 'Importe Pendiente',
        },
        itemProducto: {
            textTitle: 'PRODUCTO',
            headerTable: [
                { label: 'Descripción', key: 'descripcion' },
                { label: 'Cantidad', key: 'cantidad' },
                { label: 'Unidad', key: 'unidad' },
                { label: 'Precio Unitario', key: 'precioUnitario' },
                { label: 'Sub Total', key: 'subTotal' },
            ],
            headerTableWithoutPrice: [
                { label: 'Descripción', key: 'descripcion' },
                { label: 'Cantidad', key: 'cantidad' },
                { label: 'Unidad', key: 'unidad' },
            ],
            headerTableRQSistema: [
                { label: 'Personal', key: 'personal' },
                { label: 'Descripción', key: 'descripcion' },
                { label: 'Cantidad', key: 'cantidad' },
                { label: 'Unidad', key: 'unidad' },
            ],
            textMontoTotal: 'Monto Total',
        },
        itemValorizaciones: {
            textTitle: 'VALORIZACIÓN',
            headerTablePedidoCompra: [
                { label: 'Serie', key: 'serie' },
                { label: 'Número documento', key: 'numeroDocumento' },
                { label: 'Descripción', key: 'descripcion' },
                { label: 'Cantidad', key: 'cantidad' },
                { label: 'Precio Unitario', key: 'precioUnitario' },
                { label: 'Sub Total', key: 'subTotal' },
            ],
            headerTableContrato: [
                { label: 'Número Valorización', key: 'numeroValorizacion' },
                { label: 'Contrato', key: 'contrato' },
                { label: 'Servicio', key: 'servicio' },
                { label: 'Monto', key: 'monto' },
            ],
            textMontoTotal: 'Monto Total',
        },
        itemRendicion: {
            textTitle: 'GASTOS',
            headerTable: [
                { label: 'Nombre Servicio', key: 'nombreServicio' },
                { label: 'Importe', key: 'importe' },
            ],
            headerTableNuevo: [
                { label: '', key: 'checkbox' },
                { label: '', key: 'preview' },
                { label: 'Nombre Servicio', key: 'DatosServicio.Servicio' },
                { label: 'Importe', key: 'DatosImporte.Importe', special: 'amountFormat' },
            ],
            textImporteTotal: 'Importe Total',
        },
        itemNotas: {
            textTitle: 'NOTAS',
            headerTable: [
                { label: 'Observación', key: 'observacion' },
                { label: 'Fecha', key: 'fecha' },
                { label: 'Usuario', key: 'usuario' }
            ],
        },
        itemBitacora: {
            textTitle: 'BITACORA',
            headerTable: [
                { label: 'Acción', key: 'accion' },
                { label: 'Fecha', key: 'fecha' },
                { label: 'Descripción', key: 'descripcion' },
                { label: 'Usuario', key: 'usuario' },
            ],
        },
        itemDocumentos: {
            textTitle: 'DOCUMENTOS',
        },
        errors: {
            comparativoErrorDetalle: 'Detalle del comparativo no encontrado',
            facturaErrorDetalle: 'Detalle de la factura no encontrado',
            requerimientoSistemaErrorDetalle: 'Detalle del Requerimiento Sistema no encontrado',
            requerimientoCompraErrorDetalle: 'Detalle del Requerimiento de Compra no encontrado',
            solicitudCajaChicaErrorDetalle: 'Detalle de la solicitud caja chica no se ha encontrado',
            rendicionGastoCajaChicaErrorDetalle: 'Detalle de la rendición gastos de caja chica no se ha encontrado',
            hojaPeticionErrorDetalle: 'Detalle de la Hoja Petición no se ha encontrado',
            condicionLaboralErrorDetalle: 'Detalle de la condición laboral no se ha encontrado',
            docucobraErrorDetalle: 'Detalle del docucobra no se ha encontrado',
            facturaNoAsociada: 'No se puede aprobar la factura sin una asociación',
            aproveBatch: 'Ocurrió un error al aprobar los registros, por favor intente de nuevo',
            returnBatch: 'Ocurrió un error al retornar los registros, por favor intente de nuevo'
        },
        successBatch: 'Deleted records',
    },
    moduloGenericList: {
        navBarComparativo: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'COMPARATIVO', navigate: false, path: '' }],
        navBarFactura: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'FACTURA', navigate: false, path: '' }],
        navBarRequerimientoCompra: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'REQUERIMIENTO DE COMPRA', navigate: false, path: '' }],
        navBarRequerimientoSistema: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'REQUERIMIENTO DE SISTEMA', navigate: false, path: '' }],
        navBarSolicitudCajaChica: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'SOLICITUD CAJA CHICA', navigate: false, path: '' }],
        navBarRendicionGastosCajaChica: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'RENDICIÓN GASTOS', navigate: false, path: '' }],
        navBarHojaPeticion: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'HOJA PETICIÓN', navigate: false, path: '' }],
        navBarWorkingConditions: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'CONDICIONES LABORALES', navigate: false, path: '' }],
        navBarGastoAprobacion: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'RENDICIÓN GASTOS', navigate: false, path: '' }],
        navBarTareo: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'TAREO', navigate: false, path: '' }],
        navBarAusencia: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'AUSENCIA', navigate: false, path: '' }],
        navBarDocucobra: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'DOCUCOBRA', navigate: false, path: '' }],

        navBarHistoricoGastos: [{ text: 'RENDICIONES', navigate: true, path: '/main/caja-chica#aprobador' }, { text: 'HISTÓRICO GASTOS', navigate: false, path: '' }],
        navBarRendicionGastos: [{ text: 'RENDICIONES', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'RENDICIÓN GASTOS', navigate: false, path: '' }],
        navBarIngresoGastos: [{ text: 'RENDICIONES', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'INGRESO GASTOS', navigate: false, path: '' }],
        navBarIngresoGastosAsociados: [{ text: 'RENDICIONES', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'INGRESO GASTOS ASOCIADOS', navigate: false, path: '' }],
        navBarSolicitudAnticipo: [{ text: 'RENDICIONES', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'SOLICITUD CAJA CHICA', navigate: false, path: '' }],

        navBarTareoPersonal: [{ text: 'TAREO', navigate: true, path: '/main/tareo' }, { text: 'MI TAREO', navigate: false, path: '' }],
        navBarTareoGrupal: [{ text: 'TAREO', navigate: true, path: '/main/tareo' }, { text: 'GRUPAL', navigate: false, path: '' }],
        item: {
            textoListaVacia: 'Sin registros',
            itemTextProveedor: 'Proveedor',
            itemTextOT: 'OT',
            itemTextMoneda: 'Moneda',
            itemTextImporte: 'Importe',
            itemTextFechaRegistro: 'Fecha Registro',
            itemTextFaltaAsociacion: 'Falta asociación',
            itemTextSociedad: 'Sociedad',
            itemTextTrabajador: 'Trabajador',
            itemTextSolicitante: 'Solicitante',
            itemTextEmpresa: 'Empresa',
            itemTextPersonal: 'Personal',
            itemTextImporteAprobado: 'Importe Aprobado',
            itemTextRendidor: 'Rendidor',
            itemTextProceso: 'En proceso',
            itemTextPeriodo: 'Periodo',
            itemTextPeriodoInicial: 'Periodo Inicial',
            itemTextPeriodoFinal: 'Periodo Final',
            itemTextHoras: 'Horas',
            itemTextCantidadLinea: 'Cantidad Líneas'
        }
    },
    moduloCajaChica: {
        navBar: [{ text: 'RENDICIONES', navigate: false, path: '' }],
        textoImporteTotalSolicitud: 'Importe Total Solicitud',
        textoSinGastosAsociados: 'Sin Gastos Asociados'
    },
    moduloGasto: {
        navBarGastosPendientes: [{ text: 'RENDICIONES', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'INGRESO GASTOS', navigate: true, path: '/main/caja-chica/ingreso-gastos' }, { text: '', navigate: false, path: '' }],
        navBarGastosAsociados: [{ text: 'RENDICIONES', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'INGRESO GASTOS ASOCIADOS', navigate: true, path: '/main/caja-chica/ingreso-gastos-asociados' }, { text: '', navigate: false, path: '' }],
        detail: {
            textTitle: 'Información General',
            textOT: 'OT',
            textProveedor: 'Proveedor',
            textOtroProveedor: 'Otro Proveedor',
            textDesactivarOtroProveedor: 'Desactivar Otro Proveedor',
            textTipoDocumentoProveedor: 'Tipo Documento Proveedor',
            textNumeroDocumentoProveedor: 'Número Documento Proveedor',
            textRazonSocialProveedor: 'Razón Social Proveedor',
            textFechaEmision: 'Fecha Emisión',
            textTipoDocumentoTributario: 'Tipo Documento Tributario',
            textNumeroDocumento: 'Número Documento',
            textServicio: 'Servicio',
            textDivisa: 'Divisa',
            textImporte: 'Importe',
            textTipoImpuesto: 'Tipo Impuesto',
            textoOtroImpuesto: 'Otro Impuesto',
            textDesactivarOtroImpuesto: 'Desactivar Otro Impuesto',
            textImporteImpuesto: 'Importe Impuesto',
            textImporteTotal: 'Importe Total',
            textObservaciones: 'Observaciones',
            textDigitalizacionFactura: 'Digitalización Factura',
        },
        validate: {
            errorOT: 'Seleccione una OT',
            errorProveedor: 'Seleccione un Proveedor',
            errorTipoDocumentoProveedor: 'Ingrese un Tipo Documento Proveedor',
            errorNumeroDocumentoProveedor: 'Ingrese un Número Documento Proveedor',
            errorRazonSocialProveedor: 'Ingrese Razón Social Proveedor',
            errorFechaEmision: 'Ingrese una Fecha Emisión',
            errorTipoDocumento: 'Seleccione un Tipo Documento',
            errorNumeroDocumento: 'Ingrese Número Documento',
            errorServicio: 'Seleccione un Servicio',
            errorDivisa: 'Seleccione una Divisa',
            errorImporte: 'Ingrese el Importe',
            errorTipoImpuesto: 'Seleccione un Tipo Impuesto',
            errorObservacion: 'Ingrese una Observación',
            errorFiles: 'Ingrese Digitalización Factura',
            errorNumeroDocumentoDuplicado: 'Ya existe un gasto con el mismo Número Documento',
            errorSinFlujos: 'Esta OT/Delegación no presenta Flujos de aprobación'
        }
    },
    moduloAnticipo: {
        navBar: [{ text: 'RENDICIONES', navigate: true, path: '/main/caja-chica#rendidor' }, { text: 'SOLICITUD CAJA CHICA', navigate: true, path: '/main/caja-chica/solicitud-anticipo' }, { text: '', navigate: false, path: '' }],
        detail: {
            textTitle: 'Información General',
            textOT: 'OT',
            textProyectos: 'Restricciones',
            textTarea: 'Tarea',
            textPersonal: 'Personal',
            textDivisa: 'Divisa',
            textImporte: 'Importe',
            textBanco: 'Banco',
            textCuentaBancaria: 'Cuenta Bancaria',
            textCuentaInterbancaria: 'Cuenta Interbancaria',
            textNota: 'Descripción',
            textoImporteMaximo: 'Importe Máximo',
            textHelp: 'Para que tu solicitud de caja chica sea aprobada, es esencial adjuntar el documento debidamente firmado. Puedes encontrar la plantilla aquí.',
            textDigitalizacionSolicitud: 'Digitalización de Solicitud',
        },
        validate: {
            errorOT: 'Seleccione una OT',
            errorProyectos: 'Seleccion un Proyecto',
            errorTarea: 'Seleccione una Tarea',
            errorPersonal: 'Seleccione un Personal',
            errorDivisa: 'Seleccione una Divisa',
            errorImporte: 'Ingrese el Importe',
            errorImporteValido: 'Ingrese el Importe válido',
            errorBanco: 'Ingrese un Banco',
            errorCuentaBancaria: 'Ingrese Cuenta Bancaria',
            errorCuentaInterbancaria: 'Ingrese Cuenta Interbancaria',
            errorNota: 'Ingrese Descripción'
        }
    },
    moduloRendicion: {
        navBar: [{ text: 'RENDICIONES', navigate: true, path: '/main/caja-chica' }, { text: 'RENDICIÓN GASTOS', navigate: true, path: '/main/caja-chica/rendicion-gastos' }, { text: '', navigate: false, path: '' }],
        textoEstadoCuenta: 'Mi Estado de Cuenta',
        textoSolicitudCajaChica: 'Solicitud Caja Chica',
        textoImportePendientePago: 'Importe Pendiente Pago',
        textoTituloImporte: 'Importe',
        textoCodigo: 'Código',
        textoTituloPendienteRendir: 'Pendiente Rendir',
        detail: {
            textTituloGeneral: 'Información General',
            textOT: 'OT',
            textPersonal: 'Personal',
            textAnticipo: 'Solicitud Caja Chica',
            textBanco: 'Banco',
            textCuentaBancaria: 'Cuenta Bancaria',
            textCuentaInterbancaria: 'Cuenta Interbancaria',
            textFecha: 'Fecha',
            textDescripcion: 'Descripción',

            textTituloGastos: 'Asociar Gastos',
            textProveedor: 'Proveedor',
            textImporte: 'Importe',
            btnAprobacion: 'Enviar Aprobación',

            textTituloNuevoGasto: 'Nuevo Gasto',
            textTituloFlujo: 'Flujo',
            textTituloAsociar: 'Asociar Gasto',
            textGastoEncontrado: 'Gastos encontrados',
            textoGastoVacio: 'Sin Gastos Asociados',
        },
        validate: {
            errorDetail: 'No se encontró el detalle de este registro',
            errorOT: 'Seleccione una OT',
            errorPersonal: 'Seleccione un Personal',
            errorAnticipo: 'Seleccione una Solicitud Caja Chica',
            errorBanco: 'Ingrese un Banco',
            errorCuentaBancaria: 'Ingrese una cuenta Bancaria',
            errorFecha: 'Ingrese una Fecha',
            errorDescripcion: 'Ingrese una descripción',
            errorGasto: 'Seleccione mínimo un gasto',
            errorFlujo: 'Seleccione un Flujo'
        }
    },
    moduloGastoAprobacion: {
        navBar: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'RENDICIÓN GASTOS', navigate: true, path: '/main/approvals/gasto-aprobacion' }, { text: '', navigate: false, path: '' }],
        navBarHistorico: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'HISTÓRICO GASTOS', navigate: true, path: '/main/approvals/historico-gastos' }, { text: '', navigate: false, path: '' }],
        validate: {
            errorDetail: 'No se encontró el detalle del registro',
            errorComentario: 'Ingrese un Comentario',
            errorTitulo: 'Ingrese un título',
            errorDescripcion: 'Ingrese una descripción',
        },
        question: {
            questApprove: `Usted esta apunto de aprobar estos gastos. Por favor confirme  esta acción y agregue un comentario explicando por qué está aprobando este gasto.`,
            questReject: `Usted esta apunto de rechazar estos gastos. Por favor confirme  esta acción y agregue un comentario explicando por qué está rechazando este gasto.`,
        },
        generic: {
            textStatusRevision: 'En Revisión',
            textStatusAprobado: 'Aprobado',
            textStatusRechazado: 'Rechazado',
            textProveedor: 'Proveedor',
            textOT: 'OT',
            textImporte: 'Importe',
            textImpTotal: 'Imp. Total',
            textComentario: 'Comentario',
            textConfirmacion: 'Confirmación',
        },
        viewReport: {
            title: 'Informe Gasto',
            textTitleDetail: 'Detalle',
            textRendidor: 'Personal',
            textFechaEnvio: 'Fecha de envío',
            textImporteAsociado: 'Importe Asociado',
            textCantidadLineas: 'Cantidad Líneas',
            textGastos: 'Gastos',
            textoRevisados: 'revisados',
            textFechaEmision: 'Fecha emisión',
            textServicio: 'Servicio',
            textoBuscar: 'Buscar',
        },
        viewDetail: {
            title: 'Gasto',
            textTabDetail: 'Detalle',
            textTabArchivos: 'Archivos',
            textTitleGeneral: 'General',
            textTitleDetalleGasto: 'Detalle del Gasto',
            textDelegacion: 'Delegación',
            textFecha: 'Fecha',
            textImporte: 'Importe',
            textImpuesto: 'Impuesto',
            textTipoDoc: 'Tipo Doc',
            textServicio: 'Servicio',
            textObservaciones: 'Observaciones',
            textEstado: 'Estado',
        },
        viewHistory: {
            title: 'Notas',
            textTitleComentario: 'Nota',
            textTitulo: 'Título',
            textTitleNote: 'Notas',
            textDescripcion: 'Descripción',
            btnAgregarComentario: 'Agregar nota',
        }
    },
    moduloDocucobra: {
        navBar: [{ text: 'DOCUCOBRA', navigate: true, path: '/main/docucobra/list' }],
        textoVersion: 'Versión',
        textoResumen: 'Resumen',
        textoAbrirResumen: 'Abrir Resumen',
        textoAbrirDocumento: 'Abrir Documento',
        textoVerRelacionados: 'Ver Relacionados',
        textoTituloFiltros: 'Filtros de búsqueda',
        textoFiltroPais: 'País',
        textoBuscar: 'Buscar',
        textoVersiones: 'Versiones',
        textoDocumentos: 'Documentos',
        textoRelacionado: 'Relacionado',

        textoTituloArchivo: 'Archivo',
        textoTituloArchivosRelacionados: 'Archivos Relacionados',

        textoInputBuscar: 'Buscar...',
    },
    moduloTareo: {
        navBar: [{ text: 'TAREO', navigate: false, path: '/main/tareo' }],
        navBarPersonal: [{ text: 'TAREO', navigate: true, path: '/main/tareo' }, { text: 'MI TAREO', navigate: true, path: '/main/tareo/personal' }, { text: '', navigate: false, path: '' }],
        navBarAprobacion: [{ text: 'APROBACIONES', navigate: true, path: '/main/approvals' }, { text: 'TAREO', navigate: true, path: '/main/approvals/tareo' }, { text: 'Detalle', navigate: false, path: '' }],
        detail: {
            textDetalleAusencia: 'DETALLE AUSENCIA',
            textPeriodo: 'Periodo',
            textPeriodoInicial: 'Periodo Inicial',
            textPeriodoFinal: 'Periodo Final',
            textEstado: 'Estado',
            textTotalHoras: 'Total Horas',
            textHoras: 'Horas',
            textTitle: 'DETALLE TAREO',
            textTitleGeneral: 'DETALLE GENERAL',
            textTitleModalAutocompletar: 'Autocompletar Periodo',
            textTituloDetalle: 'Detalle',
            textOT: 'OT',
            textDisciplina: 'Disciplina',
            textFechaInicio: 'Fecha Inicio',
            textFechaFinal: 'Fecha Final',
            textExcluyeSabado: 'Excluye Sábado',
            textExcluyeDomingo: 'Excluye Domingo',
            textExcluyeFeriado: 'Excluye Feriados',
            table: [
                { key: 'DiaMes', label: 'Fecha' },
                { key: 'Nombre', label: 'Día' },
                { key: 'OT', label: 'OT' },
                { key: 'HorasTrabajador', label: 'Total Horas' },
                { key: 'Preview', label: '' }
            ],
            tableAusencia: [
                // { key: 'Delete', label: '' },
                { key: 'Preview', label: '' },
                { key: 'Estado', label: 'Estado' },
                { key: 'FechaInicio', label: 'Fecha Inicio' },
                { key: 'FechaFinal', label: 'Fecha Final' },
            ]
        },
        ausencia: {
            textAgregar: 'Agregar Ausencia',
            textVisualizar: 'Visualizar Ausencia',
            textEditar: 'Editar Ausencia',
            textTipo: 'Tipo Ausencia',
            textObservacion: 'Observación',
            textAdjuntarArchivo: 'Adjuntar archivo',
            textArchivoDocumento: 'Archivo documento',
            btnEnviarAprobacion: 'Enviar a aprobación'
        },
        validate: {
            errorPeriodo: 'No tiene configurado el Periodo',
            errorDetail: 'No se encontró el detalle para',
            errorOTBase: 'No tiene configurado la OT base',
            errorOT: 'No se encontró su OT base',
            errorOTRequired: 'Seleccione una OT',
            errorDisciplinaRequired: 'Seleccione una disciplina',
            errorHoraRequired: 'Ingrese una Hora',
            errorDetalleCantidad: 'Debe agregar mínimo un ítem',
            errorHoraMaximaDia: 'Las horas totales por día no deben superar a',
            errorDuplicadoItem: 'Ya existe esta OT y Disciplina',
        },
        question: {
            questLimpiarPeriodo: '¿Desea limpiar las horas del todo el periodo?',
            questAutocomplete: 'Se reemplazará todas las horas actuales y se autocompletará con el actual, ¿Desea seguir?',
        },
        days: {
            LU: "Lunes",
            MA: "Martes",
            MI: "Miércoles",
            JU: "Jueves",
            VI: "Viernes",
            SA: "Sábado",
            DO: "Domingo",
        }
    },
    menu: {
        '010': { titulo: 'APROBACIONES' },
        '011': { titulo: 'Requerimiento de sistema' },
        '012': { titulo: 'Requerimiento de compra' },
        '013': { titulo: 'Comparativo' },
        '014': { titulo: 'Factura' },
        '015': { titulo: 'Solicitud caja chica' },
        '016': { titulo: 'Rendición gastos' },
        '017': { titulo: 'Hoja de petición' },
        '018': { titulo: 'Condiciones laborales' },
        '019': { titulo: 'Rendición gastos' },

        '020': { titulo: 'DOCUCOBRA' },
        '030': { titulo: 'RENDICIONES' },
        '031': { titulo: 'Gastos aprobación' },
        '032': { titulo: 'Histórico gastos' },
        '033': { titulo: 'Rendiciones' },
        '036': { titulo: 'Solicitud Caja Chica' },

        '040': { titulo: 'TAREO' },
        '041': { titulo: 'Mi Tareo' },
        '042': { titulo: 'Tareo Grupal' },
        '043': { titulo: 'Tareo' },
        '044': { titulo: 'Ausencia' },
        '045': { titulo: 'Docucobra' },
    },
    languages: [
        { code: 'es', name: 'Español' },
        { code: 'en', name: 'Inglés' },
        { code: 'de', name: 'Alemán' },
        { code: 'it', name: 'Italiano' },
    ]
}
