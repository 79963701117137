import ElementModalCostume from "../../../../shared/Components/ElementModalCostume";
import { ElementTableCostume } from "../../../../shared/Components/ElementTableCostume";
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";
import { EntityMain } from "../../Domain/EntityMain"
import './ViewModalDetalle.scss';

export interface ContainerProps {
    data: EntityMain | null;
    closeModal(): void;
    show: boolean;
    openModalPDF(payload: any): void;
    openModalDetalle(payload: any): void;
    onChangeTabsDocument(codigo: string, type: 'next' | 'prev'): void;
    countCurrentFilesSameFilters: number;
}

const languageTranslate = LanguageTranslate();
export const ViewModalDetalle = (props: ContainerProps) => {
    if (!props.show) return null;

    return (
        <ElementModalCostume
            closeModal={props.closeModal}
            show={props.show}
            childrenTitle={
                <div className="d-flex gap-2" style={{ width: '80%', color: '#1B458F' }}>
                    { props.countCurrentFilesSameFilters > 1 ? <i className="fa-solid fa-circle-left" style={{ fontSize: 22 }} onClick={() => props.onChangeTabsDocument(props.data?.codigo || '', 'prev')} /> : null }
                    <h6 className="mb-0 text-truncate" style={{ fontWeight: 600, maxWidth: '100%' }}> {props.data?.name} </h6>
                    { props.countCurrentFilesSameFilters > 1 ? <i className="fa-solid fa-circle-right" style={{ fontSize: 22 }} onClick={() => props.onChangeTabsDocument(props.data?.codigo || '', 'next')} /> : null }
                </div>
            }
        >
            <div className="ViewModalDetalle">
                {/* <div className="ItemInformation">
                    <span className="Titulo">Información General</span>
                    <div className='d-flex'>
                        <span>Nombre:</span>
                        <span className='text-muted'> { props.data?.dataCompleteDocucobra?.Name } </span>
                    </div>
                    <div className='d-flex'>
                        <span>Descripción:</span>
                        <span className='text-muted'> { props.data?.dataCompleteDocucobra?.Name } </span>
                    </div>
                    <div className='d-flex'>
                        <span>Grupo:</span>
                        <span className='text-muted'> { props.data?.dataCompleteDocucobra?.Group?.Nombre } </span>
                    </div>
                    <div className='d-flex'>
                        <span>País:</span>
                        <span className='text-muted'> { props.data?.dataCompleteDocucobra?.Country?.Nombre } </span>
                    </div>
                    <div className='d-flex'>
                        <span>Filial:</span>
                        <span className='text-muted'> { props.data?.dataCompleteDocucobra?.Filial?.Nombre } </span>
                    </div>
                    <div className='d-flex'>
                        <span>Confidencial:</span>
                        <span className='text-muted'> { props.data?.dataCompleteDocucobra?.Security?.Nombre } </span>
                    </div>
                    <div className='d-flex'>
                        <span>Estado:</span>
                        <span className='text-muted'> { props.data?.dataCompleteDocucobra?.StatusWorkflow.Status } </span>
                    </div>
                    <div className='d-flex'>
                        <span>Versión actual:</span>
                        <span className='text-muted'> { props.data?.dataCompleteDocucobra?.Version } </span>
                    </div>
                </div> */}

                <div className="ItemInformation ItemInformationTable">
                    <span className="Titulo">{languageTranslate.moduloDocucobra.textoVersiones}</span>
                    <ElementTableCostume
                        listHeader={[
                            {
                                label: languageTranslate.textoArchivo,
                                key: 'name',
                                render: (text, data, index) => <>
                                    <i className="fa-solid fa-eye mx-2" style={{ fontSize: 10 }} onClick={() => props.openModalPDF(data)} />
                                    { text }
                                </>
                            },
                        ]}
                        listData={props.data?.dataCompleteDocucobra?.Versiones.map((row, index) => ({
                            name: `${languageTranslate.moduloDocucobra.textoVersion} ${index + 1}`,
                            path: row,
                            code: row.fileName,
                            principal: true,
                            autoDownload: false,
                            keyGroup: 'version',
                            textGroup: 'Versiones'
                        })) || []}
                        className="mb-4 CustomTable"
                    />
                </div>

                <div className="ItemInformation ItemInformationTable">
                    <span className="Titulo">{languageTranslate.moduloDocucobra.textoDocumentos}</span>
                    <ElementTableCostume
                        listHeader={[
                            {
                                label: languageTranslate.textoArchivo,
                                key: 'name',
                                render: (text, data, index) => <>
                                    <i className="fa-solid fa-eye mx-2" style={{ fontSize: 10 }} onClick={() => text === 'Relacionado' ? props.openModalDetalle(data) : props.openModalPDF(data)} />
                                    { text }
                                </>
                            },
                        ]}
                        listData={props.data?.listDocuments || []}
                        className="mb-4"
                    />
                </div>
            </div>
        </ElementModalCostume>
    )
}