import { RepositoryMain } from "../Domain/RepositoryMain";
import { RepositoryImplGenerico } from "../../../shared/Infraestructure/RepositoryImplGenerico";
import { DtoRequestLogin } from "../Domain/DtoRequestLogin";
import { DtoResponseLogin, EntityResponseAccess } from "../Domain/DtoResponseLogin";
import { AdapterGenerico } from "../../../shared/Infraestructure/AdapterGenerico";
import { removeLoading } from "../../../shared/Infraestructure/SliceGenerico";
import { initEntityMenu } from "../../../shared/Domain/EntityMenu";
import { EntityResponseFiles } from "../../../shared/Domain/EntityResponseFile";
import { LanguageTranslate } from "../../../shared/Infraestructure/LanguageTranslate";

const languageTranslate = LanguageTranslate();
export class RepositoryImplMain
    extends RepositoryImplGenerico<any>
    implements RepositoryMain {

    public async login(params: DtoRequestLogin): Promise<DtoResponseLogin | null> {
        return this._login(params);
    }

    private async _login(params: DtoRequestLogin): Promise<DtoResponseLogin | null> {
        if (!window.navigator.onLine) {
            this.dispatch(removeLoading());
            AdapterGenerico.createMessage(languageTranslate.textoSinConexion, languageTranslate.textoDescripcionSinConexion, "warning");
            return null;
        }
        let response: DtoResponseLogin = await this.service.call<any>("POST", `${this.urlBase}/public/login`, JSON.stringify(params), "basic", "json", "json", {}, 0);
        response = typeof response === "string" ? AdapterGenerico.isJSON(response) ? JSON.parse(response) : response : response;

        try {
            const paramsAccess = {
                "Filtros": [
                    {
                        "$project": {
                            "_id": 0,
                            "IdUser": 1,
                            "Access": 1
                        }
                    },
                    {
                        "$match": {
                            "IdUser": response.IdUser
                        }
                    }
                ],
                "CollectionName": "Master_User",
                "DataBase": "ACS_Group"
            }

            let responseAccess: EntityResponseAccess[] | null = await this.service.call<any>("POST", `${this.urlBase}/master/selectCollection`, JSON.stringify(paramsAccess), "basic", "json", "json", { Authorization: `Bearer ${response.token}`, "request-decrypt-response": "yes", }, 0);

            if (responseAccess && responseAccess[0]) {
                let userAccess = responseAccess[0];
                response.Rol = userAccess.Access.Rol;
                response.Confidencialidad = userAccess.Access.Confidencialidad;
            }

        } catch (error) {}

        // Asignar Rol/Confidencialidad por defecto
        const defaultAccess = {
            Rol: {
                Code: '',
                Name: '-'
            },
            Confidencialidad: {
                Codigo: 'INTERNO',
                Nombre: 'De Uso Interno'
            }
        };

        response.Rol = !!response.Rol?.Code ? response.Rol : defaultAccess.Rol;
        response.Confidencialidad = !!response.Confidencialidad?.Codigo ? response.Confidencialidad :  defaultAccess.Confidencialidad;

        // Asignar permisos
        response.permisos = {
            menu: initEntityMenu,
            rolesSisacce: [],
            paisesSisacce: [],
            filialesSisacce: [],
            opcionesSisacce: [],
        };

        return response;
    }

    private generarRoles(codigoRol: number): Array<string> {
        let list: string[] = [];

        for (let i = 0; i < codigoRol; i++) {
            if (i !== 0) list.push(i.toString());
        }
        list.push(codigoRol.toString());
        return list;
    }

    private async getPaisesPermisos(dataGrupos: any[]) {
        const paises: Array<{ _id: string; codigo: string; nombre: string }> = [];

        for (let g of dataGrupos || []) {
            for (let p of g?.pais || []) {
                if (p) {
                    const i = paises.map((row) => row.codigo).indexOf(p.codigo);
                    if (i === -1)
                        paises.push({ _id: p._id, codigo: p.codigo, nombre: p.nombre });
                }
            }
        }

        return paises;
    }

    private async getFilialPermisos(dataGrupos: any[]) {
        const filiales: Array<{ _id: string; codigo: string; nombre: string }> = [];

        for (let g of dataGrupos || []) {
            for (let p of g?.pais || []) {
                for (let d of p?.delegacion || []) {
                    if (d.filial !== null) {
                        const p = filiales
                            .map((row) => row.codigo)
                            .indexOf(d.filial.codigo);
                        if (p === -1)
                            filiales.push({ _id: d._id, codigo: d.codigo, nombre: d.nombre });
                    }
                }
            }
        }

        return filiales;
    }

    private async getOpcionesFolder(data: any[], idPadre: any = null, option: boolean, nivel: number) {
        const listOpciones: EntityResponseFiles[] = [];
        try {
            for (const o of data) {
                if (option) {
                    const items = this.structureData(o, option, nivel);
                    listOpciones.push(items);
                } else {
                    if (o.Padre === idPadre) {
                        const items = this.structureData(o, option, nivel);
                        listOpciones.push(items);
                    }
                }
            }
            return listOpciones;
        } catch (error) {
            return listOpciones;
        }
    }

    private structureData = (
        row: any,
        option: boolean,
        nivel: number
    ): EntityResponseFiles => {
        let validate = "Name",
            Permiso = { Code: "", _id: "", Nombre: "" };
        if (option) {
            validate = "descripcion";
            Permiso = row.padre
                ? { Code: row.padre.codigo, _id: row._id, Nombre: row.padre.nombre }
                : { ...Permiso };
        } else {
            Permiso = row.Permiso ?? { ...Permiso };
        }
        return {
            _id: row._id,
            Code: "",
            Padre: row.padre ? row.padre._id : "",
            Name: row[`${validate}`],
            Extension: "FOLDER",
            Nivel: nivel,
            Parents: [],
            Permiso: Permiso,
            Description: "",
            DateRegistry: "null",
            Resumen: "",
            Status: 1,
            StatusWorkflow: { IdStatus: 1, Status: "Aprobado" },
            Type: "FOLDER",
            VersionFileName: "",
            ArchivosRelacionados: [],
            Publicado: 1,
        };
    };
}
