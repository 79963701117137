import React from 'react';
import { ElementBreadCrumb } from '../../../shared/Components/ElementBreadCrumb';
import { ElementEmptyList } from '../../../shared/Components/ElementEmptyList';
import { LanguageTranslate } from '../../../shared/Infraestructure/LanguageTranslate';
import { EntityMain } from '../Domain/EntityMain';
import { EntityTypeModule } from '../Domain/EntityTypeModule';
import './Style.scss';
import { EntityModeMultipleSelect } from '../Domain/EntityModeMultipleSelect';
import { ViewSolicitudAnticipo } from './module/ViewSolicitudAnticipo';
import { ViewRendicionGasto } from './module/ViewRendicionGasto';
import { ViewAprobacionGasto } from './module/ViewAprobacionGasto';

interface PropsViewMain {
    data: EntityMain<any>[];
    onRedirectForm: (_id: string) => void;
    reloadData: Function;
    modeTypeModule: EntityTypeModule;
    copyCodeItem: (code: string) => Promise<void>;

    // Eliminación múltiple
    modeMultipleSelect: EntityModeMultipleSelect;
    onSelectItem: (code: string) => void;
    onSelectAllItem: () => void;
    activeModeMultipleSelect: (active?: boolean) => void;
    confirmDetail: () => Promise<any>;

    // Modal Filtro
    openModalFiltro: () => void;
};

export const ViewMain = (props: PropsViewMain) => {
    const languageTranslate = LanguageTranslate();
    const genericProps= (module: string) => ({
        data: props.data,
        onRedirectForm: props.onRedirectForm,
        copyCodeItem: props.copyCodeItem,
        onSelectItem: props.onSelectItem,
        modeMultipleSelect: props.modeMultipleSelect,
        show: props.modeTypeModule.key === module,
    })

    return (
        <div className='ViewGenericListPettyCash'>
            <div className="container-fluid">
                {/** BLOQUE DE BREADCRUMB */}
                <ElementBreadCrumb list={props.modeTypeModule.navBar} />

                {
                    (
                        props.modeTypeModule.key !== 'GastoAprobacion' &&
                        (['SolicitudAnticipo', 'RendicionGasto'].includes(props.modeTypeModule.key) && props.data.some(row => !row.isPendingSend && (row.dataComplete?.Status?.Solicitud?.IdStatus === 2 || row.dataComplete?.Status?.Rendicion?.IdStatus === 2)))
                    ) ?
                        <div className='d-flex group-btn-option'>
                            {
                                props.modeMultipleSelect.active ?
                                <>
                                    <button className='btn btn-primary btn-seleccionar' onClick={props.onSelectAllItem}>{props.modeMultipleSelect.listCode.length === props.data.length ? languageTranslate.btnDescartar : languageTranslate.btnSeleccionar}</button>
                                    <button className='btn btn-danger btn-seleccionar' onClick={() => props.activeModeMultipleSelect(false)}><i className='fa-solid fa-xmark'/></button>
                                </> :
                                <>
                                    <button className='btn btn-primary btn-seleccionar' onClick={() => props.activeModeMultipleSelect(true)}>{languageTranslate.btnSeleccionar}</button>
                                    <button className='btn btn-primary btn-seleccionar' onClick={() => props.onRedirectForm('form')} > <i style={{ fontSize: 14, marginRight: 10 }} className='fa-solid fa-plus'/>{languageTranslate.btnAgregar}</button>
                                </>
                            }
                        </div>
                    :
                    (
                        <div className='d-flex group-btn-option'>
                            {
                                <button className='btn btn-primary btn-seleccionar' onClick={() => props.onRedirectForm('form')} > <i style={{ fontSize: 14, marginRight: 10 }} className='fa-solid fa-plus'/>{languageTranslate.btnAgregar}</button>
                            }
                        </div>
                    )
                }

                <ViewSolicitudAnticipo {...genericProps('SolicitudAnticipo')} />
                <ViewRendicionGasto {...genericProps('RendicionGasto')} />
                <ViewAprobacionGasto {...genericProps('GastoAprobacion')} />

                {
                    props.data.length === 0 && ( 
                        <ElementEmptyList className="data-empty" text={languageTranslate.moduloGenericList.item.textoListaVacia} />
                    )
                }

                <div className='position-fixed reload'>
                    {
                        ["SolicitudAnticipo", "RendicionGasto"].includes(props.modeTypeModule.key) ? 
                            <div className='item-reload bg-primary' onClick={() => props.openModalFiltro()}>
                                <i className="fa-solid fa-filter" style={{ fontSize: 20 }} />
                            </div>
                        : null
                    }
                    <div className='item-reload bg-primary' onClick={() => props.reloadData()}>
                        <i className="fa-sharp fa-solid fa-arrow-rotate-right" style={{ fontSize: 20 }} />
                    </div>
                </div>
            </div>

            {
                props.modeMultipleSelect.active ?
                <div className='container_group_button shadow-lg'>
                    <div className='group_button'>
                        <button className='btn btn-dark' style={{ width: 130 }} onClick={() => props.activeModeMultipleSelect(false)} disabled={props.modeMultipleSelect.listCode.length === 0}>
                            <i className="fa-solid fa-xmark" style={{ fontSize: 14, marginRight: 8 }} />
                            {languageTranslate.btnCancelar}
                        </button>
                        <button className='btn btn-primary' style={{ width: 130 }} onClick={props.confirmDetail} disabled={props.modeMultipleSelect.listCode.length === 0}>
                            <i className="fa-solid fa-check" style={{ fontSize: 14, marginRight: 8 }} />
                            {languageTranslate.btnEliminar} ({props.modeMultipleSelect.listCode.length})
                        </button>
                    </div>
                </div>
                : null
            }
        </div >
    )
};