import { EntityOT } from "../../../../shared/Domain/Catalogos/EntityOT";
import { EntitySelectBase } from "../../../../shared/Domain/Catalogos/EntitySelectBase";
import { EntityDisciplina } from "../../../../shared/Domain/Tareo/EntityDisciplina";
import { EntityTipoAusencia } from "../../../../shared/Domain/Tareo/EntityTipoAusencia";
import { LanguageTranslate } from "../../../../shared/Infraestructure/LanguageTranslate";
import { EntityRequestTareas } from "./EntityRequest";
import { EntityResponseInitialData } from "./EntityResponse";

export interface EntityConfigForm {
    mode: ModeForm;
    navBar: EntityNavBar[];
    loadingForm: boolean;
    dataOptions: EntityResponseInitialData;
}

export const initEntityResponseInitialData: EntityResponseInitialData  = {
    ot: [],
    disciplina: [],
    tipoAusencia: [],
    ausencia: []
}

export const initEntityConfigForm: EntityConfigForm = {
    navBar: [],
    mode: '',
    loadingForm: true,
    dataOptions: initEntityResponseInitialData
}


export interface EntityNavBar {
    text: string;
    navigate: boolean;
    path: string;
}

export interface EntityConfigModalAutocomplete {
    show: boolean;
    title: string;
}

export const initEntityConfigModalAutocomplete: EntityConfigModalAutocomplete = {
    show: false,
    title: 'Autocompletar Periodo'
}

export interface EntityFormModalAutocomplete {
    OT?: EntitySelectBase<EntityOT>;
    Disciplina?: EntitySelectBase<EntityDisciplina>;
    Horas: string;
    FechaInicio: string;
    FechaFinal: string;
    ExcluyeSabado: boolean;
    ExcluyeDomingo: boolean;
    ExcluyeFeriado: boolean;
}

export const initEntityFormModalAutocomplete: EntityFormModalAutocomplete = {
    OT: undefined, 
    Disciplina: undefined,
    Horas: "08:00",
    FechaInicio: '',
    FechaFinal: '',
    ExcluyeSabado: true,
    ExcluyeDomingo: true,
    ExcluyeFeriado: true,
}

export interface EntityConfigModalDetalle {
    show: boolean;
    title: string;
    IdDia: number;
    Id: number;
    isDiaFestivo?: boolean;
}

export const initEntityConfigModalDetalle: EntityConfigModalDetalle = {
    show: false,
    title: '',
    IdDia: 0,
    Id: 0
}

export interface EntityFormModalDetalle {
    PositionTarea: number;
    OT: string;
    Disciplina: string;
    IdDia: number;
    Id: number;
    Horas: string;
    newItem?: EntityRequestTareas;
}

export interface EntityConfigModalItem {
    show: boolean;
    title: string;
}

export const initEntityConfigModalItem: EntityConfigModalItem = {
    show: false,
    title: ''
}

export interface EntityFormModalItem {
    OT?: EntitySelectBase<EntityOT>;
    Disciplina?: EntitySelectBase<EntityDisciplina>;
    Horas: string;
}

export const initEntityFormModalItem: EntityFormModalItem = {
    OT: undefined,
    Disciplina: undefined,
    Horas: '01:00'
}

export interface EntityFormModalNotes {
    show: boolean;
    note: string;
}

export const initEntityFormModalNotes: EntityFormModalNotes = {
    show: false,
    note: ''
}

export interface EntityConfigModalAusencia {
    show: boolean;
    mode: 'create' | 'edit' | 'preview' | '';
}

export const initEntityConfigModalAusencia: EntityConfigModalAusencia = {
    show: false,
    mode: ''
}

export interface EntityFormModalAusencia {
    TipoAusencia: EntitySelectBase<EntityTipoAusencia>;
    FechaInicio: string;
    FechaFinal: string;
    Observacion: string;
    Documento: {
        value: any;
        type: 'local' | 'server';
        valueToShow: string;
    }[];
    Codigo: string;
}

export const initEntityFormModalAusencia: EntityFormModalAusencia = {
    TipoAusencia: null as any,
    FechaInicio: null as any,
    FechaFinal: null as any,
    Observacion: "",
    Documento: [],
    Codigo: ""
}

const languageTranslate = LanguageTranslate();
export const nameModule = {
    'preview': languageTranslate.textoModoVisualizar,
    'create': languageTranslate.textoModoCrear,
    'edit': languageTranslate.textoModoEditar,
    '': 'No Found'
}

export type ModeForm = 'preview' | 'create' | 'edit' | '';

export type EntityRequestFormServiceSendApproval = {
    paramsSend: {
      identificacion: string;
      codigo: string;
      listaOT: string[];
      listActividad: string[];
      listDisciplina: string[];
    },
    extraData: {
      Codigo: string;
    }
}

export type EntityRequestFromServiceUpdate = {
    codigo: string;
    tareas: Array<{
        Codigo: string;
        Proyecto: {
            Delegacion: {
                Code: string;
                Codigo: string;
                Delegacion: string;
            };
            OT: {
                Code: string;
                Codigo: string;
                OT: string;
            };
            DatosEmpresa: {
                CDEmpresa: string;
                Empresa: string;
            };
        };
        Disciplina: {
            Codigo: string;
            Key: string;
            Name: string;
        };
        Actividad?: {
            Key: string;
            Name: string;
        };
        TotalFila?: number;
        Observacion?: string;
        Horas: Array<{
            Id: number;
            IdDia: number;
            Anio: number;
            Mes: number;
            DiaMes: number;
            Semana: number;
            Nombre: string;
            Tipo: number;
            Descripcion: string;
            Horas: number;
        }>;
    }>;
    user: {
        IdUser: number;
        User: string;
        LastFather: string;
        LastMother: string;
        Name: string;
        Perfil: string;
    };
}

export type EntityConfigModalPDF = {
    show: boolean;
    uri: string
}

export const initEntityConfigModalPDF: EntityConfigModalPDF = {
    show: false,
    uri: ''
}